<top-panel></top-panel>
<div
  class="bg-[url('/assets/img/background_base.webp')] bg-cover min-h-screen pb-[10vw]"
>
  <div class="flex flex-col text-center pt-[10vw] max-w-[90vw] m-auto">
    <h1 class="font-bold text-5xl text-white mb-10">Mission Crew Roles</h1>
    <h1 class="font-bold text-5xl mb-10" [style.color]="members.color">
      {{ members.name }}
    </h1>

    <h2
      *ngIf="members.name !== 'Own clothes'"
      class="font-bold text-xl text-white mb-10"
    >
      Each of these roles wear {{ members.name }} uniforms and do the following
      work in the hospital. If you're not sure what role someone is then just
      ask!
    </h2>
    <h2
      *ngIf="members.name === 'Own clothes'"
      class="font-bold text-xl text-white mb-10"
    >
      Each of these roles wear their own clothes and do the following work in
      the hospital. If you're not sure what role someone is then just ask!
    </h2>
    <div class="grid grid-cols-1 tablet:grid-cols-2 justify-start items-start">
      <div *ngFor="let member of members.members">
        <div
          class="flex flex-col rounded-[20px] p-10 m-5 border-solid border-white border-t-4 border-l-4"
          [style.background]="members.color"
        >
          <h2
            class="text-[5vw] tablet:text-[2vw] font-bold text-center text-white mb-5"
          >
            {{ member.name }}
          </h2>
          <h3
            class="text-[2.5vw] tablet:text-[1.2vw] font-bold text-center text-white"
          >
            {{ member.additionalInfo }}
          </h3>
        </div>
      </div>
    </div>
  </div>
</div>
<dashboard></dashboard>
