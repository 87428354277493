<top-panel [groundControl]="true" [dailyBrief]="dailyBrief" [extension]="extension"
  [extensionComplete]="day.extensionComplete" [extensionClicked]="day.extensionClicked"
  [dailyBriefClicked]="day.dailyBriefClicked" [funFactsClicked]="day.funFactsClicked"
  [groundControlClicked]="day.groundControlClicked" [dayComplete]="day.dayComplete" [funFacts]="funFacts"
  (dailyBriefToggle)="dailyBriefToggle()" (funFactsToggle)="funFactsToggle()"></top-panel>


<div id="academyDayDetailed">
  <div id="desktopView"
    class="w-screen flex flex-wrap flex-col items-center justify-center h-full tablet:h-screen py-[10vw] tablet:py-0 tablet:pt-[2vw] bg-cover">
    <h1 class="text-white text-center pt-0">{{ day.label }} Overview</h1>
    <h3 *ngIf="day.subtitle" class="font-bold mb-5 px-[5vw] text-center text-white">
      {{ day.subtitle }}
    </h3>
    <div class="flex flex-col tablet:flex-row gap-10 my-6">
      <div *ngFor="let activity of activities">
        <academy-activity-item [completed]="activity.completed" [number]="activity.number" [day]="day"
          [name]="activity.name" [description]="activity.description" tabindex="-1"></academy-activity-item>
      </div>
    </div>
    <button *ngIf="!evaluationShown" (click)="completeDay()"
      class="w-[80vw] laptop:w-[60vw] max-w-[500px] text-white dayCompleteBtn bg-[#48a5aa] rounded-full text-lg laptop:text-2xl px-8 py-2 cursor-pointer hover:shadow-2xl transition-shadow">
      I've completed my day
    </button>
  </div>
  <div *ngIf="evaluationShown" class="fixed inset-0 bg-gray-500 bg-opacity-90 transition-opacity z-10">
    <div class="flex h-screen items-center w-screen justify-center">
      <evaluation (evaluationToggle)="evaluationToggle()" [evaluation]="evaluation" [day]="day"
        [questions]="evaluationQuestions"></evaluation>
    </div>
  </div>
  <div id="mobileView" class="h-screen flex flex-col items-center justify-center gap-5">
    <h1 class="text-white text-center pt-2 mb-0">{{ day.label }}</h1>
    <h3 class="text-center text-primary">
      {{ day.subtitle }}
    </h3>
    <div *ngFor=" let activity of activities" class="text-white cursor-pointer">
      <img *ngIf="activity.completed" src="/assets/img/experiment_complete_icon.svg"
        class="absolute right-[8vw] translate-y-[-3vw] w-[10vw] xl-desktop:w-[3vw] z-[0] drop-shadow-2xl" />
      <div routerLink="{{ activity.number }}"
        class="bg-[#3D507A] w-[80vw] h-auto min-h-[20vw] rounded-[30px] flex flex-col justify-center items-center border-l-4 border-t-4 border-white"
        [style.background]="stage.stageColor" [style.color]="stage.stageTextColor">
        <h3 class="mb-2">{{ activity.name }}</h3>
        <p class="text-center mb-0">
          {{ activity.description }}
        </p>
      </div>
    </div>
    <button *ngIf="!evaluationShown" (click)="completeDay()"
      class="w-[80vw] laptop:w-[60vw] max-w-[500px] text-white dayCompleteBtn bg-[#48a5aa] rounded-full text-[3vw] px-8 py-2 z-[0]">
      I've completed my day
    </button>
  </div>

  <div *ngIf="day.dailyBrief && dailyBriefOpen" class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity">
    <div class="fixed z-10 inset-0 overflow-y-auto">
      <div class="relative">
        <div class="absolute inset-0 h-screen flex justify-center items-center">
          <div
            class="w-[90%] max-w-3xl relative p-5 py-10 rounded-[30px] border-solid h-[70%] border-l-4 border-t-4 px-2 text-white text-left transform transition-drop-shadow tablet:my-5 bg-[#d55541]">
            <img src="/assets/img/day_brief-close.svg"
              class="absolute top-[-25px] laptop:top-[-25px] left-[-25px] w-[50px] cursor-pointer z-[5]"
              (click)="dailyBriefOpen = false" />

            <div class="p-10 pt-0 h-full overflow-y-auto overflow-x-hidden ">
              <h1 class="text-white">
                Daily Brief
              </h1>
              <div class="overflow-y-auto">
                <div *ngFor="let item of dailyBrief">
                  <h1 *ngIf="item.type === 'headline'">
                    {{ item.text }}
                  </h1>
                  <div *ngIf="item.type === 'audio'">
                    <p>{{ item.text }}</p>
                    <audio class="w-full my-5" controls>
                      <source src="{{ item.url }}" type="audio/mpeg" />
                    </audio>
                  </div>

                  <figure *ngIf="item.type === 'profile'"
                    class="w-1/2 max-w-xs mx-auto ring-gray-300 dark:ring-gray-500 mb-4">
                    <img class="h-auto max-w-full rounded-full ring-2 ring-gray-300 dark:ring-gray-500"
                      src="{{ item.url }}" alt="{{ item.description }}" />
                    <figcaption class="mt-2 text-sm text-center">
                      {{ item.description }}
                    </figcaption>
                  </figure>

                  <div *ngIf="item.type === 'video'">
                    <video-component [videoSrc]="item.url"></video-component>
                  </div>
                  <p *ngIf="item.type === 'paragraph'">
                    {{ item.text }}
                  </p>
                  <div *ngIf="item.type === 'image'" class="w-full">
                    <img src="{{ item.url }}" />
                  </div>
                  <div *ngIf="item.type === 'imageWithList'" class="flex gap-5 w-full justify-center m-10">
                    <div>
                      <img src="{{ item.url }}" style="width: 200px; height: 200px" />
                    </div>
                    <div class="flex gap-10 m-10">
                      <h2>{{ item.listHeadLine }}</h2>
                      <ul>
                        <li class="list-disc text-start" *ngFor="let listItem of item.listItems">
                          {{ listItem }}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div *ngIf="item.type === 'external'" class="mb-5">
                    <p>
                      {{ item.headline }}
                    </p>
                    <a class="underline italic m-0 text-xl" href="{{ item.url }}" target="_blank">{{ item.text }}</a>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="funFacts && funFactsOpen" class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity">
    <div class="fixed z-10 inset-0 overflow-y-auto">
      <div class="relative">
        <div class="absolute inset-0 h-screen flex justify-center items-center">
          <div
            class="w-[90%] max-w-3xl relative p-5 py-10 rounded-[30px] border-solid h-[70%] border-l-4 border-t-4 px-2 text-white text-left transform transition-drop-shadow tablet:my-5 bg-[#d55541]">
            <img src="/assets/img/day_brief-close.svg"
              class="absolute top-[-25px] laptop:top-[-25px] left-[-25px] w-[50px] cursor-pointer z-[5]"
              (click)="funFactsOpen = false" />

            <div class="p-10 pt-0 h-full overflow-y-auto overflow-x-hidden ">
              <h1 class="text-white">
                Fun Facts
              </h1>
              <div class="overflow-y-auto">
                <div *ngFor="let item of funFacts">
                  <div *ngIf="item.type === 'headline'">
                    <h2>
                      {{ item.text }}
                    </h2>
                  </div>
                  <div *ngIf="item.type === 'audio'">
                    <small>Listen to the brief:</small>
                    <audio class="w-full" controls="controls" src="{{ item.url }}"></audio>
                  </div>
                  <div *ngIf="item.type === 'video'">
                    <video class="w-full" controls="controls" src="{{ item.url }}"></video>
                  </div>
                  <div *ngIf="item.type === 'paragraph'">
                    <p>
                      {{ item.text }}
                    </p>
                  </div>
                  <div *ngIf="item.type === 'image'" class="w-full">
                    <img src="{{ item.url }}" />
                  </div>
                  <div *ngIf="item.type === 'imageWithList'" class="flex gap-5 w-full justify-center m-10">
                    <div>
                      <img src="{{ item.url }}" style="width: 200px; height: 200px" />
                    </div>
                    <div class="flex gap-10 m-10">
                      <h2>{{ item.listHeadLine }}</h2>
                      <ul>
                        <li class="list-disc text-start" *ngFor="let listItem of item.listItems">
                          {{ listItem }}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <ol *ngIf="item.type === 'list'" class="mt-3 list-decimal">
                    <li *ngFor="let listItem of item.listItems" class="mb-3 p-3 bg-red-50/25 rounded">
                      {{ listItem }}
                    </li>
                  </ol>
                  <div *ngIf="item.type === 'external'" class="mb-5">
                    <p class="font-bold mt-2 text-[3vw] tablet:text-[2vw]">
                      {{ item.headline }}
                    </p>
                    <a class="underline italic m-0 text-[3vw] tablet:text-[2vw]" href="{{ item.url }}"
                      target="_blank">{{
                      item.text }}</a>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <dashboard></dashboard>