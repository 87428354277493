<div class="absolute inset-0 flex justify-center items-center backdrop-blur-md">
  <div
    class="p-10 w-[90%] max-w-3xl m-5 rounded-xl overflow-hidden shadow-lg text-center bg-[#48a5aa] border-t-8 border-l-8 flex flex-col items-center text-white justify-center">

    <h1 class="text-3xl tablet:text-5xl">
      Congratulations!
    </h1>
    <h3 class="mb-0">{{ badgeText }}</h3>

    <div class="my-10 text-center flex flex-col items-center justify-center">
      <img *ngIf="!ECHCCongratulations && !timPeakeCongratulations" [src]="findBadge()"
        class="w-[40vw] laptop:w-[20vw] desktop:w-[15vw]" />
      <video-component *ngIf="ECHCCongratulations"
        [videoSrc]="'https://www.youtube.com/embed/CD_ERa8l9cs'"></video-component>
      <video-component *ngIf="timPeakeCongratulations"
        [videoSrc]="'https://www.youtube.com/embed/dCVCTFK0uEc'"></video-component>

    </div>
    <div class="flex justify-between gap-4">
      <button routerLink="/academy/badges"
        class="btn btn-primary btn-sm bg-[#E84E3F] border-4 border-r-[#9e362a] border-b-[#9e362a] rounded-full z-[20] cursor-pointer hover:shadow-2xl transition-shadow">
        Badge Log
      </button>
      <button (click)="nextStage()"
        class="btn btn-primary btn-sm bg-[#E84E3F] border-4 border-r-[#9e362a] border-b-[#9e362a] rounded-full z-[20] cursor-pointer hover:shadow-2xl transition-shadow">
        {{ buttonText }}
      </button>
    </div>
  </div>
</div>