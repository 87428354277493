import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AcademyService } from '@services/academy.service';

@Component({
  selector: 'welcome-video',
  templateUrl: './welcome-video.component.html',
  styleUrls: ['./welcome-video.component.scss'],
  standalone: false
})
export class WelcomeVideo implements OnInit {
  constructor(private router: Router, private academyService: AcademyService) { }
  ngOnInit(): void { }
}
