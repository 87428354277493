import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AcademyActivityItemDetailed } from './components/academy-activity-item-detailed/academy-activity-item-detailed.component';
import { AcademyDayDetailed } from './components/academy-day-detailed/academy-day-detailed.component';
import { AcademyStageDetailed } from './components/academy-stage-detailed/academy-stage-detailed.component';
import { AcademyStageMap } from './components/academy-stage-map/academy-stage-map.component';
import { Badges } from './components/badges/badges.component';
import { Introduction } from './components/introduction/introduction.component';
import { Login } from './components/login/login.component';
import { Setup } from './components/setup/setup.component';
import { TeamMember } from './components/team-member/team-member.component';
import { Team } from './components/team/team.component';
import { Extension } from './components/extension/extension.component';
import { ProgGuard } from './services/prog.guard';
import { PublicHome } from './components/public-home/public-home.component';
import { WelcomeVideo } from './components/welcome-video/welcome-video.component';

const routes: Routes = [
  { path: '', component: PublicHome },
  { path: 'login', component: Login },
  { path: 'academy/setup', component: Setup },
  {
    path: 'introduction',
    component: Introduction,
  },
  {
    path: 'academy/welcome-video',
    component: WelcomeVideo,
  },
  {
    path: 'academy',
    component: AcademyStageMap,
  },
  {
    path: 'academy/team',
    component: Team,
  },
  {
    path: 'academy/badges',
    component: Badges,
  },
  {
    path: 'academy/:stage',
    component: AcademyStageDetailed,
  },
  {
    path: 'academy/team/:name',
    component: TeamMember,
  },
  {
    canActivate: [ProgGuard],
    path: 'academy/:stage/:day',
    component: AcademyDayDetailed,
  },
  {
    canActivate: [ProgGuard],
    path: 'academy/:stage/:day/extension',
    component: Extension,
  },
  {
    canActivate: [ProgGuard],
    path: 'academy/:stage/:day/:activity',
    component: AcademyActivityItemDetailed,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
