import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { AcademyActivityItem } from '../academy-activity-item/academy-activity-item.component';
import { AcademyDay } from '../academy-day/academy-day.component';
import { AcademyService } from 'src/app/services/academy.service';
import { Academy } from 'src/app/models/academy';
import { AcademyStage } from 'src/app/models/academystage';

@Component({
  selector: 'academy-day-detailed',
  templateUrl: './academy-day-detailed.component.html',
  styleUrls: ['./academy-day-detailed.component.scss'],
})
export class AcademyDayDetailed implements OnInit {
  academy: Academy;
  level = this.route.snapshot.paramMap.get('stage') || '1';
  stage: AcademyStage;
  activities: AcademyActivityItem[];
  day: AcademyDay;
  evaluation: any;
  evaluationShown: boolean = false;
  evaluationQuestions: string[] = [];
  dailyBrief!: any;
  extension: any;
  funFacts!: any;
  extensions!: any;
  dailyBriefOpen: boolean = false;
  funFactsOpen: boolean = false;
  extensionOpen: boolean = false;
  dayBackground: string = '';
  constructor(
    private route: ActivatedRoute,
    private academyService: AcademyService,
    private router: Router
  ) {
    this.academy = this.academyService.getAcademy();
    this.day = this.academyService.getDay(
      Number(this.route.snapshot.paramMap.get('day') || '1')
    );
    this.activities = this.day.activities;
    this.extension = this.day.extension;
    this.funFacts = this.day.funFacts;
    this.extensions = {};
    this.evaluation = this.day.evaluationQuestions;
    this.stage = this.academyService.getStage(Number(this.day.stage))!;
    if (this.evaluation) {
      this.day.evaluationQuestions.forEach((question: any) => {
        this.evaluationQuestions.push(question.question);
      });
    }
    this.dailyBrief = this.day.dailyBrief;
  }
  ngOnInit(): void {
    document
      .querySelector('#academyDayDetailed')
      ?.classList.add(this.stage.name.toLowerCase().replace(/ /g, '-'));
    this.academyService.checkDayCompletion();

    if (this.day.dayComplete && !this.day.evaluationShown) {
      this.evaluationShown = true;
      this.day.evaluationShown = true;
      this.academyService.storeAcademy();
    }

    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        this.dailyBriefOpen = false;
        this.funFactsOpen = false;
      }
    });
  }

  completeDay() {
    if (!this.day.evaluationShown) {
      this.evaluationShown = true;
      this.day.evaluationShown = true;
      this.academyService.storeAcademy();
    } else {
      this.router.navigate(['../'], { relativeTo: this.route });
    }
    this.academyService.completeDay(this.day.number);
  }
  dailyBriefToggle() {
    this.dailyBriefOpen = !this.dailyBriefOpen;
    this.funFactsOpen = false;
  }
  evaluationToggle() {
    this.evaluationShown = !this.evaluationShown;
  }
  funFactsToggle() {
    this.funFactsOpen = !this.funFactsOpen;
    this.dailyBriefOpen = false;
  }
}
