<div
  class="topBlock fixed top-0 left-0 w-screen h-[15vw] bg-[#48a5aa] z-20 border-b-4"
></div>
<div class="fixed top-0 left-[3.25vw] w-screen z-20">
  <img
    src="/assets/img/top_panel.svg"
    alt="top panel"
    class="topIMG absolute top-0 left-0 w-[93.5vw]"
  />
  <img
    *ngIf="!isOnAcademy"
    (click)="backClicked()"
    src="/assets/img/back-on.svg"
    alt="back button"
    class="absolute top-[.5vw] tablet:top-[1vw] left-[2vw] tablet:left-[10vw] h-[12vw] tablet:h-[5vw] cursor-pointer hover:drop-shadow-2xl transition-drop-shadow"
  />
  <img
    *ngIf="isOnAcademy"
    src="/assets/img/back-off.svg"
    alt="back button"
    class="absolute top-[.5vw] tablet:top-[1vw] left-[2vw] tablet:left-[10vw] h-[12vw] tablet:h-[5vw] cursor-not-allowed"
  />
  <img
    *ngIf="groundControl"
    (click)="questionGroundControlMail()"
    src="/assets/img/ground_control-on.svg"
    alt="groundcontrol button"
    class="absolute top-[.5vw] tablet:top-[1vw] left-[21vw] tablet:left-[20vw] h-[12vw] tablet:h-[5vw] cursor-pointer hover:drop-shadow-2xl transition-drop-shadow z-10"
  />
  <div
    *ngIf="groundControl && pingGroundControl"
    class="h-[12vw] tablet:h-[5vw] w-[12vw] tablet:w-[5vw] border-4 border-[#E84E3F] animate-ping bg-transparent rounded-full absolute top-[.5vw] tablet:top-[1vw] left-[21vw] tablet:left-[20vw] z-0"
  ></div>
  <img
    *ngIf="!groundControl"
    src="/assets/img/ground_control-off.svg"
    alt="groundcontrol button"
    class="absolute top-[.5vw] tablet:top-[1vw] left-[21vw] tablet:left-[20vw] h-[12vw] tablet:h-[5vw] cursor-not-allowed"
  />
  <img
    *ngIf="dailyBrief && dailyBrief.length > 0"
    (click)="openDailyBrief()"
    src="/assets/img/day_brief-on.svg"
    alt="back"
    class="z-10 absolute top-[.5vw] tablet:top-[1vw] right-[48vw] tablet:right-[33vw] h-[12vw] tablet:h-[5vw] cursor-pointer hover:drop-shadow-2xl transition-drop-shadow"
  />
  <a href="https://echcharity.org/what-we-do/" target="_blank"
    ><img
      src="/assets/img/ECHC_logo_btn.svg"
      alt="ECHC Logo"
      class="z-10 absolute top-[.5vw] tablet:top-[1vw] hidden tablet:block tablet:right-[40vw] h-[12vw] tablet:h-[5vw] cursor-pointer hover:scale-105 transition-transform"
    />
  </a>
  <div
    *ngIf="dailyBrief && !dailyBriefClicked &&dailyBrief.length > 0"
    class="h-[12vw] tablet:h-[5vw] w-[12vw] tablet:w-[5vw] border-4 border-[#E84E3F] animate-ping bg-transparent rounded-full absolute top-[.5vw] tablet:top-[1vw] right-[48vw] tablet:right-[33vw]"
  ></div>
  <img
    *ngIf="dailyBrief && dailyBriefClicked && dailyBrief.length > 0"
    src="/assets/img/experiment_complete_icon.svg"
    class="z-20 absolute top-[.5vw] tablet:top-[1vw] right-[48vw] tablet:right-[33vw] h-[5vw] tablet:h-[2vw]"
  />
  <img
    *ngIf="!dailyBrief || dailyBrief.length === 0"
    src="/assets/img/day_brief-off.svg"
    alt="daily brief button"
    class="absolute top-[.5vw] tablet:top-[1vw] right-[48vw] tablet:right-[33vw] h-[12vw] tablet:h-[5vw] cursor-not-allowed"
  />
  <img
    *ngIf="funFacts"
    (click)="openFunFacts()"
    src="/assets/img/fun_facts-on.svg"
    alt="fun facts button"
    class="z-10 absolute top-[.5vw] tablet:top-[1vw] right-[29vw] tablet:right-[24vw] h-[12vw] tablet:h-[5vw] cursor-pointer hover:drop-shadow-2xl transition-drop-shadow"
  />
  <div
    *ngIf="funFacts && !funFactsClicked"
    class="h-[12vw] tablet:h-[5vw] w-[12vw] tablet:w-[5vw] border-4 border-[#E84E3F] animate-ping bg-transparent rounded-full absolute top-[.5vw] tablet:top-[1vw] right-[29vw] tablet:right-[24vw]"
  ></div>
  <img
    *ngIf="funFacts && funFactsClicked"
    src="/assets/img/experiment_complete_icon.svg"
    class="z-20 absolute top-[.5vw] tablet:top-[1vw] right-[29vw] tablet:right-[24vw] h-[5vw] tablet:h-[2vw]"
  />
  <img
    *ngIf="!funFacts"
    src="/assets/img/fun_facts-off.svg"
    alt="fun facts button"
    class="absolute top-[.5vw] tablet:top-[1vw] right-[29vw] tablet:right-[24vw] h-[12vw] tablet:h-[5vw] cursor-not-allowed"
  />
  <img
    *ngIf="extension"
    routerLink="extension"
    src="/assets/img/extensions-on.svg"
    alt="extensions button"
    (click)="clickExtension()"
    class="z-10 absolute top-[.5vw] tablet:top-[1vw] right-[10vw] tablet:right-[15vw] h-[12vw] tablet:h-[5vw] cursor-pointer hover:drop-shadow-2xl transition-drop-shadow"
  />
  <div
    *ngIf="extension && !extensionClicked"
    class="h-[12vw] tablet:h-[5vw] w-[12vw] tablet:w-[5vw] border-4 border-[#E84E3F] animate-ping bg-transparent rounded-full absolute top-[.5vw] tablet:top-[1vw] right-[10vw] tablet:right-[15vw]"
  ></div>
  <img
    *ngIf="extensionComplete && extension"
    src="/assets/img/experiment_complete_icon.svg"
    class="z-20 absolute top-[.5vw] tablet:top-[1vw] right-[10vw] tablet:right-[15vw] h-[5vw] tablet:h-[2vw]"
  />
  <img
    *ngIf="!extension"
    src="/assets/img/extensions-off.svg"
    alt="extensions button"
    class="absolute top-[.5vw] tablet:top-[1vw] right-[10vw] tablet:right-[15vw] h-[12vw] tablet:h-[5vw] cursor-not-allowed"
  />
</div>
<div
  *ngIf="showGroundControlMailPopup"
  class="flex h-screen w-screen justify-center items-center fixed inset-0 bg-gray-500 bg-opacity-90 transition-opacity z-[500]"
>
  <div
    class="w-[60vw] h-[40vw] laptop:w-[30vw] laptop:h-[20vw] bg-[#E84E3F] rounded-[30px] flex flex-col justify-center items-center border-t-8 border-l-8"
  >
    <div *ngIf="!emailSent" class="flex flex-col justify-center items-center">
      <h2 class="text-[4vw] laptop:text-[2vw] text-center text-white">
        Are you sure you want to contact ground control?
      </h2>
      <div class="flex gap-5 mt-10">
        <button
          (click)="showGroundControlMailPopup = false"
          class="border-4 border-r-[#9e362a] border-b-[#9e362a] text-white bg-[#48a5aa] rounded-full text-[2.5vw] tablet:text-[2vw] laptop:text-[1.5vw] px-8 py-2 z-[20] cursor-pointer hover:drop-shadow-2xl transition-drop-shadow"
        >
          No
        </button>
        <button
          (click)="sendMail()"
          class="border-4 border-r-[#9e362a] border-b-[#9e362a] text-white bg-[#48a5aa] rounded-full text-[2.5vw] tablet:text-[2vw] laptop:text-[1.5vw] px-8 py-2 z-[20] cursor-pointer hover:drop-shadow-2xl transition-drop-shadow"
        >
          Yes
        </button>
      </div>
    </div>
    <div *ngIf="emailSent">
      <h2 class="text-[4vw] laptop:text-[2vw] text-center text-white">
        We have sent an email to Ground Control.<br /><br />
        They will be in touch soon!
      </h2>
    </div>
  </div>
</div>
