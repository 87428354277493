<top-panel></top-panel>
<div
  class="bg-[url('/assets/img/background_base.webp')] h-screen w-screen flex justify-center items-center"
>
  <video
    controls
    autoplay
    class="w-full laptop:w-[50vw] max-w-[1000px] max-h-[600px] rounded-lg border-8 border-[#fff5e6]"
  >
    <source src="/assets/video/introduction.mp4" type="video/mp4" />
  </video>
</div>
<dashboard></dashboard>
