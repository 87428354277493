import { AcademyStage } from '../components/academy-stage/academy-stage.component';
import { ActivityContent } from './academyActivityContent';

export class AcademyActivity {
  number: number;
  name: string;
  label: string;
  description: string;
  completed: boolean;
  content: ActivityContent[];
  pingGroundControl: boolean;
  data:{key: string, value: string}[]
  constructor(data?: Partial<AcademyActivity>) {
    this.number = data?.number || 0;
    this.name = data?.name || 'Activity Name';
    this.label = data?.label || '';
    this.description = data?.description || '';

    this.completed = data?.completed || false;
    this.content = data?.content || [];
    this.data = data?.data || [];
    this.pingGroundControl = data?.pingGroundControl || false;
  }

  getActivityDataValue(key: string): string | undefined{
    return this.data.find(item => item.key === key)?.value;
  }

  setActivityDataValue(key: string, value: string){
    const existingItem = this.data.find(item => item.key === key);
    if(existingItem){
      existingItem.value = value;
    } else {
      this.data.push({key: key, value: value})
    }
  }
}
