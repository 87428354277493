export class MailingService {
  addressee = 'spaceacademy@echcharity.org';
  sendMail(senderID: string, activityDescription: string) {
    const payload = {
      primaryEmail: this.addressee,
      subject: `Astronaut ${senderID}`,
      bodyHtml:
        activityDescription.length > 0
          ? `<p>Astronaut ${senderID} needs help with ${activityDescription} activity.</p>`
          : `<p>Astronaut ${senderID} needs general help.</p>`,
    };

    fetch('https://emailer-api.azurewebsites.net/api/email/space-academy', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((msg: any) => console.log(msg)) // logs response data
      .catch((err: any) => console.error(err)); // logs any error
  }
  sendStageCompletionEmail(senderID: string, stage: number) {
    const payload = {
      primaryEmail: this.addressee,
      subject: `Astronaut ${senderID} completed stage ${stage}!`,
      bodyHtml: `<p>Astronaut ${senderID} completed stage ${stage}!</p>`,
    };
    fetch('https://emailer-api.azurewebsites.net/api/email/space-academy', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((msg: any) => console.log(msg)) // logs response data
      .catch((err: any) => console.error(err)); // logs any error
  }
  sendEvaluations(senderID: string, evaluations: any[]) {
    const payload = {
      primaryEmail: this.addressee,
      subject: `Astronaut ${senderID}'s evaluations`,
      bodyHtml: `<p>${evaluations
        .map((evaluation) => {
          return `<p style="text-align: start;">Day ${
            evaluation.day
          }</p><table style="border: 1px solid black; border-collapse: collapse; padding: 5px;"><tr><th style="border: 1px solid black; border-collapse: collapse; padding: 5px;">Question</th><th style="border: 1px solid black; border-collapse: collapse; padding: 5px;">Answer</th></tr>${evaluation.evaluationQuestions
            .map((question: any) => {
              return `<tr><td style="border: 1px solid black; border-collapse: collapse; padding: 5px;">${question.question}</td><td style="border: 1px solid black; border-collapse: collapse; padding: 5px;">${question.answer}</td></tr>`;
            })
            .join('')}</table>`;
        })
        .join('')}</p>`,
    };
    fetch('https://emailer-api.azurewebsites.net/api/email/space-academy', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((msg: any) => console.log(msg)) // logs response data
      .catch((err: any) => console.error(err)); // logs any error
  }
  sendFinalSurvey(senderID: string, finalSurvey: any) {
    const payload = {
      primaryEmail: this.addressee,
      subject: `Astronaut ${senderID}'s final survey`,
      bodyHtml: `<table style="border: 1px solid #008890; border-collapse: collapse;">
    <tr style="border: 1px solid #008890;">
      <th style="border: 1px solid #008890; padding: 10px;">Question</th>
      <th style="border: 1px solid #008890; padding: 10px;">Answer</th>
    </tr>
    ${finalSurvey
      .filter((question: any) => question.type !== 'headline')
      .map((question: any) => {
        return `<tr style="border: 1px solid #008890;">
        <td style="border: 1px solid #008890; padding: 10px;">${question.question}</td>
        <td style="border: 1px solid #008890; padding: 10px;">${question.answer}</td>
      </tr>`;
      })
      .join('')}
  </table>`,
    };
    fetch('https://emailer-api.azurewebsites.net/api/email/space-academy', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((msg: any) => console.log(msg)) // logs response data
      .catch((err: any) => console.error(err)); // logs any error
  }

  sendSurvey(senderID: string, survey: any) {
    const payload = {
      primaryEmail: this.addressee,
      subject: `Astronaut ${senderID}'s Final Findings survey`,
      bodyHtml: `<table style="border: 1px solid #008890; border-collapse: collapse;">
    <tr style="border: 1px solid #008890;">
      <th style="border: 1px solid #008890; padding: 10px;">Question</th>
      <th style="border: 1px solid #008890; padding: 10px;">Answer</th>
    </tr>
    ${survey
      .filter((question: any) => question.type !== 'headline')
      .map((question: any) => {
        return `<tr style="border: 1px solid #008890;">
        <td style="border: 1px solid #008890; padding: 10px;">${question.question}</td>
        <td style="border: 1px solid #008890; padding: 10px;">${question.answer}</td>
      </tr>`;
      })
      .join('')}
  </table>`,
    };
    fetch('https://emailer-api.azurewebsites.net/api/email/space-academy', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((msg: any) => console.log(msg)) // logs response data
      .catch((err: any) => console.error(err)); // logs any error
  }
}
