<top-panel [groundControl]="false"></top-panel>
<div
  *ngIf="!loggedIn"
  class="flex w-screen h-screen p-16 flex-col items-center justify-center bg-[url('/assets/img/background_base.webp')] bg-cover"
>
  <div class="mb-5 text-center">
    <h1 class="text-3xl font-bold text-center mb-5 text-white">
      {{ headingText }}
    </h1>
    <input
      autofocus
      #input1
      (focus)="input1.value = ''"
      (keyup)="
        input1.value.length == 1 ? input2.focus() : ''; checkInput(input1)
      "
      maxlength="1"
      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
      type="number"
      [ngClass]="error ? 'border-red-600' : 'border-white'"
      class="outline-none w-16 h-16 border-2 text-4xl font-bold text-center m-2 rounded"
    />
    <input
      #input2
      (focus)="input2.value = ''"
      (keyup)="
        input2.value.length == 1 ? input3.focus() : ''; checkInput(input2)
      "
      maxlength="1"
      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
      type="number"
      [ngClass]="error ? 'border-red-600' : 'border-white'"
      class="outline-none w-16 h-16 border-2 text-4xl font-bold text-center m-2 rounded"
    />
    <input
      #input3
      (focus)="input3.value = ''"
      (keyup)="
        input3.value.length == 1 ? input4.focus() : ''; checkInput(input3)
      "
      maxlength="1"
      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
      type="number"
      [ngClass]="error ? 'border-red-600' : 'border-white'"
      class="outline-none w-16 h-16 border-2 text-4xl font-bold text-center m-2 rounded"
    />
    <input
      #input4
      (focus)="input4.value = ''"
      (keyup)="
        input4.value.length == 1 ? input5.focus() : ''; checkInput(input4)
      "
      maxlength="1"
      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
      type="number"
      [ngClass]="error ? 'border-red-600' : 'border-white'"
      class="outline-none w-16 h-16 border-2 text-4xl font-bold text-center m-2 rounded"
    />
    <input
      #input5
      (focus)="input5.value = ''"
      (keyup)="
        input5.value.length == 1 ? input6.focus() : ''; checkInput(input5)
      "
      maxlength="1"
      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
      type="number"
      [ngClass]="error ? 'border-red-600' : 'border-white'"
      class="outline-none w-16 h-16 border-2 text-4xl font-bold text-center m-2 rounded"
    />
    <input
      #input6
      (focus)="input6.value = ''"
      (keyup)="
        input6.value.length == 1 ? submit.focus() : ''; checkInput(input6)
      "
      maxlength="1"
      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
      type="number"
      [ngClass]="error ? 'border-red-600' : 'border-white'"
      class="outline-none w-16 h-16 border-2 text-4xl font-bold text-center m-2 rounded"
    />
  </div>
  <button
    #submit
    (click)="verifyUser(input1, input2, input3, input4, input5, input6)"
    class="bg-black hover:bg-[#295e62] text-white font-bold py-2 px-4 rounded focus:animate-pulse outline-none"
  >
    Log in
  </button>
</div>
<div
  *ngIf="loggedIn"
  class="pt-[20vw] tablet:pt-[10vw] pb-[10vw] flex w-[screen] h-[150vh] flex-col gap-5 items-start pl-2 tablet:pl-20 justify-start bg-[url('/assets/img/background_base.webp')] bg-cover text-white"
>
  <button
    class="py-1 laptop:py-5 px-1 laptop:px-5 w-[30vw] text-[2vw] laptop:text-[1.5vw] desktop:text-[1.2vw] bg-[#46a5ab] hover:bg-[#295e62] text-white font-bold rounded"
    (click)="resetAcademy()"
  >
    Reset academy details
  </button>
  <div class="flex items-center flex-wrap">
    <p class="text-[4vw] laptop:text-[3vw] desktop:text-[1.5vw]">
      Start stage:
    </p>
    <select
      #input
      class="outline-none w-[25vw] laptop:w-[10vw] text-black border-2 text-4xl font-bold text-center m-2 rounded"
    >
      <option *ngFor="let i of [2, 3, 4, 5, 6, 7, 8]" [value]="i">
        {{ i }}
      </option>
    </select>
    <button
      (click)="unlockProgress(input)"
      class="py-1 laptop:py-5 px-1 laptop:px-5 w-[30vw] laptop:w-[40vw] text-[2vw] laptop:text-[1.5vw] desktop:text-[1.2vw] bg-[#46a5ab] hover:bg-[#295e62] text-white font-bold rounded"
    >
      Unlock progress up to this stage
    </button>
  </div>
  <h2
    *ngIf="!administratorPinError"
    class="text-[4vw] laptop:text-[3vw] desktop:text-[1.5vw]"
  >
    <strong>Administrator's PIN: </strong>{{ academy.administrator.pin }}
  </h2>

  <div class="flex gap-5 items-center justify-start">
    <input
      #newAdministratorPin
      type="number"
      class="outline-none w-[50vw] laptop:w-[30vw] h-[8vw] laptop:h-[5vw] border-2 text-[4vw] laptop:text-[2vw] font-bold text-center m-2 rounded text-black"
      placeholder="New PIN"
      maxlength="6"
      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
    />
    <button
      (click)="changeAdministratorPin(newAdministratorPin)"
      class="py-1 laptop:py-5 px-1 laptop:px-5 w-[30vw] text-[2vw] laptop:text-[1.5vw] desktop:text-[1.2vw] laptop:w-[30vw] bg-[#46a5ab] hover:bg-[#295e62] text-white font-bold rounded"
    >
      Save administrator PIN
    </button>
  </div>

  <h2
    *ngIf="!astronautPinError"
    class="text-[4vw] laptop:text-[3vw] desktop:text-[1.5vw]"
  >
    <strong>Astronaut's PIN: </strong>{{ academy.astronaut.id }}
  </h2>

  <div class="flex gap-5 items-center justify-start">
    <input
      #newAstronautPin
      type="number"
      class="outline-none w-[50vw] laptop:w-[30vw] h-[8vw] laptop:h-[5vw] border-2 text-[4vw] laptop:text-[2vw] font-bold text-center m-2 rounded text-black"
      placeholder="New PIN"
      maxlength="4"
      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
    />
    <button
      (click)="changeAstronautPin(newAstronautPin)"
      class="py-1 laptop:py-5 px-1 laptop:px-5 w-[30vw] text-[2vw] laptop:text-[1.5vw] desktop:text-[1.2vw] laptop:w-[30vw] bg-[#46a5ab] hover:bg-[#295e62] text-white font-bold rounded"
    >
      Save astronaut PIN
    </button>
  </div>
  <div class="flex gap-5">
    <button
      *ngIf="evaluations.length !== 0"
      class="py-1 laptop:py-5 px-1 laptop:px-5 w-[30vw] text-[2vw] laptop:text-[1.5vw] desktop:text-[1.2vw] bg-[#46a5ab] hover:bg-[#295e62] text-white font-bold rounded"
      (click)="emailEvaluations()"
    >
      {{ emailEvaluationButtonText }}
    </button>
    <button disabled *ngIf="evaluations.length === 0">
      No evaluations yet to email or show
    </button>
    <button
      class="py-1 laptop:py-5 px-1 laptop:px-5 w-[30vw] text-[2vw] laptop:text-[1.5vw] desktop:text-[1.2vw] bg-[#46a5ab] hover:bg-[#295e62] text-white font-bold rounded"
      *ngIf="!evaluationsShowing && evaluations.length !== 0"
      (click)="evaluationsShowing = true"
    >
      Show evaluations
    </button>

    <button
      class="py-1 laptop:py-5 px-1 laptop:px-5 w-[30vw] text-[2vw] laptop:text-[1.5vw] desktop:text-[1.2vw] bg-[#46a5ab] hover:bg-[#295e62] text-white font-bold rounded"
      *ngIf="evaluationsShowing"
      (click)="evaluationsShowing = false"
    >
      Hide evaluations
    </button>
  </div>
  <div
    *ngIf="evaluations.length > 0 && evaluationsShowing"
    class="flex gap-5 flex-col overflow-y-auto p-5 border-4 rounded-xl"
  >
    <div *ngFor="let evaluation of evaluations">
      <h2 class="text-xl mb-5">
        <span>Day {{ evaluation.day }}'s evaluation </span>
      </h2>
      <table class="table-auto mb-10">
        <thead>
          <tr>
            <th class="px-4 py-2">Question</th>
            <th class="px-4 py-2">Answer</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let question of evaluation.evaluationQuestions">
            <td class="border px-4 py-2">{{ question.question }}</td>
            <td class="border px-4 py-2">{{ question.answer }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div *ngIf="evaluations.length === 0 && evaluationsShowing">
    <h2 class="text-[1.2vw] text-red-500">
      Sorry but no evaluations have been submitted yet.
    </h2>
  </div>
</div>
<dashboard></dashboard>
