<top-panel></top-panel>
<div class="bg-[url('/assets/img/background_base.webp')] bg-cover min-h-screen">
  <div class="content-container max-w-[1200px] px-12">
    <h1 class="text-center mt-10">Mission Crew</h1>
    <h3>
      Select the colour of uniform to find out what their role is and how they
      can help you
    </h3>

    <div class="grid grid-cols-2 tablet:grid-cols-3 laptop:grid-cols-4 gap-4">
      <div *ngFor="let member of team">
        <div routerLink="/academy/team/{{ member.name }}"
          class="flex flex-col _h-12 _tablet:h-16 items-center justify-center bg-[#46a5ab] rounded-[40px] p-5 m-1 border-solid border-t-2 border-l-2 hover:drop-shadow-4xl shadow-white transition-drop-shadow cursor-pointer"
          [style.background]="member.color">
          <div class="text-white text-primary">
            <img *ngIf="member.img" src="/assets/img/{{ member.img }}" />
            <ng-container *ngIf="!member.img">{{ member.name }}</ng-container>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
<dashboard></dashboard>