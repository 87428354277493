import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AcademyService } from "src/app/services/academy.service";

@Component({
    selector: "introduction",
    templateUrl: "./introduction.component.html",
    styleUrls: ["./introduction.component.scss"]
})

export class Introduction implements OnInit {
    constructor(
        private router: Router,
        private academyService: AcademyService
    ){}
    ngOnInit(): void {
    }
    setIntroductionShown() {
        this.academyService.updateIntroductionShown()
        this.router.navigate(['/academy'])
    }
}