<top-panel></top-panel>
<div class="bg-[url('/assets/img/background_base.webp')] bg-cover min-h-screen">
  <div class="content-container">
    <div
      class="font-bold text-[3vw] text-white mt-[20vw] laptop:mt-[8vw] mb-10 xl-desktop:my-0"
    >
      <h1 class="text-center">Mission Crew</h1>
      <h1
        class="font-bold text-xl tabler:text-3xl text-center text-white mb-10"
      >
        Select the colour of uniform to find out what their role is and how they
        can help you
      </h1>
    </div>
    <div class="flex flex-wrap flex-col max-w-[80%]">
      <div class="flex flex-wrap tablet:gap-10 justify-center items-center">
        <div *ngFor="let member of team">
          <div
            routerLink="/academy/team/{{ member.name }}"
            class="flex flex-col w-[20vw] min-w-[250px] xl-desktop:w-[10vw] h-[8vw] xl-desktop:h-[5vw] items-center justify-center bg-[#46a5ab] rounded-[40px] p-5 tablet:p-10 m-1 border-solid border-t-2 border-l-2 hover:drop-shadow-4xl shadow-white transition-drop-shadow cursor-pointer"
            [style.background]="member.color"
          >
            <h2
              class="laptop:text-xl xl-desktop:text-[1vw] font-bold text-center text-white"
            >
              {{ member.name }}
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<dashboard></dashboard>
