import { Component, Input, OnInit } from '@angular/core';
import { AcademyDay } from '../academy-day/academy-day.component';
import { AcademyService } from '@services/academy.service';
import { Academy } from '@models/academy';

@Component({
  selector: 'badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  standalone: false
})
export class Badge implements OnInit {
  academy: Academy;

  @Input() stage!: AcademyDay;
  @Input() completed: boolean = false;

  constructor(private academyService: AcademyService) {
    this.academy = this.academyService.getAcademy();
  }

  ngOnInit(): void { }
  determineBackground() {
    return `./assets/img/badges/stage${this.stage}.webp`;
  }
}
