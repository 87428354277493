import { Component, Input, OnInit } from '@angular/core';
import { AcademyService } from 'src/app/services/academy.service';
import { ActivatedRoute } from '@angular/router';
import { AcademyDay } from '../academy-day/academy-day.component';
import { AcademyStage } from '../../models/academystage';
@Component({
  selector: 'extension',
  templateUrl: './extension.component.html',
  styleUrls: ['./extension.component.scss'],
})
export class Extension implements OnInit {
  extension: any;
  day: AcademyDay;
  stage: AcademyStage;
  constructor(
    private academyService: AcademyService,
    private route: ActivatedRoute
  ) {
    this.day = this.academyService.getDay(
      Number(this.route.snapshot.paramMap.get('day') || '1')
    );

    this.stage = this.academyService.getStage(Number(this.day.stage))!;
    this.extension = this.day.extension;
  }
  ngOnInit(): void {
    document
      .querySelector('#extension')
      ?.classList.add(this.stage.name.toLowerCase().replace(/ /g, '-'));
  }

  completeExtension() {
    this.academyService.completeExtension(this.day.number);
  }
}
