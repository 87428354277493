import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContactGroundControl } from './components/contact-groundcontrol/contact-groundcontrol.component';
import { Dashboard } from './components/dashboard/dashboard.component';
import { AcademyActivityItem } from './components/academy-activity-item/academy-activity-item.component';
import { Evaluation } from './components/evaluation/evaluation.component';
import { BadgeAchievement } from './components/badge-achievement/badge-achievement.component';
import { AcademyDay } from './components/academy-day/academy-day.component';
import { AcademyStageDetailed } from './components/academy-stage-detailed/academy-stage-detailed.component';
import { Introduction } from './components/introduction/introduction.component';
import { AcademyStageMap } from './components/academy-stage-map/academy-stage-map.component';
import { AcademyStage } from './components/academy-stage/academy-stage.component';
import { Badges } from './components/badges/badges.component';
import { Badge } from './components/badge/badge.component';
import { Team } from './components/team/team.component';
import { TeamMember } from './components/team-member/team-member.component';
import { Checklist } from './components/checklist/checklist.component';
import { AcademyActivityItemDetailed } from './components/academy-activity-item-detailed/academy-activity-item-detailed.component';
import { Headline } from './components/headline/headline.component';
import { VideoComponent } from './components/video/video.component';
import { Login } from './components/login/login.component';
import { AcademyDayDetailed } from './components/academy-day-detailed/academy-day-detailed.component';
import { Setup } from './components/setup/setup.component';
import { AcademyService } from './services/academy.service';
import { MailingService } from './services/mailing.service';
import { TopPanel } from './components/top-panel/top-panel-component';
import { Smileys } from './components/smileys/smileys.component';
import { Extension } from './components/extension/extension.component';
import { PublicHome } from './components/public-home/public-home.component';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { AudioService } from './services/audio.service';
import { FinalSurvey } from './components/final-survey/final-survey.component';
import { Survey } from './components/survey/survey-component';
import { WelcomeVideo } from './components/welcome-video/welcome-video.component';

@NgModule({
  declarations: [
    AcademyActivityItem,
    AcademyActivityItemDetailed,
    AcademyDay,
    AcademyDayDetailed,
    AcademyStage,
    AcademyStageDetailed,
    AcademyStageMap,
    AppComponent,
    Badge,
    BadgeAchievement,
    Badges,
    Checklist,
    ContactGroundControl,
    Dashboard,
    Evaluation,
    Extension,
    FinalSurvey,
    Headline,
    Introduction,
    Login,
    PublicHome,
    Setup,
    Smileys,
    Survey,
    Team,
    TeamMember,
    TopPanel,
    VideoComponent,
    WelcomeVideo,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    NgxUsefulSwiperModule,
  ],
  providers: [
    AcademyService,
    Checklist,
    MailingService,
    AudioService,
    FinalSurvey,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
