export class AudioService {
  constructor() {}

  playAudio(type: string, number: number) {
    if (type === 'day') {
      if (number === 1) {
        const audio = new Audio('assets/audio/day1completion.mp3');
        audio.play();
      } else {
        const audio = new Audio('assets/audio/daycompletion.mp3');
        audio.play();
      }
    } else if (type === 'stage' && number < 8) {
      const audio = new Audio(`assets/audio/stage${number}.mp3`);
      audio.play();
    }
  }
}
