<div>
  <div class="relative">
    <div id="hero" class="h-[60vw] flex flex-col justify-center items-center">
      <a href="https://echcharity.org/what-we-do/" target="_blank"><img
          class="absolute top-[2.5vw] left-[2.5vw] w-[25vw]" src="/assets/img/ECHC-LimitedLogo-Reverse.png" /></a>
      <div class="text-center">
        <h1 class="hero">
          Welcome to <br />space academy
        </h1>
        <button routerLink="/login" class="btn btn-secondary">
          Start
        </button>
        <img class="absolute w-[6vw] right-[5vw] translate-y-[6vw] desktop:translate-y-[8vw]"
          src="/assets/img/space-academy-logo.png" />
      </div>
    </div>
    <div class="relative bg-primary text-center text-white flex justify-center">
      <div class="max-w-[1200px] w-[90vw] tablet:w-[70vw] py-10 tablet:py-20">
        <h2>
          What is Space Academy?
        </h2>
        <p>
          Edinburgh Children's Hospital Charity has developed a 21-day programme
          of activities for completion by children and young people who are
          clinically isolated. The programme has been developed based on the
          daily routine and experiences of an astronaut, likening it to that of
          a child's daily hospital routine
        </p>
        <p>
          Children become the captain of their spaceship and the NHS staff their
          crew, giving children some control over their environment. The
          children and young people will embark on a journey of space
          exploration, life on the ISS and a mission to Mars before returning to
          Earth. The mission they have been set is to determine whether there is
          life on Mars.
        </p>
        <p>
          The children and young people will complete two experiments per day
          devised by astrophysicists and education leaders which incorporate
          curriculum learning. They will navigate their journey from blast off
          to touch down. This programme has been designed to reduce social
          isolation and provide distraction, daily activities and a positive
          experience of hospital.
        </p>
        <h3>Visit the ECHC website here:</h3>
        <a href="https://echcharity.org/" target="_blank">
          <button class="btn btn-secondary">
            Visit
          </button>
        </a>
      </div>
    </div>


    <swiper-container slides-per-view="3" space-between="30" navigation="true" class="my-7">
      <swiper-slide>
        <img src="/assets/photos/5E3A6974.webp" alt="img 1" />
      </swiper-slide>
      <swiper-slide>
        <img src="/assets/photos/5E3A6975.webp" alt="img 2" />
      </swiper-slide>
      <swiper-slide>
        <img src="/assets/photos/5E3A6979.webp" alt="img 3" />
      </swiper-slide>
      <swiper-slide>
        <img src="/assets/photos/5E3A6982.webp" alt="img 4" />
      </swiper-slide>
      <swiper-slide>
        <img src="/assets/photos/5E3A6985.webp" alt="img 5" />
      </swiper-slide>
      <swiper-slide>
        <img src="/assets/photos/5E3A6992.webp" alt="img 6" />
      </swiper-slide>
      <swiper-slide>
        <img src="/assets/photos/5E3A7004.webp" alt="img 6" />
      </swiper-slide>
      <swiper-slide>
        <img src="/assets/photos/5E3A7008.webp" alt="img 6" />
      </swiper-slide>
      <swiper-slide>
        <img src="/assets/photos/5E3A7013.webp" alt="img 6" />
      </swiper-slide>
    </swiper-container>

    <!-- <swiper slides-per-view="3" grid-rows="3" mousewheel-force-to-axis="true">
      <swiper-slide><img src="/assets/photos/5E3A6974.webp" alt="img 1" /></swiper-slide>
      <swiper-slide>Slide 2</swiper-slide>
      <swiper-slide>Slide 3</swiper-slide>
      <div class="swiper-button-next"></div>
      <div class="swiper-button-prev"></div>
    </swiper> -->

    <!-- <swiper class="py-10" [config]="config">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <img src="/assets/photos/5E3A6974.webp" alt="img 1" />
        </div>
        <div class="swiper-slide">
          <img src="/assets/photos/5E3A6975.webp" alt="img 2" />
        </div>
        <div class="swiper-slide">
          <img src="/assets/photos/5E3A6979.webp" alt="img 3" />
        </div>
        <div class="swiper-slide">
          <img src="/assets/photos/5E3A6982.webp" alt="img 4" />
        </div>
        <div class="swiper-slide">
          <img src="/assets/photos/5E3A6985.webp" alt="img 5" />
        </div>
        <div class="swiper-slide">
          <img src="/assets/photos/5E3A6992.webp" alt="img 6" />
        </div>
        <div class="swiper-slide">
          <img src="/assets/photos/5E3A7004.webp" alt="img 6" />
        </div>
        <div class="swiper-slide">
          <img src="/assets/photos/5E3A7008.webp" alt="img 6" />
        </div>
        <div class="swiper-slide">
          <img src="/assets/photos/5E3A7013.webp" alt="img 6" />
        </div>
      </div>
      <div class="swiper-button-next"></div>
      <div class="swiper-button-prev"></div>
    </swiper> -->
  </div>
</div>