<div class="flex flex-col justify-start items-center w-[90%] m-auto">
  <div class="flex flex-col items-start gap-5">
    <div *ngFor="let item of survey">
      <div *ngIf="item.type === 'headline'" class="text-start">
        <h2 class="mb-5 text-center text-[3vw] laptop:text-[1.2vw]">
          {{ item.text }}
        </h2>
      </div>
      <div *ngIf="item.type === 'smileys'">
        <h2 class="mb-5 text-[3vw] laptop:text-[1.2vw] text-start">
          {{ item.question }}
        </h2>
        <smileys
          [limited]="true"
          (setAnswer)="setAnswer($event, item.id)"
        ></smileys>
      </div>
    </div>
  </div>
  <button
    (click)="sendSurvey()"
    class="doneBtn text-[#46a5ab] m-5 p-5 self-center bg-[#fff5e6] w-[40vw] bg-[#48a5aa] rounded-full text-2xl px-8 py-2 z-[20] cursor-pointer hover:shadow-2xl transition-shadow mb-[10vh]"
    [ngClass]="error ? 'text-red-500' : ''"
  >
    {{ error ? "Please answer all questions first" : "Send Survey" }}
  </button>
</div>
