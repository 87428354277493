<div class="relative cursor-pointer" routerLink="{{ number }}" [style.color]="stage.stageTextColor">
  <div
    class="w-[20vw] max-w-[450px] rounded-br-[35px] rounded-[30px] h-[35vh] min-h-[200px] flex flex-col justify-between items-center z-0 border-t-4 border-l-4 border-white"
    [style.background]="stage.stageColor">
    <div>
      <img src="/assets/img/day_card_shadow.svg" class="absolute bottom-0 right-0 w-[19vw] max-w-[200px] z-[-10]" />
    </div>

    <div class="px-6 py-6 flex flex-col items-between h-full justify-between">
      <div class="font-bold text-center">
        <h3 class="mb-0 pt-2">{{ name }}</h3>
      </div>
      <div>
        <p class="text-white-700 text-primary text-center">
          {{ description }}
        </p>
      </div>
      <button routerLink="{{ number }}" class="btn btn-sm btn-alt text-secondary">
        {{ completed ? "View" : "Begin" }}
      </button>
    </div>

    <img *ngIf="completed" src="/assets/img/experiment_complete_icon.svg"
      class="absolute top-[-20px] right-[-20px] w-[60px] xl-desktop:w-[3vw]" />
  </div>
</div>