<top-panel
  [pingGroundControl]="pingGroundControl"
  [groundControl]="true"
  [activity]="activity"
></top-panel>
<div class="bg-[url('/assets/img/background_base.webp')] bg-cover min-h-screen">
  <div *ngIf="pageError" class="content-container">
    <h2 class="text-white text-[3vw]">{{ pageError }}</h2>
  </div>

  <div *ngIf="!pageError" class="content-container">
    <div
      class="max-w-4xl p-5 rounded-lg text-center overflow-hidden text-[#fff5e6]"
    >
      <ng-container *ngFor="let item of content">
        <h1 *ngIf="item.type === 'headline'">
          {{ item.text }}
        </h1>
        <p *ngIf="item.type === 'paragraph'" class="text-start mb-4">
          {{ item.text }}
        </p>
        <p *ngIf="item.type === 'centerparagraph'" class="text-center mb-4">
          {{ item.text }}
        </p>
        <div *ngIf="item.type === 'video'" class="mb-5">
          <h1 *ngIf="item.text" class="font-bold mb-5">
            {{ item.text }}
          </h1>
          <video-component [videoSrc]="item.url"></video-component>
        </div>
        <div *ngIf="item.type === 'congratulations'">
          <h1 class="text-[4vw] tablet:text-[2vw] font-bold m-10">
            {{ item.text }}
          </h1>
        </div>

        <figure
          *ngIf="item.type === 'profile'"
          class="w-1/2 max-w-xs mx-auto ring-gray-300 dark:ring-gray-500 mb-4"
        >
          <img
            class="h-auto max-w-full rounded-full ring-2 ring-gray-300 dark:ring-gray-500"
            src="{{ item.url }}"
            alt="{{ item.description }}"
          />
          <figcaption class="mt-2 text-sm text-center">
            {{ item.description }}
          </figcaption>
        </figure>

        <div *ngIf="item.type === 'audio'" class="mb-10">
          <small>{{ item.text }}</small>
          <audio
            class="w-full"
            controls="controls"
            src="{{ item.url }}"
          ></audio>
        </div>

        <div *ngIf="item.type === 'external'" class="text-start ml-10 my-10">
          <p class="text-[3vw] tablet:text-[1.2vw]">{{ item.headline }}</p>
          <a
            class="underline italic text-[3vw] tablet:text-[1.2vw]"
            href="{{ item.url }}"
            target="_blank"
            >{{ item.text }}</a
          >
        </div>

        <div
          *ngIf="item.type === 'imageWithList'"
          class="flex gap-5 w-full justify-center m-10"
        >
          <div><img src="{{ item.url }}" class="w-[300px] h-[300px]" /></div>
          <div class="flex flex-col gap-10 m-10">
            <h2 class="text-start font-bold">{{ item.listHeadline }}</h2>
            <ul>
              <li
                class="list-disc text-start"
                *ngFor="let listItem of item.listItems"
              >
                {{ listItem }}
              </li>
            </ul>
          </div>
        </div>

        <div *ngIf="item.type === 'list'" class="mb-5">
          <h2 *ngIf="item.listHeadline" class="text-start font-bold mb-3">
            {{ item.listHeadline }}
          </h2>
          <ul class="list-disc ml-10 text-start">
            <li *ngFor="let listItem of item.listItems">
              {{ listItem }}
            </li>
          </ul>
        </div>

        <div *ngIf="item.type === 'finalSurvey'">
          <final-survey [activity]="activity"></final-survey>
        </div>

        <div *ngIf="item.type === 'survey'">
          <survey [activity]="activity"></survey>
        </div>

        <div
          *ngIf="item.type === 'finishTheLine'"
          class="flex w-full justify-start ml-10"
        >
          <div class="flex gap-1 m-0">
            <h2 class="text-start font-bold">{{ item.firstLetter }}</h2>
            <h2 class="text-start font-bold">{{ item.userText }}</h2>
          </div>
        </div>

        <div *ngIf="item.type === 'orderedList'" class="w-full text-start">
          <h2 class="font-bold mb-3">
            {{ item.listHeadline }}
          </h2>
          <ol class="ml-10 list-decimal text-start">
            <li *ngFor="let listItem of item.listItems">
              {{ listItem }}
            </li>
          </ol>
        </div>

        <div *ngIf="item.type === 'checklist'" class="w-full">
          <checklist
            [error]="error"
            class="flex justify-center"
            *ngIf="item.type === 'checklist'"
            [checklistHeadline]="item.checklistHeadline"
            [checklistItems]="item.checklistItems"
            [completed]="activity.completed"
          ></checklist>
        </div>

        <div
          *ngIf="item.hasGroundControl"
          class="mb-5 flex items-center justify-center"
        >
          <button
            routerLink="/academy/team"
            class="text-[#46a5ab] m-5 p-5 self-center bg-[#fff5e6] w-[20vw] bg-[#48a5aa] rounded-full text-[2vw] laptop:text-[1.5vw] desktop:text-[1.2vw] px-2 py-2 z-[20] cursor-pointer hover:shadow-2xl transition-shadow"
          >
            {{ item.groundControlButtonText }}
          </button>
        </div>

        <div
          *ngIf="item.type === 'image'"
          class="flex justify-center mb-5 mt-2"
        >
          <img class="rounded-xl" src="{{ item.url }}" />
        </div>

        <div
          *ngIf="item.type === 'slideshow'"
          class="flex justify-center mb-5 mt-2"
        >
          <swiper class="py-10" [config]="config">
            <div class="swiper-wrapper">
              <div *ngFor="let slide of item.slides" class="swiper-slide">
                <div class="swiper-slide">
                  <img
                    class="rounded-xl m-auto"
                    src="{{ slide }}"
                    alt="{{ slide }}"
                  />
                </div>
              </div>
            </div>
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
          </swiper>
        </div>
      </ng-container>
      <button
        *ngIf="!isOnSurvey"
        (click)="completeTask()"
        class="doneBtn text-[#46a5ab] m-5 p-5 self-center bg-[#fff5e6] w-[40vw] bg-[#48a5aa] rounded-full text-2xl px-8 py-2 z-[20] cursor-pointer hover:shadow-2xl transition-shadow"
      >
        Done
      </button>
    </div>
    <div
      *ngIf="emailSent"
      class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
    >
      <div class="fixed z-10 inset-0 overflow-y-auto">
        <div
          class="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0"
        >
          <div
            class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-drop-shadow sm:my-8 sm:max-w-lg sm:w-full"
          >
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div class="sm:flex sm:items-center justify-center">
                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3
                    class="text-lg leading-6 font-medium text-gray-900 text-center m-10"
                    id="modal-title"
                  >
                    We have sent an email to Ground Control.<br /><br />
                    They will be in touch soon!
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <dashboard></dashboard>
</div>
