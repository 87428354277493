import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'badge-achievement',
  templateUrl: './badge-achievement.component.html',
  styleUrls: ['./badge-achievement.component.scss'],
})
export class BadgeAchievement implements OnInit {
  buttonText: string = 'Next Stage';
  badgeText: string = "You've completed this stage of Space Academy!";
  ECHCCongratulations: boolean = false;
  timPeakeCongratulations: boolean = false;
  @Input() stage: number = 1;
  @Output() closeBadge = new EventEmitter<boolean>();
  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    if (Number(this.route.snapshot.paramMap.get('stage')) === 8) {
      this.buttonText = 'Finish';
    }
  }

  findBadge() {
    return `./assets/img/badges/stage${this.stage}.webp`;
  }
  nextStage() {
    if (this.stage < 8) {
      this.closeBadge.emit(false);
      this.router.navigate([`/academy/${this.stage + 1}`]);
    } else if (
      Number(this.route.snapshot.paramMap.get('stage')) === 8 &&
      this.stage === 8
    ) {
      this.stage = 10;
      this.buttonText = 'Next';
      this.badgeText = '';
      this.ECHCCongratulations = true;
    } else if (this.buttonText === 'Next') {
      this.stage = 9;
      this.ECHCCongratulations = false;
      this.timPeakeCongratulations = false;
      this.buttonText = 'Finish';
      this.badgeText = "You've completed Space Academy!";
    } else if (this.buttonText === 'Finish') {
      this.stage = 11;
      this.timPeakeCongratulations = true;
      this.buttonText = 'Home';
    } else if (this.buttonText === 'Home') {
      this.router.navigate(['/academy']);
    }
  }
}
