<div class="flex flex-col items-center justify-center mt-2">
  <div class="flex flex-col items-center justify-center w-full" [ngClass]="
      error
        ? 'border-t-8 border-red-500 rounded-xl'
        : 'border-t-4 border-l-4 border-[#fff5e6] rounded-xl'
    ">
    <div class="flex flex-col w-full items-center bg-[#E84E3F] rounded-lg rounded-t-lg border-gray-200 p-5 tablet:p-5">
      <h3 class="text-left w-full pt-4 mb-5">
        {{ checklistHeadline }}
      </h3>
      <ul class="w-full text-sm font-medium text-gray-900 dark:text-white">
        <div *ngFor="let item of checklistItems | keyvalue; let first = first;"
          class="flex flex-col items-center justify-center w-full">
          <li class="w-full">
            <hr *ngIf="!first" />
            <div class="flex flex-col gap-2 items-center justify-start w-full flex-wrap" *ngIf="
                item.value ===
                'Understand the control panel buttons and dashboard'
              ">
              <div class="flex items-center pl-3 justify-between w-full">
                <label for="{{ item.key }}" class="text-start py-3 m-2 font-medium text-[#fff5e6]">{{
                  item.value }}</label>
                <input id="{{ item.key }}" type="checkbox" value=""
                  class="mr-5 w-8 h-8 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-500 dark:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500"
                  [checked]="completed" />
              </div>
            </div>
            <div class="flex items-center pl-3" *ngIf="
                item.value !==
                'Understand the control panel buttons and dashboard'
              ">
              <label for="{{ item.key }}" class="text-start py-3 m-2 w-full font-medium text-[#fff5e6]">{{
                item.value }}</label>

              <input id="{{ item.key }}" type="checkbox" value=""
                class="mr-5 w-8 h-8 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-500 dark:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500"
                [checked]="completed" />
            </div>
          </li>
        </div>
      </ul>
    </div>
  </div>
  <div class="h-[50px]" *ngIf="error">
    <p class="text-red-500">
      Please tick all the boxes before you proceed, otherwise contact ground
      control for help.
    </p>
  </div>
</div>