import { teamSeed } from 'src/assets/data/team.seed';
import { stagesSeed } from '../../assets/data/stages.seed';
import { GroundControlMessage } from '../components/groundcontrol-message/groundcontrol-message.component';
import { AcademyStage } from './academystage';
import { AcademyTeam } from './academyTeam';

export class Academy {
  administrator: AcademyAdministrator;
  astronaut: AcademyAstronaut;
  daysCompleted: number;
  currentDay: number;
  badges: AcademyBadge[] = [];
  stages: AcademyStage[];
  team: AcademyTeam[];
  availableNames: string[];
  groundControlMessage: GroundControlMessage;
  introductionComplete: boolean;
  dashboardClicked: boolean;
  mapPopupShown: boolean;

  constructor(data?: Partial<Academy>) {
    this.administrator = new AcademyAdministrator(data?.administrator);
    this.astronaut = new AcademyAstronaut(data?.astronaut);
    this.daysCompleted =
      data && data.daysCompleted && data.daysCompleted >= 0
        ? data.daysCompleted
        : 0; //Might want to use a function to calculate this at the end
    this.currentDay =
      data && data.currentDay && data.currentDay > 0 ? data.currentDay : 1;
    this.introductionComplete =
      data && data.introductionComplete && data.introductionComplete === true
        ? true
        : false;
    this.availableNames =
      data && data.availableNames ? data.availableNames : [];
    this.groundControlMessage = {
      email: 'test@test.com',
      subject: 'Patient 1234 contacted you',
      body: 'They need help with ordering the VR kit',
      buttonText: 'Contact ground control',
      buttonColour: '#00ff00',
    };
    this.dashboardClicked = data && data.dashboardClicked ? true : false;
    this.mapPopupShown = data && data.mapPopupShown ? true : false;

    this.team = teamSeed;
    this.stages = stagesSeed;

    // Hydrate completed data
    if (data && data.stages && data.stages.length > 0) {
      // Loop through seed stages
      this.stages.forEach((stage) => {
        // Find the existing stage data
        const userStage = data.stages?.find((s) => s.level === stage.level);
        if (userStage) {
          // Hydrate the stage
          stage.completed = userStage.completed;
          stage.locked = userStage.locked;
          stage.badgeShown = userStage.badgeShown;
          stage.completionAudioPlayed = userStage.completionAudioPlayed;
        }

        if (userStage && userStage.completed) {
          stage.completed = true;
        }

        if (userStage && userStage.days) {
          userStage.days.forEach((userDay) => {
            const day = stage.days.find((d) => d.number === userDay.number);

            if (day) {
              day.dayComplete = userDay.dayComplete;
              day.extensionComplete = userDay.extensionComplete;
              day.extensionClicked = userDay.extensionClicked;
              day.dailyBriefClicked = userDay.dailyBriefClicked;
              day.funFactsClicked = userDay.funFactsClicked;
              day.completionAudioPlayed = userDay.completionAudioPlayed;
              day.activities.forEach((activity) => {
                const userActivity = userDay.activities.find(
                  (a) => a.name === activity.name
                );

                if (userActivity && userActivity.completed) {
                  activity.completed = true;
                }
              });
            }
          });
          // Loop through the seed stage days

          stage.days.forEach((day) => {
            const userDay = userStage.days.find((d) => d.number === day.number);

            if (userDay && userDay.dayComplete === true) {
              day.dayComplete = true;
            }

            if (userDay && userDay.evaluationShown === true) {
              day.evaluationShown = true;
            }

            if (userDay && userDay.activities) {
              // Loop through the seed stage day evaluation questions

              day.evaluationQuestions.forEach((evaluationQuestion) => {
                const userEvaluationQuestion =
                  userDay.evaluationQuestions?.find(
                    (e) => e.question === evaluationQuestion.question
                  );

                if (userEvaluationQuestion && userEvaluationQuestion.answer) {
                  evaluationQuestion.answer = userEvaluationQuestion.answer;
                }
              });
            }
          });
        }
      });
    }

    // populate and hydrate the badges.. this might be better done as a calculation based on the content completed
    for (let i = 1; i <= 9; i++) {
      if (data && data.badges && data.badges.length > 0) {
        const existingBadge = data.badges.find((b) => b.stage === i);
        const completed =
          existingBadge && existingBadge.completed === true ? true : false;
        this.badges.push({
          stage: i,
          completed: completed,
        });
      } else {
        this.badges.push({
          stage: i,
          completed: false,
        });
      }
    }
  }
}

export class AcademyAdministrator {
  pin: string;

  constructor(data?: Partial<AcademyAdministrator>) {
    this.pin = data && data.pin ? data.pin : '031122';
  }
}

export interface AcademyBadge {
  stage: number;
  completed: boolean;
}

export class AcademyAstronaut {
  id: string;
  nickName: string;

  constructor(data?: Partial<AcademyAstronaut>) {
    this.id = data && data.id ? data.id : '1234';
    this.nickName = data && data.nickName ? data.nickName : 'Astronaut';
  }
}
