import { Component, Input, OnInit } from "@angular/core";
import { AcademyActivityItemDetailed } from "../academy-activity-item-detailed/academy-activity-item-detailed.component";
@Component({
    selector: "contact-groundcontrol",
    templateUrl: "./contact-groundcontrol.component.html",
    styleUrls: ["./contact-groundcontrol.component.scss"]
})

export class ContactGroundControl implements OnInit {
    @Input() groundControlMessage: string = "";
    @Input() groundControlButtonText: string = "";
    
    constructor(
        private academyActivityItemDetailed: AcademyActivityItemDetailed
    ) { }

    ngOnInit(): void {
    }
    sendMail(){
        this.academyActivityItemDetailed.sendMail()
    }
}