import { AcademyStage } from '../components/academy-stage/academy-stage.component';
import { ActivityContent } from './academyActivityContent';

export class AcademyActivity {
  number: number;
  name: string;
  label: string;
  description: string;
  completed: boolean;
  content: ActivityContent[];
  pingGroundControl: boolean;
  constructor(data?: Partial<AcademyActivity>) {
    this.number = data?.number || 0;
    this.name = data?.name || 'Activity Name';
    this.label = data?.label || '';
    this.description = data?.description || '';

    this.completed = data?.completed || false;
    this.content = data?.content || [];
    this.pingGroundControl = data?.pingGroundControl || false;
  }
}
