import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AcademyService } from "src/app/services/academy.service";
@Component({
    selector: "team-member",
    templateUrl: "./team-member.component.html",
    styleUrls: ["./team-member.component.scss"]
})

export class TeamMember implements OnInit {
    members: any
    constructor(private route: ActivatedRoute, private academyService: AcademyService) {
        this.members = this.academyService.getRolesFromColor(this.route.snapshot.paramMap.get('name') || 'red')
    }
    ngOnInit(): void {
    }
}