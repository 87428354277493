<top-panel></top-panel>
<div class="text-center flex flex-col items-center justify-start bg-cover h-screen mt-10 laptop:mt-0 laptop:mb-10">
  <div class="max-w-[1200px] px-10 relative pb-[20vw]">
    <h1 class="mt-[65px] tablet:mt-[10vw]">Badge Log</h1>
    <div
      class="mb-10 bg-gray-200 rounded-full h-10 dark:bg-gray-700 flex items-center justify-between border-4 border-[#46a5ab]">
      <div class="bg-[#46a5ab] h-10 rounded-full flex items-center justify-start"
        [ngStyle]="{ width: completionPercentage }">
        <h2 class="font-bold text-xl ml-5 text-white mb-1">Progress</h2>
      </div>
      <div class="absolute right-[3vw]">
        <h2 class="font-bold text-xl mr-10 mb-1 text-white">
          {{ completedBadges }}/{{ badges.length }}
        </h2>
      </div>
    </div>

    <div class="grid grid-cols-3 tablet:grid-cols-4 laptop:grid-cols-5 gap-x-6 gap-y-4 tablet:gap-x-14 tablet:gap-y-10">

      <!-- <div class="flex flex-wrap gap-10 max-w-[95vw] justify-center m-auto items-center w-screen h-[50vh]"> -->
      <div *ngFor="let badge of badges">
        <badge [stage]="badge.stage" [completed]="badge.completed"></badge>
      </div>
    </div>
    <dashboard></dashboard>
  </div>
</div>