import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AcademyService } from 'src/app/services/academy.service';
import { Checklist } from '../checklist/checklist.component';
import { MailingService } from 'src/app/services/mailing.service';
import { AcademyDay } from '../academy-day/academy-day.component';
import { AcademyStage } from 'src/app/models/academystage';
import { AcademyActivity } from 'src/app/models/academyActivity';
import { SwiperOptions } from 'swiper';
@Component({
  selector: 'academy-activity-item-detailed',
  templateUrl: './academy-activity-item-detailed.component.html',
  styleUrls: ['./academy-activity-item-detailed.component.scss'],
})
export class AcademyActivityItemDetailed implements OnInit {
  activityNumber: number = 0;
  activity!: AcademyActivity;
  stage!: AcademyStage;
  day!: AcademyDay;
  content!: any;
  activityBrief!: any;
  error: boolean = false;
  pageError: string = '';
  emailSent: boolean = false;
  userText: string = '';
  isOnSurvey: boolean = false;
  pingGroundControl: boolean = false;

  @Input() index!: number;
  @Input() title: string = "Captain's Checklist";
  @Input() subtitle: string = '';

  config: SwiperOptions = {
    loop: true,
    pagination: { el: '.swiper-pagination', clickable: true },
    preloadImages: false,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    spaceBetween: 30,
    slidesPerView: 1,
  };
  constructor(
    private route: ActivatedRoute,
    private academyService: AcademyService,
    private router: Router,
    private checklist: Checklist,
    private mailingService: MailingService
  ) {}
  ngOnInit(): void {
    const stage = this.academyService.getStage(
      Number(this.route.snapshot.paramMap.get('stage') || '1')
    );
    const day = this.academyService.getDay(
      Number(this.route.snapshot.paramMap.get('day') || '1')
    );

    if (stage && stage.level) {
      this.stage = stage;
      this.day = day;
      this.activityNumber = Number(
        this.route.snapshot.paramMap.get('activity') || '1'
      );
      const activity = this.academyService.getCurrentActivity(
        this.activityNumber,
        this.stage.level,
        this.day.number
      );

      if (activity) {
        this.activity = activity;
        if (
          this.activity.description === 'Space Academy Survey' ||
          'Final Findings Survey'
        ) {
          this.isOnSurvey = true;
        }
        this.content = this.activity.content;
      } else {
        this.pageError = 'Could not find activity';
      }
    } else {
      this.pageError = 'Stage or day does not exist';
    }
    if (this.activity) {
      if (this.activity.pingGroundControl) {
        this.pingGroundControl = true;
      } else {
        this.pingGroundControl = false;
      }
    }
  }

  completeTask() {
    if (this.checklist.checkCompletion()) {
      this.activity.completed = true;
      this.academyService.storeAcademy();
      this.router.navigate([
        '/academy/' + this.stage.level + '/' + this.day.number,
      ]);
    } else {
      this.error = true;
      setTimeout(() => {
        this.error = false;
      }, 3000);
    }
  }
  sendMail() {
    this.emailSent = true;
    this.mailingService.sendMail(this.academyService.academy.astronaut.id, '');
    setTimeout(() => {
      this.emailSent = false;
    }, 3000);
  }
}
