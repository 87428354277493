import { Component, Input, OnInit } from '@angular/core';
import { AcademyService } from 'src/app/services/academy.service';
import { MailingService } from 'src/app/services/mailing.service';
import { Router } from '@angular/router';
import { AcademyActivity } from 'src/app/models/academyActivity';
@Component({
  selector: 'survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss'],
})
export class Survey implements OnInit {
  @Input() activity!: AcademyActivity;
  survey: any;
  error: boolean = false;
  constructor(
    private academyService: AcademyService,
    private mailingService: MailingService,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.survey = this.activity.content.find(
      (content: any) => content.type === 'survey'
    )!.content;
    if (this.survey) {
      this.survey.forEach((question: any) => {
        if (question.type === 'number') {
          question.answer = 5;
        }
      });
    }
  }

  sendSurvey() {
    this.error = false;
    this.survey.forEach((question: any) => {
      if (question.type === 'smileys') {
        if (question.answer === undefined || question.answer === '') {
          this.error = true;
          setTimeout(() => {
            this.error = false;
          }, 3000);
        }
      }
    });

    if (!this.error) {
      this.mailingService.sendSurvey(
        this.academyService.academy.astronaut.id,
        this.survey
      );
      this.activity.completed = true;
      this.academyService.storeAcademy();
      this.router.navigate(['/academy/7/20']);
    }
  }

  setAnswer(event: any, id: number) {
    this.survey.find((question: any) => question.id === id).answer =
      event.target.id;
  }
}
