<div
  class="flex w-screen h-screen p-16 flex-col items-center justify-center bg-[url('/assets/img/background_base.webp')] bg-cover">
  <div
    class="bg-[url('/assets/img/launch.webp')] absolute top-[10vw] left-[35vw] w-[10vw] bg-cover animate-bounce-slow">
  </div>
  <div
    class="bg-[url('/assets/img/iss.webp')] absolute top-[15vh] tablet:top-[15vh] left-[58vw] h-[15vw] w-[15vw] tablet:h-[10vw] tablet:w-[10vw] bg-cover animate-spin-slow">
  </div>
  <div
    class="bg-[url('/assets/img/mars_rover.webp')] absolute top-[5vh] left-[30vw] h-[15vw] w-[15vw] tablet:h-[10vw] tablet:w-[10vw] bg-cover animate-spin-reverse">
  </div>
  <div
    class="bg-[url('/assets/img/mars_exploration.webp')] absolute top-[75vh] tablet:top-[65vh] left-[20vw] h-[13vw] w-[13vw] tablet:h-[9vw] tablet:w-[9vw] bg-cover animate-spin-reverse">
  </div>
  <div
    class="bg-[url('/assets/img/star_sailor.webp')] absolute top-[78vh] tablet:top-[68vh] left-[45vw] h-[15vw] w-[15vw] tablet:h-[10vw] tablet:w-[10vw] bg-cover animate-spin-slow">
  </div>
  <div
    class="bg-[url('/assets/img/return_to_earth.webp')] absolute top-[85vh] tablet:top-[75vh] left-[71vw] h-[15vw] w-[15vw] tablet:h-[10vw] tablet:w-[10vw] bg-cover animate-bounce-slow">
  </div>
  <div class="mb-5 text-center z-20">
    <h1>
      {{ headingText }}
    </h1>
    <input autofocus #input1 (focus)="input1.value = ''" (keyup)="
        input1.value.length == 1 ? input2.focus() : ''; checkInput(input1)
      " maxlength="1"
      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
      type="number" [ngClass]="error ? 'border-red-600' : 'border-white'" autofocus />
    <input #input2 (focus)="input2.value = ''" (keyup)="
        input2.value.length == 1 ? input3.focus() : ''; checkInput(input2)
      " maxlength="1"
      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
      type="number" [ngClass]="error ? 'border-red-600' : 'border-white'" />
    <input #input3 (focus)="input3.value = ''" (keyup)="
        input3.value.length == 1 ? input4.focus() : ''; checkInput(input3)
      " maxlength="1"
      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
      type="number" [ngClass]="error ? 'border-red-600' : 'border-white'" />
    <input #input4 (focus)="input4.value = ''" (keyup)="
        input2.value.length == 1 ? submit.focus() : ''; checkInput(input4)
      " maxlength="1"
      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
      type="number" [ngClass]="error ? 'border-red-600' : 'border-white'" />
  </div>
  <button #submit (click)="verifyUser(input1, input2, input3, input4)" class="btn btn-primary">
    Log in
  </button>
</div>