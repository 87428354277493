import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'video-component',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  standalone: false
})
export class VideoComponent implements OnInit {
  @Input() videoSrc: string = '';
  safeVideoSrc: string = '';
  constructor(private sanitizer: DomSanitizer) { }
  ngOnInit(): void {
    this.safeVideoSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.videoSrc + '?autoplay=1&showinfo=0&rel=0'
    ) as string;
  }
}
