<top-panel></top-panel>
<div id="desktopView" class="academyMap pt-[7vw] flex w-full flex-col">
  <div
    class="flex justify-center mt-[7vw] absolute top-[40%] translate-y-[-50%] left-[50%] translate-x-[-50%]"
  >
    <div class="flex" *ngFor="let stage of stages">
      <academy-stage
        class="z-10"
        [locked]="stage.locked"
        [imgUrl]="stage.imgUrl"
        [level]="stage.level"
        [size]="stage.map.size"
        [top]="stage.map.top"
        [left]="stage.map.left"
        [name]="stage.name"
        [completed]="stage.completed"
        [days]="stage.days"
      ></academy-stage>
    </div>
    <svg
      class="z-0 w-[70vw]"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1172.85 535.55"
    >
      <defs>
        <style>
          .cls-1,
          .cls-2 {
            fill: none;
            stroke: #fff;
            stroke-linecap: round;
            stroke-miterlimit: 10;
            stroke-width: 6px;
          }
          .cls-2 {
            stroke-dasharray: 0 0 102.94 51.47;
          }
          .cls-3 {
            fill: #fff;
          }
        </style>
      </defs>
      <path
        class="cls-1"
        d="M39.85,387.6c-8.39-14.48-15.17-29.75-20.49-45.52"
      />
      <path
        class="cls-2"
        d="M7.48,292.06C-10.68,168.39,46.28,33.34,122.92,8.14c96.95-31.88,157.48,134.38,323.15,134.02,140.5-.31,163.75-120.03,323.15-132.77,114.47-9.15,250.78,40.74,328.16,150.3,83.86,118.74,97.02,305.47,22.55,356.96-82.26,56.87-186.55-109.9-408.32-100.2-87.84,3.84-142.47,33.11-195.13,61.16"
      />
      <path
        class="cls-1"
        d="M493.68,489.61c-14.7,7.57-29.6,14.74-45.38,20.95"
      />
      <path
        class="cls-3"
        d="M475.78,468.58c1.34,.97,1.64,2.85,.67,4.19l-26.9,37.19,45.08,8.65c1.63,.31,2.7,1.88,2.39,3.51s-1.9,2.69-3.52,2.38l-49.7-9.54c-1-.19-1.85-.89-2.22-1.84-.38-.95-.24-2.04,.36-2.86l29.66-41.01c.35-.48,.81-.83,1.32-1.03,.92-.37,2.01-.27,2.87,.36Z"
      />
    </svg>
  </div>
</div>
<div
  id="mobileView"
  class="academyMap flex flex-col items-center justify-start py-[20vw] gap-5"
>
  <h1 class="text-white text-[5vw]">Stages and progress</h1>
  <div
    *ngFor="let stage of stages"
    class="flex flex-col bg-[#3D507A] rounded-[30px] border-l-2 border-t-2 border-white"
    [ngClass]="
      stage.locked
        ? 'cursor-not-allowed bg-gray-600'
        : 'cursor-pointer bg-[#3D507A]'
    "
  >
    <div
      class="flex"
      [ngClass]="stage.locked ? 'grayscale' : ''"
      [routerLink]="stage.locked ? null : '/academy/' + stage.level"
    >
      <img
        *ngIf="stage.locked"
        src="/assets/img/padlock_icon.svg"
        class="absolute translate-x-[72vw] translate-y-[-2vw] w-[10vw] z-10"
      />
      <img
        *ngIf="stage.completed"
        src="/assets/img/badge_icon.svg"
        class="absolute translate-x-[72vw] translate-y-[-2vw] w-[10vw] z-10"
      />
      <div class="w-[30vw] flex justify-center items-center">
        <img class="max-h-[25vw] p-4" [src]="stage.imgUrl" />
      </div>
      <div
        class="flex flex-col items-start justify-around rounded-r-[30px] p-2 w-[50vw] h-[25vw]"
        [style.background]="stage.stageColor"
      >
        <h2 class="px-2 text-[4vw]" [style.color]="stage.stageTextColor">
          {{ stage.name }}
        </h2>
        <div class="flex">
          <div *ngFor="let day of stage.days">
            <!-- <div
              class="w-[3vw] h-[3vw] m-2 rounded-full border-[3px] border-white"
              [ngClass]="
                day.dayComplete
                  ? 'rounded-full border-[1px] border-white bg-white'
                  : 'rounded-full bg-transparent'
              "
            ></div> -->
            <img
              src="/assets/img/star.svg"
              class="w-[5vw] max-w-[50px] m-2"
              [ngClass]="day.dayComplete ? '' : 'grayscale'"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<dashboard></dashboard>

<div
  *ngIf="!academy.mapPopupShown"
  class="fixed top-0 left-0 w-full h-full flex justify-center items-center backdrop-blur-md text-white"
>
  <div class="absolute top-0 left-0 w-full h-full" (click)="setPopup()"></div>
  <div
    class="bg-[#46a5ab] w-[80vw] laptop:w-6/12 mx-auto rounded-[40px] shadow-lg py-4 text-left px-6"
  >
    <div class="flex justify-center items-center pb-3">
      <h1 class="text-xl laptop:text-4xl text-center font-bold">
        Welcome to Space Academy!
      </h1>
    </div>
    <p class="text-center text-lg laptop:text-2xl">
      Work your way through each stage of the map to complete your mission.
      Don't forget to click complete after each day and earn your mission
      badges!
    </p>
    <div class="flex justify-center pt-10">
      <button
        class="bg-[#48a5aa] rounded-full text-xl tablet:text-[2vw] laptop:text-[1.5vw] px-8 py-2 z-[20] cursor-pointer shadow-2xl hover:shadow-lg transition-shadow border-4 border-t-white border-r-[#008890] border-b-[#008890]"
        (click)="setPopup()"
      >
        Okay
      </button>
    </div>
  </div>
</div>
