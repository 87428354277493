import { Component, Input, OnInit } from '@angular/core';
import { AcademyDay } from '../academy-day/academy-day.component';
import { Location } from '@angular/common';
import { AcademyService } from 'src/app/services/academy.service';
import { Academy } from 'src/app/models/academy';
@Component({
  selector: 'badges',
  templateUrl: './badges.component.html',
  styleUrls: ['./badges.component.scss'],
})
export class Badges implements OnInit {
  academy: Academy;
  days: AcademyDay[];
  completedBadges: number = 0;
  constructor(
    private location: Location,
    private academyService: AcademyService
  ) {
    this.academy = this.academyService.getAcademy();
    this.days = this.academyService.getDays();
    this.badges = this.academy.badges;
    this.getComplete();
  }

  @Input() completionPercentage: string = '99%';
  badges: any;
  ngOnInit(): void {
    this.calculateCompletionPercentage();
    this.academyService.checkDayCompletion();
    document.body.style.background = 'url("/assets/img/background_base.webp")';
  }

  getComplete() {
    this.badges.forEach((badge: any) => {
      if (badge.completed) {
        this.completedBadges++;
      }
    });
  }
  calculateCompletionPercentage() {
    this.completionPercentage =
      (this.completedBadges / this.badges.length) * 100 + '%';
  }
}
