import { Component, OnInit } from '@angular/core';
import { Academy } from 'src/app/models/academy';
import { AcademyService } from 'src/app/services/academy.service';
@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class Dashboard implements OnInit {
  emailSent: boolean = false;
  isOpen: boolean = false;
  isOnMap: boolean = false;
  isOnBadges: boolean = false;
  isOnTeam: boolean = false;
  isMobile: boolean = false;
  academy: Academy;
  constructor(private academyService: AcademyService) {
    this.academy = academyService.getAcademy();
    if (window.innerWidth < 654) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  ngOnInit(): void {
    this.setDashboard();
    document.addEventListener('click', (e) => {
      let target = e.target as HTMLElement;
      let container = document.querySelector('#dashboardContainer');
      if (this.isOpen) {
        if (!container?.contains(target)) {
          this.toggle();
        }
      }
    });
    this.setButtons();
  }

  setDashboard() {
    window.addEventListener('resize', () => {
      if (window.innerWidth < 654) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    });
  }

  toggle() {
    this.academy.dashboardClicked = true;
    this.academyService.storeAcademy();
    let dashboard = document.querySelector('#dashboardContainer');
    if (!this.isMobile) {
      if (this.isOpen) {
        dashboard?.classList.remove('slideIn');
        dashboard?.classList.add('slideOut');
        setTimeout(() => {
          this.isOpen = false;
        }, 500);
      } else {
        dashboard?.classList.remove('slideOut');
        dashboard?.classList.add('slideIn');
        this.isOpen = true;
      }
    }
    if (this.isMobile) {
      if (this.isOpen) {
        dashboard?.classList.remove('slideInMobile');
        dashboard?.classList.add('slideOutMobile');
        setTimeout(() => {
          this.isOpen = false;
        }, 500);
      } else {
        dashboard?.classList.remove('slideOutMobile');
        dashboard?.classList.add('slideInMobile');
        this.isOpen = true;
      }
    }
  }
  setButtons() {
    if (window.location.href.endsWith('academy')) {
      this.isOnMap = true;
    }
    if (window.location.href.endsWith('badges')) {
      this.isOnBadges = true;
    }
    if (window.location.href.endsWith('team')) {
      this.isOnTeam = true;
    }
  }
}
