import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AcademyService } from 'src/app/services/academy.service';
import { MailingService } from 'src/app/services/mailing.service';
import { ActivatedRoute } from '@angular/router';
import { AcademyActivity } from 'src/app/models/academyActivity';
import { Location } from '@angular/common';

@Component({
  selector: 'top-panel',
  templateUrl: './top-panel-component.html',
  styleUrls: ['./top-panel-component.scss'],
})
export class TopPanel implements OnInit {
  @Input() dailyBrief: any;
  @Input() funFacts: any;
  @Input() extension: any;
  @Input() activity: AcademyActivity = new AcademyActivity();
  @Input() extensionComplete: boolean = false;
  @Input() extensionClicked: boolean = false;
  @Input() dailyBriefClicked: boolean = false;
  @Input() funFactsClicked: boolean = false;
  @Input() groundControl: boolean = false;
  @Input() dayComplete: boolean = false;
  @Input() pingGroundControl: boolean = false;
  @Output() dailyBriefToggle = new EventEmitter<boolean>();
  @Output() funFactsToggle = new EventEmitter<boolean>();
  emailSent: boolean = false;
  isOnAcademy: boolean = false;
  showGroundControlMailPopup: boolean = false;
  constructor(
    private mailingService: MailingService,
    private academyService: AcademyService,
    private route: ActivatedRoute,
    private _location: Location
  ) {}

  ngOnInit(): void {
    this.isOnAcademy =
      window.location.href.includes('academy') &&
      window.location.href.split('academy')[1] === '';
  }

  backClicked() {
    this._location.back();
  }

  questionGroundControlMail() {
    this.pingGroundControl = false;
    this.showGroundControlMailPopup = true;
  }
  sendMail() {
    this.emailSent = true;
    this.mailingService.sendMail(
      this.academyService.academy.astronaut.id,
      this.activity.description
    );
    setTimeout(() => {
      this.emailSent = false;
      this.showGroundControlMailPopup = false;
    }, 3000);
  }
  openDailyBrief() {
    this.dailyBriefToggle.emit(true);
    this.clickDailyBrief();
  }
  openFunFacts() {
    this.clickFunFacts();
    this.funFactsToggle.emit(true);
  }
  clickExtension() {
    this.academyService.visitExtension(
      Number(this.route.snapshot.paramMap.get('day'))
    );
  }
  clickDailyBrief() {
    this.academyService.visitDailyBrief(
      Number(this.route.snapshot.paramMap.get('day'))
    );
  }
  clickFunFacts() {
    this.academyService.visitFunFacts(
      Number(this.route.snapshot.paramMap.get('day'))
    );
  }
}
