<top-panel></top-panel>
<div id="extension" class="h-screen">
  <div
    class="flex flex-col py-[20vw] laptop:py-[10vw] justify-center items-center text-center w-[95vw] laptop:w-[80vw] m-auto gap-2">
    <h1 class="text-white">
      More Activities
    </h1>
    <div *ngFor="let extensionItem of extension">
      <div *ngIf="extensionItem.type === 'paragraph'" class="mt-1">
        <p class="text-white">
          {{ extensionItem.text }}
        </p>
      </div>
      <div *ngIf="extensionItem.type === 'external'" class="mt-1">
        <p class="text-white">
          {{ extensionItem.headline }}
        </p>
        <a href="{{ extensionItem.url }}" target="_blank">
          <button *ngIf="extensionItem.type === 'external'" class="btn py-5 rounded-xl border-2 border-white w-[75vw]"
            [style.background]="stage.stageColor" [style.color]="stage.stageTextColor">
            {{ extensionItem.text }}
          </button>
        </a>
      </div>
    </div>
    <button routerLink=".." (click)="completeExtension()"
      class="m-10 border-2 border-b-[#008890] border-t-white w-[80vw] laptop:w-[60vw] max-w-[500px] text-white dayCompleteBtn bg-[#48a5aa] rounded-full text-lg laptop:text-2xl px-8 py-2 cursor-pointer hover:drop-shadow-2xl transition-drop-shadow">
      Completed extension
    </button>
  </div>
</div>
<dashboard></dashboard>