<div class="absolute cursor-pointer hover:drop-shadow-2xl">
  <div
    class="flex flex-col items-center justify-end bg-cover relative w-[100px]"
    [ngStyle]="{ top: top, left: left }"
    [routerLink]="locked ? null : '/academy/' + level"
    [ngClass]="locked ? 'cursor-not-allowed grayscale' : 'cursor-pointer'"
  >
    <img [src]="imgUrl" class="w-[6vw]" />
    <button
      class="stageNameTab rounded-full m-1 p-1 text-white w-[15vw] desktop:w-[10vw] p-2 text-[1.2vw] desktop:text-[0.8vw]"
      [style.color]="determineTextColor()"
      [style.background]="determineBackgroundColor(true)"
    >
      {{ name }}
    </button>
    <div class="flex w-[200px] justify-center">
      <div *ngFor="let day of days">
        <img
          src="/assets/img/star.svg"
          class="w-[3vw] max-w-[30px] m-2"
          [ngClass]="day.dayComplete ? '' : 'grayscale'"
        />
      </div>
    </div>
  </div>
</div>
