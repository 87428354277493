<div class="flex justify-start items-center w-[90%] m-auto">
  <div class="flex flex-col items-start gap-5">
    <div *ngFor="let item of finalSurvey">
      <div *ngIf="item.type === 'headline'" class="text-start">
        <h2 class="mb-5 text-center text-[3vw] laptop:text-[1.2vw]">
          {{ item.text }}
        </h2>
      </div>
      <div *ngIf="item.type === 'smileys'">
        <h2 class="mb-5 text-[3vw] laptop:text-[1.2vw]">{{ item.question }}</h2>
        <smileys (setAnswer)="setAnswer($event, item.id)"></smileys>
      </div>
      <div *ngIf="item.type === 'freeText'" class="text-start">
        <h2 class="mb-5 text-[3vw] laptop:text-[1.2vw]">
          {{ item.question }}<br /><small>*required</small>
        </h2>

        <textarea
          class="w-[80vw] laptop:w-[60vw] text-black p-2 rounded-lg resize-none focus:outline-none textareaSmall border-4"
          maxlength="300"
          required
          [(ngModel)]="item.answer"
        ></textarea>
      </div>
      <div *ngIf="item.type === 'number'" class="w-[70vw]">
        <h2 class="mb-5 text-[3vw] laptop:text-[1.2vw] text-start">
          {{ item.question }}
        </h2>
        <div class="flex gap-2 items-center">
          <input
            [(ngModel)]="item.answer"
            type="range"
            value="5"
            required
            min="1"
            max="10"
            oninput="this.nextElementSibling.value = this.value"
            class="w-1/2"
          />
          <output class="text-[3vw] laptop:text-[1.8vw] ml-2" id="output"
            >5</output
          >
        </div>

        <div class="flex justify-between">
          <p>(it made little / no difference)</p>
          <p>(it made it more fun / provided distraction)</p>
        </div>
      </div>
      <div *ngIf="item.type === 'wordBank'">
        <h2 class="text-start mb-5 text-[3vw] laptop:text-[1.2vw]">
          {{ item.question }}<br /><small>*please select 3</small>
        </h2>
        <div
          class="wordBank flex flex-wrap gap-2 justify-center border-4 p-5 rounded-xl border-transparent"
        >
          <div *ngFor="let option of item.options">
            <div>
              <button
                class="rounded-xl p-2 bg-[#48a5aa] text-white text-[3vw] laptop:text-[1.2vw] hover:bg-white hover:text-[#48a5aa] hover:scale-105 transition-all duration-300"
                [ngClass]="{
                  'bg-white text-[#48a5aa]': item.answer.includes(option)
                }"
                (click)="selectOption(item.answer, option)"
              >
                {{ option }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<button
  (click)="sendSurvey()"
  class="doneBtn text-[#46a5ab] m-5 p-5 self-center bg-[#fff5e6] w-[40vw] bg-[#48a5aa] rounded-full text-2xl px-8 py-2 z-[20] cursor-pointer hover:shadow-2xl transition-shadow mb-[10vh]"
>
  Send Survey
</button>
