import { Component, Input, OnInit } from '@angular/core';
import { AcademyService } from '@services/academy.service';
import { MailingService } from '@services/mailing.service';
import { Router } from '@angular/router';
import { AcademyActivity } from '@models/academyActivity';

@Component({
  selector: 'final-survey',
  templateUrl: './final-survey.component.html',
  styleUrls: ['./final-survey.component.scss'],
  standalone: false
})
export class FinalSurvey implements OnInit {
  @Input() activity!: AcademyActivity;
  finalSurvey: any;
  error: boolean = false;
  constructor(
    private academyService: AcademyService,
    private mailingService: MailingService,
    private router: Router
  ) { }
  ngOnInit(): void {
    this.finalSurvey = this.activity.content.find(
      (content: any) => content.type === 'finalSurvey'
    )!.content;
    if (this.finalSurvey) {
      this.finalSurvey.forEach((question: any) => {
        if (question.type === 'number') {
          question.answer = 5;
        }
      });
    }
  }

  sendSurvey() {
    this.error = false;
    if (
      this.finalSurvey.find((question: any) => question.type === 'wordBank')
        .answer.length !== 3
    ) {
      document
        .querySelector('.wordBank')
        ?.classList.remove('border-transparent');
      document.querySelector('.wordBank')?.classList.add('border-red-500');

      this.error = true;
      setTimeout(() => {
        document.querySelector('.wordBank')?.classList.remove('border-red-500');
        document
          .querySelector('.wordBank')
          ?.classList.add('border-transparent');
        this.error = false;
      }, 3000);
    }

    this.finalSurvey.forEach((question: any) => {
      let textareas = document.querySelectorAll('.textareaSmall');
      textareas.forEach((textarea: any) => {
        if (textarea.value === '') {
          this.error = true;
          textarea.classList.add('border-red-500');
          textarea.placeholder = 'Please fill this out';
          setTimeout(() => {
            this.error = false;
            textarea.classList.remove('border-red-500');
            textarea.placeholder = '';
          }, 3000);
        }
      });
    });
    if (!this.error) {
      this.mailingService.sendFinalSurvey(
        this.academyService.academy.astronaut.id,
        this.finalSurvey
      );
      this.activity.completed = true;
      this.academyService.storeAcademy();
      this.router.navigate(['/academy/8/21']);
    }
  }

  selectOption(answer: any, option: any) {
    if (answer.includes(option)) {
      answer.splice(answer.indexOf(option), 1);
    } else if (answer.length < 3) {
      answer.push(option);
    }
  }
  setAnswer(event: any, id: number) {
    this.finalSurvey.find((question: any) => question.id === id).answer =
      event.target.id;
  }
}
