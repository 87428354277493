import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { AcademyService } from '../services/academy.service';

@Injectable({
  providedIn: 'root',
})
export class ProgGuard  {
  constructor(private academyService: AcademyService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const stage = this.academyService.getStage(
      Number(route.paramMap.get('stage'))
    );
    const day = this.academyService.getDay(Number(route.paramMap.get('day')));
    if (stage && stage.locked) {
      this.router.navigate([
        '/academy',
        this.academyService.lastUnlockedStage(),
      ]);
      return false;
    }
    if (day && stage && stage.locked) {
      this.router.navigate([
        '/academy',
        this.academyService.lastUnlockedStage(),
      ]);
      return false;
    }
    return true;
  }
}
