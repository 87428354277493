<top-panel></top-panel>
<ng-container *ngIf="stage">
  <div class="w-screen h-screen absolute z-0 py-[10vw]">
    <img *ngIf="stage.level !== 1" (click)="previousPage()" src="/assets/img/left_arrow.svg" alt="left arrow"
      class="arrow absolute left-0 top-[50%] h-[200px] cursor-pointer hover:drop-shadow-2xl translate-y-[-50%]" />
    <img *ngIf="stage.level !== academy.stages.length" (click)="nextPage()" src="/assets/img/right_arrow.svg"
      alt="right arrow"
      class="arrow absolute right-0 top-[50%] h-[200px] cursor-pointer hover:drop-shadow-2xl translate-y-[-50%]" />
  </div>
  <div id="desktopView" (touchstart)="swipe($event, 'start')" (touchend)="swipe($event, 'end')"
    class="pt-[5vw] laptop:pt-0 overflow-hidden text-center w-screen flex items-center justify-center min-h-screen flex flex-col items-center text-white pb-20"
    [style.background-image]="determineBackground()" [ngClass]="stage.locked ? 'grayscale' : ''">
    <div class="flex">
      <h1 class="self-start mb-5 xl-desktop:mb-10 mt-20 xl-desktop:mt-0 px-5 mt-5">
        {{ stage.name }}
      </h1>
    </div>
    <div class="p-4 text-center">
      <h3>{{stage.description}}</h3>
    </div>
    <div
      class="px-6 pt-4 pb-2 text-center flex flex-wrap gap-10 gap-y-20 items-center justify-center max-w-[90vw] mb-10 xl-desktop:mb-0">
      <div *ngFor="let day of stage.days">
        <academy-day *ngIf="stage?.locked !== undefined" [locked]="stage.locked" [day]="day"></academy-day>
      </div>
    </div>
  </div>

  <div>
    <div *ngIf="showingBadge" class="fixed z-[200] inset-0 overflow-y-auto flex justify-center items-center">
      <badge-achievement (closeBadge)="closeBadge($event)" [stage]="stage.level"></badge-achievement>
    </div>
  </div>

  <div id="mobileView" (touchstart)="swipe($event, 'start')" (touchend)="swipe($event, 'end')"
    class="academyMap h-screen flex flex-col items-center justify-start pt-20 pb-[200%] gap-5"
    [style.background-image]="determineBackground()" [ngClass]="stage.locked ? 'grayscale' : ''">
    <h1 class="text-white text-center mt-10 mb-0">
      {{ stage.name }}
    </h1>
    <div class="text-white text-center pb-2">
      <h3>{{stage.description}}</h3>
    </div>
    <div *ngFor="let day of stage.days"
      class="max-w-lg cursor-pointer rounded-[30px] border-l-4 border-t-4 border-white z-10"
      [style.background]="determineBackgroundColor()" [style.text]="determineTextColor()">
      <div class="flex gap-5 h-auto min-h-[150px] w-[80vw]" [routerLink]="stage.locked ? null : [day.number]">
        <img [ngClass]="!stage.completed ? 'grayscale' : ''" *ngIf="
            day.number === stage.days[stage.days.length - 1].number &&
            !stage.locked
          " src="/assets/img/badge_icon.svg" class="absolute translate-x-[72vw] translate-y-[-2vw] w-[10vw] z-10" />
        <img *ngIf="stage.locked" src="/assets/img/padlock_icon.svg"
          class="absolute translate-x-[72vw] translate-y-[-2vw] w-[10vw] z-20" />
        <div class="bg-[#3D507A] min-w-[20vw] rounded-l-[30px] flex justify-center items-center">
          <h1 class="text-white text-[10vw]">{{ day.number }}</h1>
        </div>
        <div class="flex flex-col justify-between pt-4 pr-2">
          <h3>{{ day.description }}</h3>
          <div class="relative flex justify-start bottom-[15px] gap-2">
            <div *ngFor="let activity of day.activities">
              <img src="/assets/img/star.svg" alt="star" [ngClass]="!activity.completed ? 'grayscale' : ''"
                class="w-8" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<dashboard></dashboard>