<div
  class="flex flex-col items-center w-screen h-screen justify-center bg-[url('/assets/img/background_base.webp')] bg-cover"
>
  <h1 class="text-[6vw] mb-20 laptop:text-[3vw] text-center text-white">
    Introduction for astronauts
  </h1>
  <video
    controls
    autoplay
    class="w-full laptop:w-[50vw] max-w-[1000px] max-h-[600px] rounded-lg border-8 border-[#fff5e6]"
  >
    <source src="/assets/video/introduction.mp4" type="video/mp4" />
  </video>
  <button
    (click)="setIntroductionShown()"
    class="text-[#fff5e6] bg-[#48a5aa] rounded-full text-[2vw] px-8 mt-[5vw] laptop:mt-[2vw] py-2 cursor-pointer z-[20]"
  >
    Enter Dashboard
  </button>
</div>
