<div class="flex flex-col items-center justify-center mt-2">
  <div
    class="flex flex-col items-center justify-center w-full"
    [ngClass]="
      error
        ? 'border-t-8 border-red-500 rounded-xl'
        : 'border-t-4 border-l-4 border-[#fff5e6] rounded-xl'
    "
  >
    <div class="flex flex-col w-full items-center">
      <ul
        class="w-full text-sm font-medium text-gray-900 rounded-lg bg-[#E84E3F] dark:text-white"
      >
        <h1
          class="text-[2.4vw] laptop:text-[1.6vw] xl-desktop:text-[1.2vw] text-start ml-2 p-5 text-[#fff5e6]"
        >
          {{ checklistHeadline }}
        </h1>
        <div
          *ngFor="let item of checklistItems | keyvalue"
          class="flex flex-col items-center justify-center w-full"
        >
          <li class="w-full rounded-t-lg border-gray-200 px-5">
            <hr />
            <div
              class="flex flex-col gap-2 items-center justify-start w-full flex-wrap"
              *ngIf="
                item.value ===
                'Understand the control panel buttons and dashboard'
              "
            >
              <div class="flex items-center pl-3 justify-between w-full">
                <label
                  for="{{ item.key }}"
                  class="text-start py-3 m-2 w-1/8 text-[2.2vw] laptop:text-[1.4vw] xl-desktop:text-[1vw] font-medium text-[#fff5e6]"
                  >{{ item.value }}</label
                >
                <input
                  id="{{ item.key }}"
                  type="checkbox"
                  value=""
                  class="mr-5 w-8 h-8 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-500 dark:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500"
                  [checked]="completed"
                />
              </div>
            </div>
            <div
              class="flex items-center pl-3"
              *ngIf="
                item.value !==
                'Understand the control panel buttons and dashboard'
              "
            >
              <label
                for="{{ item.key }}"
                class="text-start py-3 m-2 w-full text-[2.2vw] laptop:text-[1.4vw] xl-desktop:text-[1vw] font-medium text-[#fff5e6]"
                >{{ item.value }}</label
              >

              <input
                id="{{ item.key }}"
                type="checkbox"
                value=""
                class="mr-5 w-8 h-8 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-500 dark:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500"
                [checked]="completed"
              />
            </div>
          </li>
        </div>
      </ul>
    </div>
  </div>
  <div class="h-[50px]" *ngIf="error">
    <p class="text-red-500">
      Please tick all the boxes before you proceed, otherwise contact ground
      control for help.
    </p>
  </div>
</div>
