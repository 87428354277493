import { Component, Input, OnInit } from '@angular/core';
import { Academy } from 'src/app/models/academy';
import { AcademyStage } from 'src/app/models/academystage';
import { AcademyService } from 'src/app/services/academy.service';

@Component({
  selector: 'academy-stage-map',
  templateUrl: './academy-stage-map.component.html',
  styleUrls: ['./academy-stage-map.component.scss'],
})
export class AcademyStageMap implements OnInit {
  academy: Academy;
  stages: AcademyStage[];
  @Input() stage: string = 'Pre-launch';
  @Input() disabledStage: boolean = false;
  @Input() stageDays: number[] = [1, 2];

  constructor(private academyService: AcademyService) {
    this.academy = academyService.getAcademy();
    this.stages = this.academy.stages;
  }

  ngOnInit(): void {
    document.body.style.background = 'url("/assets/img/background_base.webp")';
  }

  determineBackgroundColor(dayCompletion: boolean, stage: number) {
    if (dayCompletion) {
      return this.stages[stage - 1].stageColor;
    }
    return 'white';
  }
  setPopup() {
    this.academy.mapPopupShown = true;
    this.academyService.storeAcademy();
  }
}
