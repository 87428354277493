<div
  class="border-t-4 border-l-4 rounded-xl overflow-hidden shadow-lg text-center text-white bg-[#46a5ab] relative w-[95vw] tablet:w-[90vw] laptop:w-[70vw] max-h-[60vh] z-[200]"
>
  <div class="px-6 py-4">
    <h1 class="text-[4vw] tablet:text-[2.5vw] tablet:mb-5">
      Day {{ day.number }}: Evaluation
    </h1>
    <h2
      class="text-[3vw] tablet:text-[2vw]"
      [ngClass]="error ? 'text-red-500' : 'text-white'"
    >
      {{
        error
          ? "Please select an option for all questions."
          : "Feedback to mission control"
      }}
    </h2>
  </div>
  <div *ngFor="let question of questions" class="px-6 pt-4">
    <div class="flex justify-between ml-5 mb-1">
      <div>
        <p class="text-[3vw] tablet:text-[1.6vw] text-left mb-2 mr-5">
          {{ question }}
        </p>
      </div>
      <div class="flex gap-5">
        <smileys [id]="question" (setAnswer)="setAnswer($event)"></smileys>
      </div>
    </div>
  </div>
  <div class="flex justify-end m-5">
    <button
      (click)="sendEvaluation()"
      class="bg-[#E84E3F] border-x-4 border-y-2 rounded-full text-[1.2vw] tablet:text-[2vw] laptop:text-[1.2vw] px-8 py-2 z-[20] cursor-pointer hover:drop-shadow-2xl transition-drop-shadow"
    >
      Send >
    </button>
  </div>
</div>
