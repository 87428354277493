import { Component, Input, OnInit } from '@angular/core';
import { AcademyDay } from 'src/app/models/academyday';
import { AcademyService } from 'src/app/services/academy.service';

@Component({
  selector: 'academy-stage',
  templateUrl: './academy-stage.component.html',
  styleUrls: ['./academy-stage.component.scss'],
})
export class AcademyStage implements OnInit {
  @Input() level: number = 1;
  @Input() days: AcademyDay[] = [];
  @Input() size: string = '200px';
  @Input() top: string = '0px';
  @Input() left: string = '0px';
  @Input() name: string = 'Pre-launch';
  @Input() completed: boolean = false;
  @Input() imgUrl: string = '';
  @Input() locked: boolean = true;
  stageNameTab: string = '';

  constructor(private academyService: AcademyService) {
    this.days = this.academyService.getDays();
  }
  ngOnInit(): void {
    this.determineStageNameTab();
  }
  get isComplete(): boolean {
    let complete = true;
    this.days.forEach((day) => {
      if (!day.dayComplete) {
        complete = false;
      }
    });
    return complete;
  }
  determineBackgroundColor(dayCompletion: boolean) {
    if (dayCompletion) {
      return this.academyService.getStageColor(this.level);
    }
    return 'transparent';
  }
  determineTextColor() {
    if (this.name === 'Return to Earth') {
      return '#0B023D';
    } else {
      return 'white';
    }
  }
  determineStageNameTab() {
    this.stageNameTab = this.name.toLowerCase().replaceAll(' ', '_');
  }
}
