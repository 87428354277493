<div *ngIf="!isMobile" id="dashboardContainer" class="fixed z-20"
  [ngClass]="!isOpen ? 'bottom-[7vw]' : 'bottom-[25vw]'">
  <img src="/assets/img/bottom_panel.svg" class="w-screen fixed left-0" alt="Bottom control panel" />
  <img routerLink="/academy/welcome-video" src="/assets/img/play-button.svg"
    class="w-[5vw] fixed translate-y-[230%] right-[15vw] z-10 cursor-pointer" alt="Tim Peake Play button" />

  <img routerLink="/academy/welcome-video" src="/assets/img/timpeake.webp"
    class="w-[15.8vw] fixed translate-y-[80%] rounded right-[9.5vw] cursor-pointer" alt="Tim peak video screenshot" />

  <div id="dashboard-actions" class="fixed w-[15vw] left-[50%] translate-x-[-53%] translate-y-[30%] z-[500]">
    <button (click)="toggle()"
      class="dashboardBtn w-100 p-1 px-2 rounded-xl text-[1.8vw] rounded-xl bg-[#FFF4E6] text-[#d55542] hover:shadow-2xl transition-shadow cursor-pointer">
      Dashboard
    </button>
  </div>
  <div *ngIf="!academy.dashboardClicked"
    class="fixed w-[217px] xl-desktop:w-[327px] h-[5vw] left-[calc(50%-109px)] xl-desktop:left-[calc(50%-164px)] bottom-[1.9vw] xl-desktop:bottom-[2.4vw] translate-y-[26%] left-[50%] bg-transparent border-8 border-[#FFF4E6] rounded-[40%] animate-ping z-[0]">
  </div>
  <div class="fixed left-[50%] translate-x-[-50%] mt-[8vw] flex flex-col align-center gap-1 tablet:gap-2 laptop:gap-5">
    <button routerLink="/academy" class="dashboardBtn w-[30vw] py-[.4vw] rounded-xl text-[1.8vw]" [ngClass]="
        isOnMap
          ? 'bg-[#f1dbb9] text-[#d55542]'
          : 'bg-[#FFF4E6] text-[#d55542] hover:shadow-2xl transition-shadow	 cursor-pointer'
      ">
      Mission Map
    </button>
    <button routerLink="/academy/badges" class="dashboardBtn w-[30vw] py-[.4vw] rounded-xl text-[1.8vw]" [ngClass]="
        isOnBadges
          ? 'bg-[#f1dbb9] text-[#d55542]'
          : 'bg-[#FFF4E6] text-[#d55542] hover:shadow-2xl transition-shadow cursor-pointer'
      ">
      Badge Log
    </button>
    <button routerLink="/academy/team" class="dashboardBtn w-[30vw] py-[.4vw] rounded-xl text-[1.8vw]" [ngClass]="
        isOnTeam
          ? 'bg-[#f1dbb9] text-[#d55542]'
          : 'bg-[#FFF4E6] text-[#d55542] hover:shadow-2xl transition-shadow cursor-pointer'
      ">
      Mission Crew
    </button>
    <button class="text-transparent z-[20] absolute left-[-16vw] bottom-[5.5vw]" routerLink="/setup">
      setup
    </button>
  </div>
</div>
<div *ngIf="isMobile" id="dashboardContainer" class="fixed z-20"
  [ngClass]="!isOpen ? 'bottom-[11vw]' : 'bottom-[45vw]'">
  <img src="/assets/img/mobile_bottom_control_panel_base.svg" class="w-screen fixed left-0"
    alt="Bottom control panel" />
  <button (click)="toggle()"
    class="fixed w-[30vw] translate-y-[30%] left-[50%] translate-x-[-50%] dashboardBtn p-1 px-2 rounded-xl text-[3vw] rounded-xl bg-[#FFF4E6] text-[#d55542] hover:shadow-2xl transition-shadow cursor-pointer z-[500]">
    Dashboard
  </button>
  <div *ngIf="!academy.dashboardClicked"
    class="fixed w-[150px] h-[6.5vw] left-[calc(50%-75px)] bottom-[1.8vw] translate-y-[26%] left-[50%] bg-transparent border-4 border-[#FFF4E6] rounded-[40%] animate-ping z-[0]">
  </div>
  <div class="fixed left-[50%] translate-x-[-50%] mt-[12vw] flex flex-col align-center gap-1">
    <button routerLink="/academy" [ngClass]="
        isOnMap ? 'bg-[#f1dbb9] text-[#d55542]' : 'bg-[#FFF4E6] text-[#d55542]'
      " class="dashboardBtn w-[80vw] py-[0.4vw] rounded-xl text-[#d55542] text-[4vw] bg-[#f1dbb9]">
      Mission Map
    </button>
    <button routerLink="/academy/badges" [ngClass]="
        isOnBadges
          ? 'bg-[#f1dbb9] text-[#d55542]'
          : 'bg-[#FFF4E6] text-[#d55542]'
      " class="dashboardBtn w-[80vw] py-[0.4vw] rounded-xl text-[#d55542] text-[4vw] bg-[#f1dbb9]">
      Badge Log
    </button>
    <button routerLink="/academy/team" [ngClass]="
        isOnTeam ? 'bg-[#f1dbb9] text-[#d55542]' : 'bg-[#FFF4E6] text-[#d55542]'
      " class="dashboardBtn w-[80vw] py-[0.4vw] rounded-xl text-[#d55542] text-[4vw] bg-[#f1dbb9]">
      Mission Crew
    </button>
  </div>
</div>