import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Academy } from 'src/app/models/academy';
import { AcademyService } from 'src/app/services/academy.service';
@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class Login implements OnInit {
  academy: Academy;
  pin: string = '0000';
  headingText: string = 'Enter PIN';
  error: boolean = false;
  constructor(private router: Router, private academyService: AcademyService) {
    this.academy = academyService.getAcademy();
  }

  ngOnInit(): void {}
  verifyUser(
    input1: HTMLInputElement,
    input2: HTMLInputElement,
    input3: HTMLInputElement,
    input4: HTMLInputElement
  ) {
    let pin = input1.value + input2.value + input3.value + input4.value;

    if (this.academy.astronaut.id.toString() === pin) {
      if (this.academy.introductionComplete) {
        this.router.navigate(['/academy']);
      } else {
        this.router.navigate(['/introduction']);
      }
    } else {
      this.error = true;
      this.headingText = 'Incorrect PIN, try again';
      input1.value = '';
      input2.value = '';
      input3.value = '';
      input4.value = '';
      setTimeout(() => {
        this.headingText = 'Enter your PIN';
        this.error = false;
      }, 2000);
    }
  }
  checkInput(event: HTMLInputElement) {
    if (event.value.length > 1) {
      event.value = '';
    }
  }
}
