import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.scss'],
  standalone: false
})
export class Checklist implements OnInit {
  @Input() title: string = '';
  @Input() subtitle: string = '';
  @Input() groundControlNeeded: boolean = false;
  @Input() checklistItems: string[] = [];
  @Input() checklistHeadline: string = '';
  @Input() error: boolean = false;
  @Input() completed: boolean = false;
  ngOnInit(): void { }

  checkCompletion(): boolean {
    return (
      document.querySelectorAll('input[type="checkbox"]:checked').length ===
      document.querySelectorAll('input[type="checkbox"]').length
    );
  }
}
