import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'smileys',
  templateUrl: './smileys.component.html',
  styleUrls: ['./smileys.component.scss'],
})
export class Smileys implements OnInit {
  @Input() id: string = '';
  @Input() limited: boolean = false;
  @Output() setAnswer = new EventEmitter<string>();
  sad: boolean = false;
  neutral: boolean = false;
  happy: boolean = false;
  constructor() {}
  ngOnInit(): void {}

  setEvaluationAnswer(event: any) {
    if (event.target.id === 'sad' || event.target.parentElement.id === 'sad') {
      this.sad = true;
      this.neutral = false;
      this.happy = false;
    }
    if (
      event.target.id === 'neutral' ||
      event.target.parentElement.id === 'neutral'
    ) {
      this.sad = false;
      this.neutral = true;
      this.happy = false;
    }
    if (
      event.target.id === 'happy' ||
      event.target.parentElement.id === 'happy'
    ) {
      this.sad = false;
      this.neutral = false;
      this.happy = true;
    }
    this.setAnswer.emit(event);
  }
}
