import { AcademyTeam } from 'src/app/models/academyTeam';

export const teamSeed = [
  {
    name: 'Own clothes',
    description: '',
    color: '#a1a1a566',
    members: [
      {
        name: 'Consultants',
        additionalInfo:
          'The consultant is the most senior doctor in charge of your care. They are responsible for overseeing diagnosis, care and treatment so lead a team of more junior doctors including registrars. A consultant is always a specialist.',
      },
      {
        name: 'Paediatric Psychology and Liaison Service (PPALS)',
        additionalInfo:
          'You may meet a member of this team, and they will support you with your wellbeing.',
      },
    ],
  },
  {
    name: 'Burgundy',
    description: '',
    color: '#8B2D3B',
    members: [
      {
        name: 'Clinical Nurse Manager',
        additionalInfo:
          'Nurse managers are responsible for managing the resources ensuring standards and quality of care are maintained.',
      },
    ],
  },
  {
    name: 'Navy Blue',
    color: '#244978',
    members: [
      {
        name: 'Senior Charge Nurse',
        additionalInfo: 'This is the nurse in charge of your ward.',
      },
    ],
  },
  {
    name: 'Cornflower Blue',
    color: '#5A83BF',
    members: [
      {
        name: 'Staff Nurse',
        additionalInfo:
          'Staff nurses are responsible for the nursing care you receive.',
      },
    ],
  },
  {
    name: 'Grey',
    color: '#A1A1A5',
    members: [
      {
        name: 'Student Nurse',
        additionalInfo:
          'Student nurses assist more senior nurses and learn to complete a range of medical procedures as part of their degree.',
      },
    ],
  },
  {
    name: 'Mediterranean Blue',
    color: '#4BA6C8',
    members: [
      {
        name: 'Physiotherapist',
        additionalInfo: 'A physiotherapist assists wellbeing through movement.',
      },
      {
        name: 'Occupational Therapist',
        additionalInfo:
          "OT's use activities to help patients function independently.",
      },
      {
        name: 'Dietician',
        additionalInfo:
          'Dieticians are trained in the science of nutrition which they use to provide practical information about food.',
      },
    ],
  },
  {
    name: 'Ocean Blue',
    description:
      'Other healthcare professionals including Healthcare Science staff.',
    color: '#70BFD1',
    members: [
      {
        name: 'Other Healthcare Professionals(eg. Healthcare Science staff)',
        additionalInfo:
          'Healthcare science staff help prevent, diagnose and treat a wide range of medical conditions. Many work directly with patients while others may work in laboratories.',
      },
    ],
  },
  {
    name: 'Pale Sky Blue',
    color: '#8FB1D5',
    members: [
      {
        name: 'Clinical Support Staff',
        additionalInfo:
          'Clinical support staff work with nurses, midwives, doctors and allied health professionals to deliver high-quality care.',
      },
    ],
  },
  {
    name: 'Dark Green',
    color: '#4EAB9E',
    members: [
      {
        name: 'Catering supervisors',
        additionalInfo:
          'Catering supervisors make sure you have highly nutritious, high quality food and drink when you need it.',
      },
      {
        name: 'Domestic supervisors',
        additionalInfo:
          'Domestic supervisors supervise the routine cleaning of the hospital to make sure it meets quality and hygiene standards.',
      },
    ],
  },
  {
    name: 'Mid Green',
    description:
      'A range of staff who undertake non-clinical duties including: Catering and Domestic staff, Porters, Estates, Admin & Clerical',
    color: '#95CBBD',
    members: [
      {
        name: 'Housekeeper',
        additionalInfo:
          'Housekeepers coordinate catering, cleaning and maintain equipment. They work with other staff to ensure you have the correct food and that your bed area is tidy and clean.',
      },
      {
        name: 'Porters',
        additionalInfo:
          'Porters are the heartbeat of NHS hospitals, making sure crucial goods and items are delivered where they are needed most. They are also making sure you are at the right place at the right time to get the treatment you need.',
      },
      {
        name: 'Domestics',
        additionalInfo:
          'Domestics have responsibility for ensuring wards are clean and tidy.',
      },
      {
        name: 'Catering Assistants',
        additionalInfo:
          'Catering assistants help prepare your food and keep a clean kitchen environment.',
      },
      {
        name: 'Ward Clerk',
        additionalInfo:
          'Clerks have a wide range of responsibilities including booking appointments, filing and keeping patient records. Clerks also handle general enquiries from patients and family members and have responsibility for admission to their ward.',
      },
    ],
  },
  {
    name: 'Red',
    color: '#EA131E',
    members: [
      {
        name: "ECHC Children's Wellbeing Team",
        additionalInfo:
          'The CWT are here to support you with a range of arts and wellbeing activities as well as signposting to other support services.',
      },
    ],
  },
  {
    name: 'Dark Blue',
    color: '#294059',
    members: [
      {
        name: 'Teenage Cancer Trust Youth Coordinator (Lochranza only)',
        additionalInfo:
          'Support Coordinators work alongside our nursing teams to help young people deal with the emotional and practical impact of cancer.',
      },
    ],
  },
  {
    name: 'Pink',
    color: '#FF69B4',
    members: [
      {
        name: 'Play Team',
        additionalInfo:
          'The Play team work to promote effective coping mechanisms through play, procedural preparation, procedural support, distraction therapy, education and self-expression activities.',
      },
    ],
  },
] as AcademyTeam[];
