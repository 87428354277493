import { Injectable } from '@angular/core';
import { Academy } from '../models/academy';
import { AcademyStage } from '../models/academystage';
import { AudioService } from './audio.service';
import { MailingService } from './mailing.service';
import { AcademyActivity } from '../models/academyActivity';

@Injectable({ providedIn: 'root' }) // Or 'any' 
export class AcademyService {
  academy!: Academy;

  constructor(
    private audioService: AudioService,
    private mailingService: MailingService
  ) {
    this.restoreAcademy();
  }

  restoreAcademy() {
    this.academy = new Academy(
      JSON.parse(localStorage.getItem('academy') || '{}')
    );
  }

  storeAcademy() {
    localStorage.setItem('academy', JSON.stringify(this.academy));
  }

  checkDayCompletion() {
    this.academy.stages.forEach((stage: { days: any[] }) => {
      stage.days.forEach((day) => {
        if (
          day.activities.every(
            (activity: { completed: boolean }) => activity.completed
          )
        ) {
          day.dayComplete = true;
          this.storeAcademy();
        } else {
          day.dayComplete = false;
          this.storeAcademy();
        }
      });
    });
  }

  completeDay(dayNumber: number) {
    const day = this.academy.stages
      .map((stage: { days: any[] }) => {
        return stage.days;
      })
      .flat()
      .find((day: { number: number }) => day.number === dayNumber);
    if (day && !day.completionAudioPlayed) {
      const stage = this.academy.stages.find(
        (s: { days: any[] }) =>
          s.days.find((d: { number: number }) => d.number === dayNumber) !==
          undefined
      );
      if (stage) {
        const lastDay = stage.days[stage.days.length - 1];
        if (day.number !== lastDay.number) {
          this.audioService.playAudio('day', dayNumber);
          day.completionAudioPlayed = true;
        }
      }
    }
    this.academy.stages
      .map((stage: { days: any[] }) => {
        return stage.days;
      })
      .reduce((a: any, b: any) => a.concat(b))
      .find((day: { number: number }) => day.number === dayNumber)
      .activities.forEach((activity: { completed: boolean }) => {
        activity.completed = true;
      });
    this.academy.stages
      .map((stage: { days: any[] }) => {
        return stage.days;
      })
      .reduce((a: any, b: any) => a.concat(b))
      .find((day: { number: number }) => day.number === dayNumber).dayComplete =
      true;
    this.storeAcademy();
  }

  checkStageCompletion(stageNumber: number) {
    if (
      this.academy.stages[stageNumber - 1].days.every(
        (day: { dayComplete: boolean }) => day.dayComplete
      )
    ) {
      this.academy.stages[stageNumber - 1].completed = true;
      if (this.academy.stages[stageNumber]) {
        this.academy.stages[stageNumber].locked = false;
      }
      this.academy.badges[stageNumber - 1].completed = true;
      if (!this.academy.stages[stageNumber - 1].completionAudioPlayed) {
        this.audioService.playAudio('stage', stageNumber);
        this.mailingService.sendStageCompletionEmail(
          this.academy.astronaut.id,
          stageNumber
        );
      }
      this.academy.stages[stageNumber - 1].completionAudioPlayed = true;
      this.storeAcademy();
    } else {
      this.academy.stages[stageNumber - 1].completed = false;
      this.academy.badges[stageNumber - 1].completed = false;
      this.storeAcademy();
    }

    if (
      this.academy.stages.every(
        (stage: { completed: boolean }) => stage.completed
      )
    ) {
      this.academy.badges[8].completed = true;
      this.storeAcademy();
    }
  }

  getStageLock(stageNumber: number) {
    const stage = this.academy.stages.find(
      (s: { level: number }) => s.level === stageNumber
    );

    if (stage?.locked === true) return true;
    return false;
  }
  lastUnlockedStage() {
    const lastUnlockedStage = this.academy.stages
      .slice()
      .reverse()
      .find((stage: { locked: boolean }) => !stage.locked);
    if (lastUnlockedStage) {
      return lastUnlockedStage.level;
    }
    return 1;
  }

  getRolesFromColor(name: string) {
    return this.academy.team.find(
      (member: { name: string }) => member.name === name
    );
  }

  getStageColor(stageNumber: number): string {
    const stage = this.academy.stages.find(
      (s: { level: number }) => s.level === stageNumber
    );
    if (!stage) return '#ff00ff';
    return stage.stageColor;
  }
  getStageTextColor(stageNumber: number) {
    const stage = this.academy.stages.find(
      (s: { level: number }) => s.level === stageNumber
    );

    if (!stage) return '#ff00ff';
    return stage.stageTextColor;
  }

  getAcademy() {
    if (!this.academy) {
      this.restoreAcademy();
    }
    return this.academy;
  }

  getStage(stageNumber: number): AcademyStage | undefined {
    return this.academy.stages.find(
      (s: { level: number }) => s.level === stageNumber
    );
  }

  getDay(dayNumber: number) {
    return this.academy.stages
      .map((stage: { days: any[] }) => {
        return stage.days.find(
          (day: { number: number }) => day.number === dayNumber
        );
      })
      .find((day: any) => day !== undefined);
  }

  getDays() {
    return this.academy.stages
      .map((stage: { days: any[] }) => {
        return stage.days;
      })
      .reduce((a: any, b: any) => a.concat(b));
  }

  getCurrentActivity(activityNumber: number, stage: number, day: number) {
    return this.academy.stages
      ?.find((s: { level: number }) => s.level === stage)
      ?.days.find((d: { number: number }) => d.number === day)
      ?.activities.find((a: { number: number }) => a.number === activityNumber);
  }
  updateIntroductionShown() {
    this.academy.introductionComplete = true;
    this.storeAcademy();
  }
  setBadge(stage: number) {
    this.academy.stages[stage - 1].badgeShown = true;
    this.storeAcademy();
  }
  getEvaluations() {
    const evaluations: any[] = [];
    this.academy.stages.forEach((stage: any) => {
      stage.days.forEach((day: any) => {
        let evaluationQuestions: any[] = [];
        if (day.evaluationQuestions) {
          day.evaluationQuestions.forEach((evaluationQuestion: any) => {
            evaluationQuestions.push({
              question: evaluationQuestion.question,
              answer: evaluationQuestion.answer,
            });
          });
        }
        evaluations.push({
          day: day.number,
          evaluationQuestions: evaluationQuestions,
        });
      });
    });
    return evaluations;
  }
  saveEvaluation(evaluation: any, day: number) {
    this.academy.stages
      .map((stage: { days: any[] }) => {
        return stage.days;
      })
      .reduce((a: any, b: any) => a.concat(b))
      .find((d: { number: number }) => d.number === day)
      .evaluationQuestions.forEach((evaluationQuestion: any, index: number) => {
        evaluationQuestion.answer = evaluation[index].answer;
      });
    this.storeAcademy();
  }

  unlockProgress(stage: number) {
    this.academy.stages.forEach(
      (
        s: {
          level: number;
          completed: boolean;
          locked: boolean;
          days: any[];
          completionAudioPlayed: boolean;
          badgeShown: boolean;
        },
        index: number
      ) => {
        if (s.level <= stage - 1) {
          s.completed = true;
          s.locked = false;
          s.completionAudioPlayed = true;
          s.badgeShown = true;
          s.days.forEach(
            (d: {
              dayComplete: boolean;
              activities: any[];
              evaluationShown: boolean;
              completionAudioPlayed: boolean;
            }) => {
              d.dayComplete = true;
              d.activities.forEach((a: { completed: boolean }) => {
                a.completed = true;
              });
              d.evaluationShown = true;
              d.completionAudioPlayed = true;
            }
          );
        } else {
          s.completed = false;
          s.locked = true;
          s.completionAudioPlayed = false;
          s.badgeShown = false;
          s.days.forEach(
            (d: {
              dayComplete: boolean;
              activities: any[];
              evaluationShown: boolean;
              completionAudioPlayed: boolean;
            }) => {
              d.dayComplete = false;
              d.activities.forEach((a: { completed: boolean }) => {
                a.completed = false;
              });
              d.evaluationShown = false;
              d.completionAudioPlayed = false;
            }
          );
        }
      }
    );
    this.academy.badges.forEach((b: { completed: boolean }, index: number) => {
      if (index + 2 <= stage) {
        b.completed = true;
      } else {
        b.completed = false;
      }
    });
    if (stage === 1) {
      this.academy.stages[0].locked = false;
      this.checkStageCompletion(stage);
    } else {
      this.checkStageCompletion(stage - 1);
    }

    this.storeAcademy();
  }
  completeExtension(dayNumber: number) {
    this.academy.stages
      .map((stage: { days: any[] }) => {
        return stage.days;
      })
      .reduce((a: any, b: any) => a.concat(b))
      .find(
        (d: { number: number }) => d.number === dayNumber
      ).extensionComplete = true;

    this.storeAcademy();
  }
  visitExtension(dayNumber: number) {
    this.academy.stages
      .map((stage: { days: any[] }) => {
        return stage.days;
      })
      .reduce((a: any, b: any) => a.concat(b))
      .find(
        (d: { number: number }) => d.number === dayNumber
      ).extensionClicked = true;

    this.storeAcademy();
  }
  visitDailyBrief(dayNumber: number) {
    this.academy.stages
      .map((stage: { days: any[] }) => {
        return stage.days;
      })
      .reduce((a: any, b: any) => a.concat(b))
      .find(
        (d: { number: number }) => d.number === dayNumber
      ).dailyBriefClicked = true;

    this.storeAcademy();
  }
  visitFunFacts(dayNumber: number) {
    this.academy.stages
      .map((stage: { days: any[] }) => {
        return stage.days;
      })
      .reduce((a: any, b: any) => a.concat(b))
      .find((d: { number: number }) => d.number === dayNumber).funFactsClicked =
      true;

    this.storeAcademy();
  }

  visitGroundControl(dayNumber: number) {
    this.academy.stages
      .map((stage: { days: any[] }) => {
        return stage.days;
      })
      .reduce((a: any, b: any) => a.concat(b))
      .find((d: { number: number }) => d.number === dayNumber).groundControlClicked =
      true;

    this.storeAcademy();
  }

  storeActivityQuestion(activity: AcademyActivity, dayNumber: number, activityNumber: number) {
    this.academy.stages
      .map((stage: { days: any[] }) => {
        return stage.days;
      })
      .reduce((a: any, b: any) => a.concat(b))
      .find((d: { number: number }) => d.number === dayNumber).activities.find((y: { number: number }) => y.number === activityNumber).data = activity.data;

    this.storeAcademy();
  }
}
