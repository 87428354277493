import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AcademyService } from 'src/app/services/academy.service';
import { AcademyStage } from 'src/app/models/academystage';
import { AcademyDay } from 'src/app/models/academyday';
import { Academy } from 'src/app/models/academy';
@Component({
  selector: 'academy-stage-detailed',
  templateUrl: './academy-stage-detailed.component.html',
  styleUrls: ['./academy-stage-detailed.component.scss'],
})
export class AcademyStageDetailed implements OnInit {
  @Input() stageDays: AcademyDay[] = [];
  @Input() disabledStage: boolean = false;
  academy: Academy;
  level: number = 1;
  stage: AcademyStage | undefined;
  showingBadge: boolean = false;
  private swipeCoord?: [number, number];
  private swipeTime?: number;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private academyService: AcademyService
  ) {
    this.academy = academyService.getAcademy();
    this.load();
    this.checkBadge();
  }
  ngOnInit(): void {
    this.route.params.subscribe(() => {
      this.load();
    });
  }

  load() {
    this.level = parseInt(this.route.snapshot.paramMap.get('stage') || '1');
    this.stage = this.academyService.getStage(this.level);
    if (this.stage) {
      this.academyService.checkStageCompletion(this.stage.level);
      this.academyService.checkDayCompletion();
    }
  }
  checkBadge() {
    if (
      this.academyService.getStage(
        Number(this.route.snapshot.paramMap.get('stage') || '1')
      )!.completed &&
      !this.stage?.badgeShown
    ) {
      this.academyService.setBadge(
        this.academyService.getStage(
          Number(this.route.snapshot.paramMap.get('stage') || '1')
        )!.level
      );
      this.showingBadge = true;
    } else {
      this.showingBadge = false;
    }
  }
  closeBadge(event: boolean) {
    this.showingBadge = event;
  }
  determineBackgroundColor() {
    return this.stage?.stageColor;
  }
  determineTextColor() {
    return this.stage?.stageTextColor;
  }

  determineBackground() {
    const imageName = this.stage?.name
      ? this.stage?.name.toLowerCase().replaceAll(' ', '-')
      : 'unknown';

    if (window.innerWidth < 819) {
      return `url('./assets/img/stages/${imageName}-mobile.webp')`;
    } else {
      return `url('./assets/img/stages/${imageName}.webp')`;
    }
  }

  previousPage() {
    this.router.navigate([`/academy/${this.level - 1}/`]).then(() => {
      this.load();
    });
  }
  nextPage() {
    this.router.navigate([`/academy/${this.level + 1}/`]).then(() => {
      this.load();
    });
  }

  swipe(e: TouchEvent, when: string): void {
    const coord: [number, number] = [
      e.changedTouches[0].clientX,
      e.changedTouches[0].clientY,
    ];
    const time = new Date().getTime();

    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else if (when === 'end') {
      const direction = [
        coord[0] - this.swipeCoord![0],
        coord[1] - this.swipeCoord![1],
      ];
      const duration = time - this.swipeTime!;

      if (
        duration < 1000 &&
        Math.abs(direction[0]) > 30 &&
        Math.abs(direction[0]) > Math.abs(direction[1] * 3)
      ) {
        const swipe = direction[0] < 0 ? 'next' : 'previous';
        if (swipe === 'next' && this.level < this.academy.stages.length) {
          this.nextPage();
        }
        if (swipe === 'previous' && this.level > 1) {
          this.previousPage();
        }
      }
    }
  }
}
