<div class="absolute inset-0 flex justify-center items-center backdrop-blur-md">
  <div
    class="m-5 rounded-xl overflow-hidden shadow-lg text-center w-[70vw] tablet:w-[50vw] laptop:w-[60vw] bg-[#48a5aa] border-t-8 border-l-8 flex flex-col items-center text-white justify-center"
  >
    <div class="px-6 py-4">
      <h1 class="text-[5vw] tablet:text-[3vw] desktop:text-[2vw] mb-5">
        Congratulations!
      </h1>
      <h2 class="text-[4vw] tablet:text-[1vw]">{{ badgeText }}</h2>
    </div>
    <div
      class="px-6 pt-4 pb-2 text-center flex flex-col items-center justify-center"
    >
      <img
        *ngIf="!ECHCCongratulations && !timPeakeCongratulations"
        [src]="findBadge()"
        class="w-[40vw] laptop:w-[20vw] desktop:w-[15vw]"
      />
      <video-component
        *ngIf="ECHCCongratulations"
        [videoSrc]="'https://www.youtube.com/embed/CD_ERa8l9cs'"
      ></video-component>
      <video-component
        *ngIf="timPeakeCongratulations"
        [videoSrc]="'https://www.youtube.com/embed/dCVCTFK0uEc'"
      ></video-component>
      <div class="flex justify-between gap-2 mt-10 mb-5">
        <button
          routerLink="/academy/badges"
          class="bg-[#E84E3F] border-4 border-r-[#9e362a] border-b-[#9e362a] rounded-full text-[3vw] tablet:text-[2vw] laptop:text-[1.5vw] px-8 py-2 z-[20] cursor-pointer hover:shadow-2xl transition-shadow"
        >
          Bagde Log
        </button>
        <button
          (click)="nextStage()"
          class="bg-[#E84E3F] border-4 border-r-[#9e362a] border-b-[#9e362a] rounded-full text-[3vw] tablet:text-[2vw] laptop:text-[1.5vw] px-8 py-2 z-[20] cursor-pointer hover:shadow-2xl transition-shadow"
        >
          {{ buttonText }}
        </button>
      </div>
    </div>
  </div>
</div>
