import { Component, Input, OnInit } from '@angular/core';
import { AcademyDay } from '../academy-day/academy-day.component';
import { AcademyService } from 'src/app/services/academy.service';
import { Academy } from 'src/app/models/academy';
@Component({
  selector: 'badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class Badge implements OnInit {
  academy: Academy;

  @Input() stage!: AcademyDay;
  @Input() completed: boolean = false;

  constructor(private academyService: AcademyService) {
    this.academy = this.academyService.getAcademy();
  }

  ngOnInit(): void {}
  determineBackground() {
    return `./assets/img/badges/stage${this.stage}.webp`;
  }
}
