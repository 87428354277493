import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { AcademyService } from '@services/academy.service';
import { Checklist } from '../checklist/checklist.component';
import { MailingService } from '@services/mailing.service';
import { AcademyDay } from '@components/academy-day/academy-day.component';
import { AcademyStage } from '@models/academystage';
import { AcademyActivity } from '@models/academyActivity';

// import { SwiperOptions } from 'swiper';
@Component({
  selector: 'academy-activity-item-detailed',
  templateUrl: './academy-activity-item-detailed.component.html',
  styleUrls: ['./academy-activity-item-detailed.component.scss'],
  standalone: false
})
export class AcademyActivityItemDetailed implements OnInit {
  activityNumber: number = 0;
  activity!: AcademyActivity;
  stage!: AcademyStage;
  day!: AcademyDay;
  content!: any;
  activityBrief!: any;
  error: boolean = false;
  pageError: string = '';
  emailSent: boolean = false;
  userText: string = '';
  isOnSurvey: boolean = false;
  pingGroundControl: boolean = false;
  activityFn!: AcademyActivity;
  showAnswerResult: boolean = false;
  isAnswerCorrect: boolean = false;
  showPreviousAnswer: boolean = false;
  previousAnswers: { key: string, value: string, answer?: string }[] = [];

  @Input() index!: number;
  @Input() title: string = "Captain's Checklist";
  @Input() subtitle: string = '';

  // config: SwiperOptions = {
  //   loop: true,
  //   pagination: { el: '.swiper-pagination', clickable: true },
  //   preloadImages: false,
  //   navigation: {
  //     nextEl: '.swiper-button-next',
  //     prevEl: '.swiper-button-prev',
  //   },
  //   spaceBetween: 30,
  //   slidesPerView: 1,
  // };
  constructor(
    private route: ActivatedRoute,
    private academyService: AcademyService,
    private router: Router,
    private checklist: Checklist,
    private mailingService: MailingService
  ) {
    this.activityFn = new AcademyActivity();
  }
  ngOnInit(): void {
    const stage = this.academyService.getStage(
      Number(this.route.snapshot.paramMap.get('stage') || '1')
    );
    const day = this.academyService.getDay(
      Number(this.route.snapshot.paramMap.get('day') || '1')
    );

    if (stage && stage.level) {
      this.stage = stage;
      this.day = day;
      this.activityNumber = Number(
        this.route.snapshot.paramMap.get('activity') || '1'
      );
      const activity = this.academyService.getCurrentActivity(
        this.activityNumber,
        this.stage.level,
        this.day.number
      );

      if (activity) {
        this.activity = activity;
        if (
          this.activity.description === 'Space Academy Survey' ||
          'Final Findings Survey'
        ) {
          this.isOnSurvey = true;
        }
        this.content = this.activity.content;
      } else {
        this.pageError = 'Could not find activity';
      }
    } else {
      this.pageError = 'Stage or day does not exist';
    }
    if (this.activity) {
      if (this.activity.pingGroundControl) {
        this.pingGroundControl = true;
      } else {
        this.pingGroundControl = false;
      }
    }
    if (this.content.filter((x: any) => x.type == "question")?.length > 0) {

      const questionContent: any[] = this.content.filter((x: any) => x.type == "question");
      questionContent.forEach(obj1 => {

        this.previousAnswers.push(this.activity?.data?.filter((y: any) => y.key === obj1.id)?.[0]);
        this.previousAnswers.forEach(prevA => {
          if (prevA?.key == obj1?.id) {
            const answer = obj1?.answerOptions?.filter((x: any) => x.id == prevA.value)?.[0]?.answer;
            prevA.answer = answer;
            this.showPreviousAnswer = true;
          }
        });



      });

    }

  }

  completeTask() {
    if (this.checklist.checkCompletion()) {
      this.activity.completed = true;
      this.academyService.storeAcademy();
      this.router.navigate([
        '/academy/' + this.stage.level + '/' + this.day.number,
      ]);
    } else {
      this.error = true;
      setTimeout(() => {
        this.error = false;
      }, 3000);
    }
  }
  sendMail() {
    this.emailSent = true;
    this.mailingService.sendMail(this.academyService.academy.astronaut.id, '');
    setTimeout(() => {
      this.emailSent = false;
    }, 3000);
  }

  answerQuestion(questionId: string, answerId: string) {
    this.showPreviousAnswer = false;
    this.activityFn.setActivityDataValue(questionId, answerId);
    let questions = this.activity.content.filter(x => x.type == "question" && x.id == questionId);
    if (questions?.length) {
      let answerOptions = questions[0].answerOptions ?? [];
      this.showAnswerResult = true;
      if (answerOptions.filter(x => x.correct && x.id == answerId)?.length > 0) {
        this.isAnswerCorrect = true;
      }
      answerOptions.forEach(answer => {

        if (answer.id == answerId) {
          if (answer.correct) {
            answer.answerColor = "#3B7A3F"
          } else {
            answer.answerColor = "#E53935"
          }
        } else {
          answer.answerColor = "#767676"
        }

      });

    }
    var dayNumber = Number(this.route.snapshot.paramMap.get('day'));
    var activityNumber = Number(this.route.snapshot.paramMap.get('activity'));
    this.academyService.storeActivityQuestion(this.activityFn, dayNumber, activityNumber);

  }

}
