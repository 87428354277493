import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AcademyService } from 'src/app/services/academy.service';
import { AcademyDay } from '../academy-day/academy-day.component';
@Component({
  selector: 'evaluation',
  templateUrl: './evaluation.component.html',
  styleUrls: ['./evaluation.component.scss'],
})
export class Evaluation implements OnInit {
  @Input() questions: string[] = [];
  @Input() day!: AcademyDay;
  @Input() evaluation: any;
  @Output() evaluationToggle = new EventEmitter<boolean>();
  astronautID: string = '1234';
  answer: string = '';
  error: boolean = false;
  constructor(private academyService: AcademyService) {
    this.astronautID = this.academyService.getAcademy().astronaut.id;
  }
  ngOnInit(): void {}
  setAnswer(event: any) {
    if (event.target.tagName === 'path') {
      this.evaluation.find(
        (question: { question: string }) =>
          question.question === event.target.parentElement.parentElement.id
      ).answer = event.target.parentElement.id;
    } else {
      this.evaluation.find(
        (question: { question: string }) =>
          question.question === event.target.parentElement.id
      ).answer = event.target.id;
    }
  }
  sendEvaluation() {
    if (
      this.evaluation.every(
        (question: { answer: string }) => question.answer.length > 0
      )
    ) {
      this.academyService.saveEvaluation(this.evaluation, this.day.number);
      this.toggleEvaluation();
    } else {
      this.error = true;
      setTimeout(() => {
        this.error = false;
      }, 3000);
    }
  }
  toggleEvaluation() {
    this.evaluationToggle.emit(false);
  }
}
