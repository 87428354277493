import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { AcademyActivityItem } from '../academy-activity-item/academy-activity-item.component';
import { AcademyService } from '@services/academy.service';
import { AcademyStage } from '@models/academystage';

@Component({
  selector: 'academy-day',
  templateUrl: './academy-day.component.html',
  styleUrls: ['./academy-day.component.scss'],
  standalone: false
})
export class AcademyDay implements OnInit {
  number: number = 0; // integer & unique
  label: string = ''; // "Overview"
  evaluationQuestions: string[] = [];
  description: string = '';
  stage: AcademyStage | undefined;
  level: number = 1;
  subtitle: string = '';
  activities!: AcademyActivityItem[];
  dailyBrief!: any;
  funFacts!: any;
  extension: any;
  evaluationShown: boolean = false;
  extensionClicked: boolean = false;
  dailyBriefClicked: boolean = false;
  funFactsClicked: boolean = false;
  groundControlClicked: boolean = false;
  name: string = 'Day 1';
  dayComplete: boolean = false;
  extensionComplete: boolean = false;
  @Input() day: any;
  @Input() locked: boolean = true;
  @Input() disabled: boolean = false;

  constructor(
    private academyService: AcademyService,
    private route: ActivatedRoute
  ) {
    this.level = parseInt(this.route.snapshot.paramMap.get('stage') || '1');
    this.stage = this.academyService.getStage(this.level);
    this.day = this.academyService.getDay(
      parseInt(this.route.snapshot.paramMap.get('day') || '1')
    );
    this.extensionClicked = this.day.extensionClicked;
    this.dailyBriefClicked = this.day.dailyBriefClicked;
    this.funFactsClicked = this.day.funFactsClicked;
    this.groundControlClicked = this.day.groundControlClicked;
  }
  ngOnInit(): void {
    this.dayComplete = this.day.dayComplete;
  }
  determineBackground() {
    return this.academyService.getStageColor(this.day.stage);
  }
  determineTextColor() {
    return this.academyService.getStageTextColor(this.day.stage);
  }
  determineDayCardBackground() {
    return `url(assets/img/daycards/day${this.day.number}.jpg)`;
  }
}
