import { Component, OnInit } from "@angular/core";
import { AcademyService } from "src/app/services/academy.service";

@Component({
    selector: "team",
    templateUrl: "./team.component.html",
    styleUrls: ["./team.component.scss"]
})

export class Team implements OnInit {
    additionalInformationOpen: boolean = false;
    additionalInformation: string = ""
    team: any;

    constructor(private academyService: AcademyService) {
        this.team = this.academyService.academy.team.sort((a: any, b: any) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        }
        )
     }

    ngOnInit(): void {
    }
}