import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "headline",
    templateUrl: "./headline.component.html",
    styleUrls: ["./headline.component.scss"]
})

export class Headline implements OnInit {
    @Input() title: string = "Captain's Checklist";
    @Input() subtitle: string = "Do you know how to operate these cockpit features? If not contact ground control";
    @Input() groundControlNeeded: boolean = false;
    ngOnInit(): void {
    }
}