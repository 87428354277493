import { AcademyStage } from '@models/academystage';

export const stagesSeed = [
  {
    level: 1,
    name: 'Pre-launch',
    label: 'Pre-launch',
    stageColor: '#E84E3F',
    stageTextColor: '#FFFFFF',
    imgUrl: './assets/img/prelaunch.webp',
    description: 'The first stage of the academy.',
    map: {
      size: '10vw',
      maxWidth: '0.5',
      maxHeight: '0.5',
      top: '19vw',
      left: '-1vw',
      connectionX: '0.5',
      connectionY: '0.5',
    },
    completed: false,
    completionAudioPlayed: false,
    badgeShown: false,
    locked: false,
    days: [
      {
        number: 1,
        label: 'Day 1',
        description: 'Welcome',
        subtitle:
          "Let's start by getting familiar with your surroundings and preparing for your Mission to Mars.",
        stage: 1,
        extensionComplete: false,
        extensionClicked: false,
        funFactsClicked: false,
        dailyBrief: [
          {
            type: 'video',
            url: 'https://www.youtube.com/embed/5Jen4S017uE',
          },
        ],
        extension: [
          {
            type: 'paragraph',
            text: 'Check out the extensions icon to prepare you more for your journey to Mars.',
          },
          {
            type: 'external',
            headline: 'Create your own Space Name:',
            url: 'https://www.namegenerator.co/occupations/astronaut-name-generator',
            text: 'Astronaut Name Generator - Generate Random Realistic Astronaut Names',
          },
          {
            type: 'external',
            headline: 'Explore Space with Paxi and the European Space Agency:',
            url: 'https://www.esa.int/kids/en/about_Paxi/Paxi',
            text: 'ESA - Space for Kids - Paxi',
          },
          {
            type: 'external',
            headline: '',
            url: 'https://www.esa.int/kids/en/Games/Colour_with_Paxi',
            text: 'ESA - Space for Kids - Colour with Paxi',
          },
          {
            type: 'external',
            headline:
              'Before every mission, NASA engineers design a new poster featuring the crew modelled after a favourite sci-fi movie. Check out the design here: ',
            url: 'https://www.nasa.gov/directorates/heo/sfa/sp/expedition-posters-20-29',
            text: "Sci-Fi to Snoopy: NASA's Coolest Mission Posters (Photos) | Space",
          },
          {
            type: 'paragraph',
            text: 'You could use a page from the sketchbook in your Mission Box to design your own poster.',
          },
        ],
        funFacts: [
          {
            type: 'paragraph',
            text: 'Astronauts have their own traditions before they blast off into space. For example every mission has their own small cuddly toy on a chain that is dangled from the instrument panel before lift-off. As well as being a mascot for the mission it also shows when the crew reaches zero gravity as it starts to float! Would you like to choose a mission mascot?',
          },
          { type: 'headline', text: 'Four Truths and a Lie' },
          {
            type: 'paragraph',
            text: 'Four of these traditions are true, one is a lie. Can you spot the one made up tradition?',
          },
          {
            type: 'list',
            listHeadline: 'asdf',
            listItems: [
              'Before Astronauts get on the bus that takes them to the launch pad, they wee on the right hand back wheel of the bus!',
              'Each crew member plants a tree at the site called ‘avenue of heroes’ to make each mission from the last 50 years. Tim Peake did this before he launched in 2015!',
              'While the space shuttle is being pulled along to the launch pad, engineers, staff and the astronaut’s families often place coins along the track. This is believed to be good luck for their mission.',
              'On the run up to the launch, astronauts are isolating for 7 days in a special hotel at the launch site away from their friends and families.  On the morning of the launch, the astronauts sign their hotel door before leaving to the launch site.',
              'Astronauts get to ride on top of the space shuttle as it is being pulled by a train to the launch pad. Their space suits have special magnets on them to make sure they don’t slip off!',
            ],
          },
        ],
        activities: [
          {
            number: 1,
            name: 'Activity 1',
            description: "Captain's Checklist",
            completed: false,
            content: [
              { type: 'headline', text: "Captain's Checklist" },
              {
                type: 'paragraph',
                text: "Before we think about launching you into orbit, you will need to be comfortable and in control of your bedspace, which we will be calling, your space shuttle! Can you complete all the tasks in the Captain's Checklist below?",
              },
              {
                type: 'image',
                url: './assets/img/explainer.webp',
              },
              {
                type: 'checklist',
                checklistHeadline: 'I Know How to:',
                checklistItems: [
                  'Adjust the position of my bed, can you find the controls?',
                  'Switch the lights on/off in my room',
                  "Operate the Space Shuttle's TV",
                  'Understand the control panel buttons and dashboard',
                  'Contact your NHS crew member if you need help',
                  'Meet your hospital crew (Click the button below)',
                ],
                hasGroundControl: true,
                groundControlMessage: 'If not, contact your Mission Crew',
                groundControlButtonText: 'Mission Crew',
              },
            ],
          },
          {
            number: 2,
            name: 'Activity 2',
            description: 'Design Your Mission Patch',
            completed: false,
            content: [
              { type: 'headline', text: 'Design Your Mission Patch' },
              {
                type: 'paragraph',
                text: 'Every successful mission needs a Mission Patch. This is a badge that all crew wear proudly to remind them of the importance of their mission.',
              },
              { type: 'image', url: './assets/photos/5E3A6992.webp' },
              {
                type: 'external',
                url: 'https://blogs.esa.int/tim-peake/2015/12/31/tims-spaceflight-patches/',
                text: "Check out Tim Peake's Mission patches, one with an electric guitar and one with an apple for inspiration",
              },
              {
                type: 'paragraph',
                text: 'You might want to think about your favourite hobbies, sports, places, food, colours etc.',
              },
              {
                type: 'list',
                listHeadline: 'You will need:',
                listItems: [
                  "Mission Badge Template found in your Captain's Log Book",
                  'Stained Glass Pens',
                  'Pencil',
                  'Plastic sheet & paperclip',
                ],
              },
              {
                type: 'orderedList',
                listHeadline: 'Instructions:',
                listItems: [
                  'Turn to the Mission Badge page of your Log Book. Draw your mission patch, remember you can include your favourite hobby, food or animal. Anything you like, it’s your mission!',
                  'To create your stained glass mission patch., place the plastic sheet over your design and secure with the paperclip.',
                  'First trace round your design using the stained glass pens.',
                  'Let your outline dry.',
                  'Colour in your design using the stained glass pens, making sure to fill the whole design with colour.',
                  'Leave overnight to dry.',
                  'Once dry, peel off your mission patch and stick it to your space shuttle window for your crew to see!',
                ],
              },
            ],
          },
          {
            number: 3,
            name: 'Activity 3',
            description: 'Spaceship Control Panel',
            completed: false,
            content: [
              { type: 'headline', text: 'Spaceship Control Panel' },
              {
                type: 'paragraph',
                text: 'To chart your course and travel through space you will need to set up your spaceship’s control panel. You will find a template in your Mission Box to colour in and customise. Once complete, use the Blu Tack from your Mission Box to attach it to your bed.',
              },
              {
                type: 'image',
                url: './assets/img/illustrations/controlpanel.jpg',
              },
            ],
          },
        ],
        evaluationQuestions: [
          {
            question: 'Tell us how your space day went?',
            answer: '',
          },
          {
            question: 'Do you feel more in control?',
            answer: '',
          },
          {
            question: 'Could you complete all the tasks?',
            answer: '',
          },
        ],
        evaluationShown: false,
        dayComplete: false,
        completionAudioPlayed: false,
      },
      {
        number: 2,
        label: 'Day 2',
        description: 'Preparing to Launch',
        subtitle:
          'We are going to think about the best materials to use to design your spacecraft.',
        stage: 1,
        extensionComplete: false,
        extensionClicked: false,
        funFactsClicked: false,
        dailyBrief: [
          {
            type: 'profile',
            description: 'Sophie - Science Officer',
            url: '/assets/img/sophie.webp',
          },
          {
            type: 'audio',
            url: './assets/audio/day2-preparing-to-launch-parto-one-female.mp3',
          },
        ],
        extension: [
          {
            type: 'external',
            headline:
              'Learn more about how to design a spaceship through the extensions icon and listen to some fun facts.',
            url: 'https://spaceplace.nasa.gov/build-a-spacecraft/en/',
            text: 'Build Your Own Spacecraft',
          },
          {
            type: 'external',
            headline: 'Play these fun spacecraft building games:',
            url: 'https://www.esa.int/kids/en/Games/Catch_The_Craft',
            text: 'ESA - Space for Kids - Catch the Craft',
          },
          {
            type: 'external',
            headline: '',
            url: 'https://www.esa.int/kids/en/Games/Spacecraft_Assemble',
            text: 'ESA - Space for Kids - Spacecraft Assemble',
          },
        ],
        funFacts: [
          {
            type: 'paragraph',
            text: 'What do real astronauts take with them on missions into space? Information about the items that astronauts take with them is usually kept very private, but typical Personal Preference Kit (PPK - a small box about the size of a lunchbox) items include family photos, organisational flags, t-shirts, ball-caps, books, religious texts, and personal mementos. Many astronauts bring musical instruments to the space station and leave them there for future use. Lots of astronauts also bring camera gear, as photography is a favourite pastime when orbiting above fantastic views of Earth. What would you bring?',
          },
          {
            type: 'external',
            url: 'https://www.nasa.gov/feature/the-personal-preference-kit-what-astronauts-take-with-them-to-space',
            text: 'The Personal Preference Kit: What Astronauts Take With Them To Space',
          },
        ],
        activities: [
          {
            number: 1,
            name: 'Activity 1',
            description: 'Spacecraft Materials Kit',
            completed: false,
            content: [
              { type: 'headline', text: 'Spacecraft Materials Kit' },
              {
                type: 'profile',
                description: 'Daniel - Space Engineer',
                url: '/assets/img/daniel.webp',
              },
              {
                type: 'audio',
                url: './assets/audio/day2-preparing-to-launch-parto-one-male.mp3',
              },
              {
                type: 'video',
                url: 'https://www.youtube.com/embed/VeRQ1CYW-cg',
              },
              {
                type: 'paragraph',
                text: 'These experiments explore different properties of materials to help discover which materials are best suited for different parts of a spacecraft. It takes a lot of rocket fuel to launch into space but something that is strong, stiff and light in weight to build the space craft.',
              },
              {
                type: 'list',
                listHeadline: 'You will need:',
                listItems: [
                  'Test ramp - contact Mission Control for this',
                  'Box of material cubes',
                  'Marble',
                  'Set of Scales',
                  'Magnet',
                  'Pencil',
                  'Log Book',
                ],
              },
              {
                type: 'orderedList',
                listHeadline: 'Stage 1 - Impact Test - Instructions:',
                listItems: [
                  'Contact Mission Control to receive a test ramp.',
                  'Choose your first material cube to test.',
                  'Slot the material cube into the space at the bottom of the ramp.',
                  'Roll the marble down the ramp from the top.',
                  'Record how far the marble bounces back in your Log Book.',
                  'Repeat with each different material cube.',
                  'Decide which material is best for your space rocket, note it down!',
                ],
              },
              {
                type: 'paragraph',
                text: "The first experiment explores impact. Choosing what material to make your space rocket from is very important. If something hits your rocket in space, you don't want it to break. The more the marble bounces off the material, the less damage your spaceship will suffer!",
              },
              {
                type: 'orderedList',
                listHeadline: 'Stage 2 - Weight Test - Instructions:',
                listItems: [
                  'Weigh the cubes in your hands, one at a time, and write down in your Log Book how heavy you think they are, from lightest (1) to heaviest (9).',
                  'Weigh each cube using the scale and write down in your Log Book how heavy each one is.',
                  'How did your guesses compare to the actual weight - were they similar or different?',
                  'Decide which of these materials is most suitable for designing a spacecraft and why. Record this in your Log Book.',
                ],
              },
              {
                type: 'paragraph',
                text: "The next test considers weight. Choosing what material to make your space rocket from is very important. Spaceships need to be solid for safety, but they also need to be light so that they have a better chance of escaping Earth's gravitational pull into space with less fuel.",
              },
              {
                type: 'paragraph',
                text: "The final experiment is the magnetic test. When moving in space, it can be helpful if the material making up the spacecraft is non-magnetic. Materials which are magnetic can disturb instruments such as the onboard orientation device, which uses the Earth's magnetic field to point the spacecraft in the right direction.",
              },
              {
                type: 'orderedList',
                listHeadline: 'Stage 3 - Magnetic Test - Instructions:',
                listItems: [
                  "Using the magnet, test each material in turn and note down in your Log Book which are magnetic and which aren't.",
                  'After testing each material, look at your chosen materials from the last 2 experiences. Is there one that is best from all the tests? Is a combination of materials better?',
                  'Decide on the final material that is best to build your own spaceship from. Note down in your Log Book why.',
                ],
              },
              {
                type: 'paragraph',
                text: 'You are now ready to design your spaceship!',
              },
              {
                type: 'orderedList',
                listHeadline: 'Conclusion',
                listItems: [
                  'Look at your results for all the stages and choose which material you think is best for each.',
                  'Look and feel (what shape will your rocket be?)',
                  'Weight test (remember you want it to be strong, but not too heavy!) ',
                  'Impact test (remember the more it bounced, the better it will protect against damage)',
                  'Magnetic test – (remember you don’t want it to be magnetic)'
                ],
              },
            ],
          },
          {
            number: 2,
            name: 'Activity 2',
            description: 'Design your Space Shuttle',
            completed: false,
            content: [
              { type: 'headline', text: 'Design your Space Shuttle' },
              {
                type: 'list',
                listHeadline: 'What you will need:',
                listItems: [
                  'Pencils or pens'
                ],
              },
              {
                type: 'image',
                url: './assets/img/illustrations/spaceshuttle.jpg',
              },
              {
                type: 'image',
                url: './assets/img/illustrations/launchpad.webp',
              },
              {
                type: 'orderedList',
                listHeadline: 'Instructions:',
                listItems: [
                  'Use the template with the rocket launch tower to complete your design.',
                  'You have chosen your materials, remember to include these!',
                  'Label the important bits e.g. what is it made out of, how big is it and how many people does it hold?',
                  'Does it have a name?',
                ],
              },
              {
                type: 'paragraph',
                text: "Get inspired by these Nasa space shuttles: Designing A Rocket in Six Easy Steps - Rocketology: NASA's Space Launch System.",
              },
              {
                type: 'list',
                listHeadline: 'Have you included:',
                listItems: [
                  'Main body of the space shuttle',
                  'Nose Cone',
                  'Thrusters',
                  'Door hatch',
                  'Windows',
                  'Fuel tank',
                ],
              },
              {
                type: 'paragraph',
                text: 'If you would like to create your own 3D printed model of a rocket, contact Mission Control and the Engineering Team will arrange to bring you the design equipment.',
              },
              {
                type: 'external',
                headline: 'Here are some tips for designing your rocket:',
                url: 'https://blogs.nasa.gov/Rocketology/2015/07/09/designing-a-rocket-in-six-easy-steps/',
                text: "Designing A Rocket In Six Easy Steps - Rocketology: NASA's Space Launch System",
              },
            ],
          },
        ],
        evaluationQuestions: [
          {
            question: 'Tell us how your space day went?',
            answer: '',
          },
          {
            question: 'Do you feel more in control?',
            answer: '',
          },
          {
            question: 'Could you complete all the tasks?',
            answer: '',
          },
        ],
        evaluationShown: false,
        dayComplete: false,
        completionAudioPlayed: false,
      },
    ],
  },
  {
    level: 2,
    name: 'Launch',
    label: 'Launch',
    stageColor: '#88B26A',
    stageTextColor: '#FFFFFF',
    imgUrl: './assets/img/launch.webp',
    description: 'The second stage of the academy.',
    map: {
      size: '12vw',
      maxWidth: '0.5',
      maxHeight: '0.5',
      top: '-3vw',
      left: '6vw',
      connectionX: '0.5',
      connectionY: '0.5',
    },
    completed: false,
    completionAudioPlayed: false,
    badgeShown: false,
    locked: true,
    days: [
      {
        number: 3,
        label: 'Day 3',
        description: 'Aerodynamics',
        subtitle:
          "Let's try out these experiments and see how the air affects how the object moves. What do you notice?",
        stage: 2,
        extensionComplete: false,
        extensionClicked: false,
        funFactsClicked: false,
        dailyBrief: [
          {
            type: 'profile',
            description: 'Daniel - Space Engineer',
            url: '/assets/img/daniel.webp',
          },
          {
            type: 'audio',
            url: './assets/audio/day3-aerodynamics.mp3',
          },
        ],
        extension: [
          {
            type: 'paragraph',
            text: 'Check out more fun rocket launch experiments:',
          },
          {
            type: 'external',
            headline: 'Cup Glider:',
            url: 'https://bigbangeducation.com.au/flying-cup-glider/',
            text: 'Flying Cup Glider - Big Bang Education',
          },
          {
            type: 'external',
            headline: 'Paper airplane launcher:',
            url: 'https://www.sciencebuddies.org/stem-activities/paper-airplane-launcher',
            text: 'Build a Paper Airplane Launcher',
          },
          {
            type: 'paragraph',
            text: 'Your could also try to make an origami rocket with these tutorials:',
          },
          {
            type: 'external',
            headline: '',
            url: 'https://www.youtube.com/watch?v=tGuZoEwcxJU',
            text: 'Origami: Rocket - How to Make a Paper Rocket Launcher / Spaceship - Easy Origami Rocket Instructions - Youtube',
          },
          {
            type: 'external',
            headline: '',
            url: 'https://www.youtube.com/watch?v=YEfK-xifwVA',
            text: 'Easy Origami Rocket Spaceship Tutorial',
          },
          {
            type: 'external',
            headline: '',
            url: 'https://www.youtube.com/watch?v=DGyho2ry7EY',
            text: 'DIY Origami Paper Rocket That Can Fly !! Easy Tutorial ~ Step By Step - Youtube',
          },
        ],
        funFacts: [
          {
            type: 'paragraph',
            text: 'The first rockets were Chinese fireworks.',
          },
          {
            type: 'paragraph',
            text: "A space shuttle must travel at a speed of 5 miles per second to escape the Earth’s gravity - more than 20 times the speed of sound. It takes a space shuttle 8 ½ minutes to get into space.",
          },
        ],
        activities: [
          {
            number: 1,
            name: 'Activity 1',
            description: 'Straw Rocket',
            completed: false,
            content: [
              { type: 'headline', text: 'Straw Rocket' },
              {
                type: 'paragraph',
                text: "First let’s explore forces. Remember Daniel telling us about the force ‘Thrust’? In this experiment thrust will be created by you blowing down the straw. The harder you blow down the more energy the air will have and the further your rocket will fly.",
              },
              {
                type: 'paragraph',
                text: "The force gravity is pulling your rocket down, while the force from your breath is pushing it forward!",
              },
              {
                type: 'video',
                url: 'https://player.vimeo.com/video/1024757659',
              },
              {
                type: 'list',
                listHeadline: 'You will need:',
                listItems: [
                  'Paper straw',
                  'Plastic pipette',
                  'Tape',
                  'Coloured pens / pencils',
                  'Scissors - ask your Play Specialist',
                  'Rocket Template',
                ],
              },
              {
                type: 'orderedList',
                listHeadline: 'Instructions:',
                listItems: [
                  'Colour in your rocket template and cut it out.',
                  'Cut the thin end off the plastic pipette.',
                  'Tape the pipette to the rocket and slide the straw inside the pipette.',
                  'Give your straw a big puff of air, and watch it take off!',
                  'Record how far it went in your Log Book!',
                  'How does the angle you launch your rocket affect the distance it travels? Try out different angles and record the results.',
                  'What happens to your straw rocket if you point it straight up and blow?',
                  'Try again blowing harder or more softly, how does the shape of the flight change?'
                ],
              },
            ],
          },
          {
            number: 2,
            name: 'Activity 2',
            description: 'Pop-up Rocket',
            completed: false,
            content: [
              { type: 'headline', text: 'Pop-up Rocket' },
              {
                type: 'paragraph',
                text: "This experiment explores how real shuttles launch from Earth into space! When you push down on the rubber band it pushes it upwards and makes it launch. The fins on the side of the rocket help to keep it more stable in the air. Because we are still in Earth’s atmosphere, gravity is still pulling your rocket down and because of this it will eventually fall back down.",
              },
              {
                type: 'video',
                url: 'https://www.youtube.com/embed/v6_S8TnRVJI',
              },
              {
                type: 'list',
                listHeadline: 'You will need:',
                listItems: [
                  '2x paper cups',
                  '2x elastic bands',
                  'Card circle',
                  'Tape',
                  'Stickers and pens/pencils to decorate',
                  'Scissors',
                ],
              },
              {
                type: 'orderedList',
                listHeadline: 'Instructions:',
                listItems: [
                  'Decorate both paper cups - one as a rocket and the other as the base for the rocket launcher, find the spots or stars stickers in your box!',
                  'Cut out a section of the paper circle, about one quarter like a pizza slice.',
                  'Take the large piece and curve it to create a cone shape and tape together. Tape this to the top of the paper cup rocket.',
                  'Cut the small wedge section in half to create 2 triangle shapes, bend along the longer edge so you can stick it on. Tape these to the side of the rocket to create wings.',
                  'Cut 4 small slits on the rim of the rocket paper cup - cut one at the front of the cup, then directly opposite at the back and the other 2 on either side of the cup.',
                  'Slot the elastic bands into the slits - one going across one way, and the second going across the opposite side.',
                  'Your rocket is now ready to launch! Place the rocket on top of the launcher, gently push the rocket down and then release to see your rocket fly into the air!',
                ],
              },
            ],
          },
          {
            number: 3,
            name: 'Activity 3',
            description: 'Balloon Rocket',
            completed: false,
            content: [
              { type: 'headline', text: 'Balloon Rocket' },
              {
                type: 'paragraph',
                text: "What’s the Science Behind It? In this experiment, the rocket is propelled by pressure. When you blow up the balloon, you are filling the balloon with gas particles (mainly oxygen). The pressure inside the balloon serves as the fuel for the rocket. When you release the opening of the balloon, gas quickly escapes to equalize the pressure inside with the air pressure outside of the balloon. As the gases escape from the balloon, the gas particles exert a force on the ground and the air outside of the balloon. According to Newton’s Third Law of Motion, every action has an equal and opposite reaction. Therefore, as the gas is released from the balloon, it pushes against the outside air, and the outside air pushes back. As a result, the rocket is propelled forward by the opposing force which is known as thrust.",
              },
              {
                type: 'video',
                url: 'https://www.youtube.com/embed/3AsJY5hUrlc',
              },
              {
                type: 'list',
                listHeadline: 'You will need:',
                listItems: [
                  '3x balloons',
                  'Paper straw',
                  'String',
                  'Tape',
                  'Clip / clothes peg',
                  'Measuring tape',
                ],
              },
              {
                type: 'orderedList',
                listHeadline: 'Instructions:',
                listItems: [
                  'Thread the string through the straw.',
                  'Tie the string across the room, and pull to make it tight.',
                  'Stretch the balloons to make them easier to blow up.',
                  'Blow up balloon 1, twist the open end and clip it closed, do not tie the balloon.',
                  'Tape the balloon to the straw, make sure the clip is facing the opposite way you want to launch.',
                  'Slide the balloon and straw to the starting line.',
                  'Remove the peg and launch!',
                  'Measure how far it travelled and note it in your Log Book.',
                  'Repeat the process with the next balloon, this time add ‘cargo’ onto your balloon (try taping on some coloured pencils, blue tac or anything else in your room) will it travel further?',
                ],
              },
              {
                type: 'external',
                headline: 'You can see a good example here:',
                url: 'https://www.jpl.nasa.gov/edu/teach/activity/simple-rocket-science/',
                text: 'Educator Guide: Simple Rocket Science | NASA / JPL Edu',
              },
            ],
          },
        ],
        evaluationQuestions: [
          {
            question: 'Tell us how your space day went?',
            answer: '',
          },
          {
            question: 'Do you feel more in control?',
            answer: '',
          },
          {
            question: 'Could you complete all the tasks?',
            answer: '',
          },
        ],
        evaluationShown: false,
        dayComplete: false,
        completionAudioPlayed: false,
      },
      {
        number: 4,
        label: 'Day 4',
        description: 'Lift Off',
        subtitle: 'Are you ready for Lift Off?',
        stage: 2,
        extensionComplete: false,
        extensionClicked: false,
        funFactsClicked: false,
        dailyBrief: [
          {
            type: 'profile',
            description: 'Sophie - Science Officer',
            url: '/assets/img/sophie.webp',
          },
          {
            type: 'audio',
            url: './assets/audio/day4-launching-into-space.mp3',
          },
        ],
        extension: [
          {
            type: 'paragraph',
            text: "We have just listened to the countdown from 10 in English, but astronauts come from all over the world and speak all different languages. In how many languages can you count down from 10? If you're interested in hearing all the different languages, watch this Youtube video of blast off from around the world!",
          },
          {
            type: 'external',
            headline: '',
            url: 'https://www.youtube.com/watch?v=CaIgsevL-2Y',
            text: 'Rocket Launch Countdown Compilation',
          },
          {
            type: 'paragraph',
            text: 'The term astronaut is commonly applied to an individual who has flown in outer space. More specifically, astronauts are those persons who went to space aboard a U.S. spacecraft; individuals who first travelled aboard a spacecraft operated by the Soviet Union or Russia are known as cosmonauts, and those from China are known as taikonauts.',
          },
          {
            type: 'external',
            headline: 'Learn more about how we launch into space here:',
            url: 'https://spaceplace.nasa.gov/launching-into-space/en/',
            text: 'How Do We Launch Things Into Space? | NASA Space Place - NASA Science for Kids',
          },
        ],
        funFacts: [
          {
            type: 'paragraph',
            text: 'In 2013 NASA recruit Victor J. Glover wrote a poem as part of his application and got hired!',
          },
          {
            type: 'paragraph',
            text: "Did you know that before Astronauts climb into their shuttles they have lots of very funny traditions,one is towatch a film the night before launch. Why don't you try one of our favourites:",
          },
          { type: 'paragraph', text: 'The IMAX Space Collection (2001)' },
          { type: 'paragraph', text: 'Wall E' },
          { type: 'paragraph', text: 'Muppets in Space' },
          { type: 'paragraph', text: 'Astroboy' },
          {
            type: 'paragraph',
            text: 'Astro - Star and Nautis - Sailor, so astronaut means Star Sailors.',
          },
          {
            type: 'paragraph',
            text: 'In Russia they go by Cosmonaut - Cosmos - Universe and Nautis - Sailor',
          },
          {
            type: 'paragraph',
            text: "In China they are Taikonaut, Taikong - 'Great Emptiness' or Chinese word for space",
          },
          {
            type: 'paragraph',
            text: 'French - Spationaut - Latin for Spatium (Space) and Greek Nautis - Sailor',
          },
        ],
        activities: [
          {
            number: 1,
            name: 'Activity 1',
            description: 'Lift Off',
            completed: false,
            content: [
              { type: 'headline', text: 'Lift Off' },
              {
                type: 'paragraph',
                text: "It’s time to launch!"
              },
              {
                type: 'paragraph',
                text: "Wave goodbye to the hospital as we set off on our journey to outer space into space!"
              },
              {
                type: 'video',
                url: 'https://www.youtube.com/embed/4gzjRnMI9Ss',
              },
              {
                type: 'centerparagraph',
                text: 'Press the red button to activate the engines and countdown with me from 10…..',
              },
            ],
          },
          {
            number: 2,
            name: 'Activity 2',
            description: 'Apollo Launch VR Experience',
            completed: false,
            pingGroundControl: true,
            content: [
              { type: 'headline', text: 'Apollo Launch VR Experience' },
              { type: 'image', url: './assets/img/apollo-launch.jpg' },
              {
                type: 'centerparagraph',
                text: 'In the next experiment you can be in the seat next to Neil Armstrong and experience the Apollo launch for yourself.',
              },
              {
                type: 'centerparagraph',
                text: 'Contact Mission Control to request the VR headset.',
              },
            ],
          },
          {
            number: 3,
            name: 'Activity 3',
            description: 'Journey Poem',
            completed: false,
            content: [
              { type: 'headline', text: 'Journey Poem' },
              {
                type: 'paragraph',
                text: "Fill in each line in your Captain's Log Book with a word or words about your journey to Mars, starting with each of these letters. We've done the last one for you!",
              },
              { type: 'finishTheLine', firstLetter: 'J', userText: '' },
              { type: 'finishTheLine', firstLetter: 'O', userText: '' },
              { type: 'finishTheLine', firstLetter: 'U', userText: '' },
              { type: 'finishTheLine', firstLetter: 'R', userText: '' },
              { type: 'finishTheLine', firstLetter: 'N', userText: '' },
              { type: 'finishTheLine', firstLetter: 'E', userText: '' },
              { type: 'finishTheLine', firstLetter: 'Y', userText: '' },
              { type: 'finishTheLine', firstLetter: 'T', userText: '' },
              { type: 'finishTheLine', firstLetter: 'O', userText: '' },
              { type: 'finishTheLine', firstLetter: 'M', userText: '' },
              { type: 'finishTheLine', firstLetter: 'A', userText: '' },
              { type: 'finishTheLine', firstLetter: 'R', userText: '' },
              {
                type: 'finishTheLine',
                firstLetter: 'S',
                userText: 'ee you when I return Earth!',
              },
            ],
          },
        ],
        evaluationQuestions: [
          {
            question: 'Tell us how your space day went?',
            answer: '',
          },
          {
            question: 'Do you feel more in control?',
            answer: '',
          },
          {
            question: 'Could you complete all the tasks?',
            answer: '',
          },
        ],
        evaluationShown: false,
        dayComplete: false,
        completionAudioPlayed: false,
      },
    ],
  },
  {
    level: 3,
    name: 'International Space Station',
    label: 'ISS',
    stageColor: '#FF9700',
    stageTextColor: '#FFFFFF',
    imgUrl: './assets/img/stages/international-space-station.webp',
    description: 'The ISS is the place where astronauts live and work while they are in space. It’s the biggest ever object that humans have put into space. 16 countries worked together to build the station– USA, Russia, Japan Canada and many European Space Agency members, including the UK Space Agency!.',
    map: {
      size: '11vw',
      maxWidth: '0.5',
      maxHeight: '0.5',
      top: '4vw',
      left: '22vw',
      connectionX: '0.5',
      connectionY: '0.5',
    },
    completed: false,
    completionAudioPlayed: false,
    badgeShown: false,
    locked: true,
    days: [
      {
        number: 5,
        label: 'Day 5',
        description: 'Gravity',
        subtitle:
          "It's time to carefully dock your spacecraft into the docking port of the International Space Station. Can you successfully navigate a space vehicle to the docking port in the next experiment?",
        stage: 3,
        extensionComplete: false,
        extensionClicked: false,
        funFactsClicked: false,
        dailyBrief: [
          {
            type: 'video',
            url: 'https://www.youtube.com/embed/3PaAS0HHzFc',
          },
          {
            type: 'audio',
            url: './assets/audio/day5-gravity.mp3',
          },
        ],
        extension: [
          {
            type: 'external',
            headline: 'What is it like on the ISS?',
            url: 'https://www.youtube.com/watch?v=B9EKx5l9Pv8',
            text: 'Paxi on the ISS: Living in space',
          },
          {
            type: 'external',
            headline:
              '3D virtual tour of the International Space Station - 3D glasses',
            url: 'https://www.youtube.com/watch?v=ktGPIsJbYng',
            text: '3D virtual tour of the International Space Station',
          },
          {
            type: 'external',
            headline: '',
            url: 'https://iss-sim.spacex.com/',
            text: 'ISS Docking Simulator SpaceX - ISS Docking Simulator',
          },
          {
            type: 'external',
            headline: 'Paxi on the ISS: Living in Space',
            url: 'https://www.youtube.com/watch?v=B9EKx5l9Pv8',
            text: 'Paxi on the ISS: Living in Space - Youtube',
          },
          {
            type: 'external',
            headline: 'Who is currently on the ISS? ISS Live Stream',
            url: 'https://eol.jsc.nasa.gov/ESRS/HDEV/',
            text: 'ISS High Definition Live Streaming Video of the Earth',
          },
          {
            type: 'paragraph',
            text: 'When viewing the ISS tracker app, it may look like the International Space Station is travelling really slowly, but it is actually travelling fast enough to fly over the equivalent of 64 football pitches every second! It only appears to be moving slowly across the screen because the Earth is so big! The ISS circles the Earth every 90 minutes. It would take an aeroplane approximately 45 hours to circle the Earth once.',
          },
        ],
        funFacts: [
          {
            type: 'paragraph',
            text: 'The ISS flies around the world every 90 minutes, travelling at 5 miles per second. In the space of just 24 hours, the space station makes 16 orbits of Earth, travelling through 16 sunrises and sunsets.',
          },
          {
            type: 'paragraph',
            text: "It is 357ft long from end to end - that's about the same as a football pitch. After the moon, the ISS is the second brightest object in our night sky - you don't even need a telescope to see it zoom over your house",
          },
          {
            type: 'paragraph',
            text: "Unlike space shuttles or space capsules, the International Space Station (ISS) never returns to Earth. This means that the crewmembers on board the ISS are responsible not only for operating the Station, but for maintaining it as well. Astronauts do not have only complex and difficult tasks to perform: they must also take care of household chores! Otherwise, small debris could become lodged in the circuits and cause damage. It could also end up in astronauts' lungs. Housekeeping tasks include using a vacuum cleaner to clean the control panels and air vents and to remove all undesirable matter from the ambient air (crumbs, hair, etc.), disinfecting experimental or other equipment to eliminate any potentially harmful bacteria, and cleaning the bathroom and kitchen areas.",
          },
        ],
        activities: [
          {
            number: 1,
            name: 'Activity 1',
            description: 'Docking Target Game',
            completed: false,
            content: [
              { type: 'headline', text: 'Docking Target Game' },
              {
                type: 'video',
                url: 'https://www.youtube.com/embed/QNcYpxDtpNI',
              },
              {
                type: 'list',
                listHeadline: 'You will need:',
                listItems: [
                  '2x Table tennis balls',
                  'Paper cup',
                  'Paper / card',
                  'Straws',
                  'Scissors',
                  'Plasticine',
                  'Tape',
                  'Laminated bullseye',
                ],
              },
              {
                type: 'orderedList',
                listHeadline: 'Instructions:',
                listItems: [
                  'Use the materials listed above to create a space shuttle that will keep the table tennis balls safely in the cup when being dropped from a height. You should not cover the cup.',
                  'Place the bullseye target on the floor. Test your space shuttle by dropping it from a height. Are you able to land within the target zone without the table tennis balls bouncing out?',
                  "Keep trying to drop your space shuttle from different heights. What do you notice? Is it easier to successfully dock from a closer distance or further away?",
                ],
              },
            ],
          },
          {
            number: 2,
            name: 'Activity 2',
            description: 'Gravity',
            completed: false,
            content: [
              { type: 'headline', text: 'Gravity' },
              {
                type: 'video',
                url: 'https://www.youtube.com/embed/FudwyVSQUZA',
              },
              {
                type: 'paragraph',
                text: 'Mass is the amount of matter a thing contains, for example 1kg contains 1,000g. ',
              },
              {
                type: 'paragraph',
                text: 'Anything with a mass has what we call a gravitational pull. The earth is very, very heavy ........... 6,000,000,000,000,000,000,000,000 Kilograms! Now what this means is that the earth has a very strong gravitational pull.',
              },
              {
                type: 'paragraph',
                text: "Let's try this out for ourselves - don't forget to record your findings in your Log Book!",
              },
              {
                type: 'list',
                listHeadline: 'You will need:',
                listItems: [
                  'Balloon',
                  'Balloon pump',
                  'Metal washers / flat weights',
                  'Tape',
                  'Laminated Bullseye Target',
                ],
              },
              {
                type: 'orderedList',
                listHeadline: 'Instructions:',
                listItems: [
                  'Blow up and tie the balloon.',
                  'Using tape stick one washer to the outside of the balloon at a random location.',
                  'Test fly the balloon by gently giving it an upward pat with an open hand. Watch how the balloon flies and note it down in your Log Book. Why did the balloon fly that way?',
                  'Add another washer and fly it again. What did it do? Can you hit the target with your balloon?',
                  'Invent a sport to play with your crazy balloon. What is the objective of your sport? What are the rules? Record this in your Log Book.',
                ],
              },
            ],
          },
          {
            number: 3,
            name: 'Activity 3',
            description: 'Anti-Gravity Magic Trick',
            completed: false,
            content: [
              { type: 'headline', text: 'Anti-Gravity Magic Trick' },
              {
                type: 'video',
                url: 'https://www.youtube.com/embed/P7hW4WzST5s',
              },
              {
                type: 'paragraph',
                text: 'Unlike space there is no escaping gravity on earth, but we can use a little magic to try and convince people that we can. See if you can convince your crew that you have managed to cheat gravity with this trick.',
              },
              {
                type: 'list',
                listHeadline: 'You will need:',
                listItems: [
                  'Clear string',
                  'Astronaut matchstick',
                  'Paper',
                  'Playing cards',
                  'Tape',
                  'A little bit of magic!',
                ],
              },
              {
                type: 'orderedList',
                listHeadline: 'Instructions:',
                listItems: [
                  'Using clear tape, stick one end of the clear string to a playing card.',
                  'Wind the string 3 times round the playing card the long way and stick it to the same side as before.',
                  'Draw an astronaut onto the paper and stick it to the matchstick.',
                  'Hold the card (with the sticky tape facing down) in your hand between your thumb and fingers. Place the astronaut on top of the card across all three invisible thread strings.',
                  'To make the astronaut float upwards slowly push the edges of the card together so it begins to bend downwards. The card will come away from the match, but the invisible string will hold the match up, giving the illusion the astronaut is floating!',
                  "Keep practising. No astronaut or magician gets something right the first time, it is all about training and practice! When you're ready, try the trick out on one of your crew members.",
                ],
              },
            ],
          }
        ],
        evaluationQuestions: [
          {
            question: 'Tell us how your space day went?',
            answer: '',
          },
          {
            question: 'Do you feel more in control?',
            answer: '',
          },
          {
            question: 'Could you complete all the tasks?',
            answer: '',
          },
        ],
        evaluationShown: false,
        dayComplete: false,
        completionAudioPlayed: false,
      },
      {
        number: 6,
        label: 'Day 6',
        description: 'Canadarm',
        subtitle:
          "Robotic arms are used in space to help with jobs the astronauts can't do. Try building your own Robotic Arm in these experiments.",
        stage: 3,
        extensionComplete: false,
        extensionClicked: false,
        funFactsClicked: false,
        dailyBrief: [
          {
            type: 'profile',
            description: 'Daniel - Space Engineer',
            url: '/assets/img/daniel.webp',
          },
          {
            type: 'audio',
            url: './assets/audio/day6-canadarm.mp3',
          },
        ],
        extension: [
          {
            type: 'external',
            headline: 'A day in the life on the ISS:',
            url: 'https://spacevoyageventures.com/life-on-the-iss-daily-routines-of-astronauts-in-orbit/',
            text: 'A day in the life on the ISS',
          },
          {
            type: 'external',
            headline: 'Living on the ISS:',
            url: 'https://www.youtube.com/watch?v=ouDKD9G9jOE',
            text: 'Living on the ISS',
          },
        ],
        funFacts: [
          {
            type: 'paragraph',
            text: 'Did you know that gravity causes tides in our seas and oceans? This is because gravity makes everything in the universe pull on each other. The Moon pulls on Earth and in turn, makes the water move! As Earth rotates, the water in our seas and oceans will build up on the side closest to the Moon. This is why we have high and low tides.',
          },
          {
            type: 'paragraph',
            text: "On Earth, gravity pulls liquids and solids down to the bottom of our digestive systems, while gases stay up top and forced back up the esophagus as a burp. That can't happen in space. You can't burp in space because the air, food and liquids in your stomach are all floating together like chunky bubbles. If you burp, you throw up into your mouth",
          },
          {
            type: 'paragraph',
            text: "On Earth, farts are typically no big deal — smelly, harmless, and they quickly dissipate. But if you're an astronaut, every fart is a ticking time bomb. The gases in farts are flammable, which can quickly become a problem in a tiny pressurised capsule in the middle of space where your fart gases have nowhere to go!",
          },
        ],
        activities: [
          {
            number: 1,
            name: 'Activity 1',
            description: 'Robotic Arm: Pincer Challenge',
            completed: false,
            content: [
              { type: 'headline', text: 'Robotic Arm: Pincer Challenge' },
              {
                type: 'image',
                url: './assets/img/canadarm2_robotic_arm.webp',
              },
              {
                type: 'paragraph',
                text: 'Canada contributed a huge 17 meter long robotic arm to the International Space Station called ‘Canadarm2’. Astronauts control this arm from within the ISS to transfer cargo, release satellites and help space shuttles to dock, also known as ‘cosmic catches’!',
              },
              {
                type: 'video',
                url: 'https://www.youtube.com/embed/RYqO73qV-UM',
              },
              {
                type: 'list',
                listHeadline: 'You will need:',
                listItems: [
                  '8x lollipop sticks',
                  'Split pins',
                  'Sticky pads / tape',
                  'Cardboard',
                ],
              },
              {
                type: 'orderedList',
                listHeadline: 'Instructions:',
                listItems: [
                  'Use split pin paper fasteners to attach two lollipop sticks in the centre to form a cross.',
                  'Repeat this 2 more times.',
                  'Use split pin paper fasteners to attach the ends of the cross shapes you have just made to form a long chain.',
                  'Attach two sticks at one end as handles.',
                  "Cut two cardboard triangles and fix to the other end with sticky tabs or tape, creating 'fingers' to grab objects.",
                  'Attach tape to the ends of the grabber. This will help your robotic arm grip objects.',
                  'Search around your room for objects to pick up and record in your Log Book.',
                ],
              },
            ],
          },
          {
            number: 2,
            name: 'Activity 2',
            description: 'Build a Bionic Hand',
            completed: false,
            content: [
              { type: 'headline', text: 'Build a Bionic Hand' },
              {
                type: 'video',
                url: 'https://www.youtube.com/embed/YjHNDRPRb8c',
              },
              {
                type: 'list',
                listHeadline: 'You will need:',
                listItems: [
                  'Paper hand template or draw round your own hand',
                  'Paper straws',
                  'Pencil',
                  'Tape',
                  'Scissors',
                  '5x pipe cleaners',
                ],
              },
              {
                type: 'orderedList',
                listHeadline: 'Instructions:',
                listItems: [
                  'Cut out the hand template from your Mission Box.',
                  'Cut the straws into pieces to attach to the fingers. You will need 3 small pieces to fit on each finger plus two for the thumb. You will also need 5 longer pieces to fit on the palm of the hand.',
                  'Thread a pipe cleaner through the straws on each finger.',
                  'Tie a knot at the top of each finger to secure the pipe cleaner in place.',
                  'Use tape to attach the straws to the paper hand.',
                  'Pull the 5 pipe cleaners together at the bottom of the hand and tie together.',
                  'Gently pull on the pipe cleaner and watch your bionic hand move.',
                ],
              },
            ],
          },
          {
            number: 3,
            name: 'Activity 3',
            description: 'Living on the ISS',
            completed: false,
            content: [
              { type: 'headline', text: 'Living on the ISS' },
              {
                type: 'paragraph',
                text: 'Look what we’ve found! A diary entry by Tim Peake from his mission to the ISS in 2016. You’ll find this in your log book, read what a day in the life of an astronaut is really like!',
              },
              {
                type: 'image',
                url: './assets/img/tpdiaryentry.webp',
              },
              {
                type: 'paragraph',
                text: 'Now that you’ve read what it’s like to be an astronaut on the ISS, take some time to think about what your day is like in hospital.',
              },
              {
                type: 'paragraph',
                text: 'How is your day different? Are there any part’s of the day that are similar?',
              }
            ],
          },
        ],
        evaluationQuestions: [
          {
            question: 'Tell us how your space day went?',
            answer: '',
          },
          {
            question: 'Do you feel more in control?',
            answer: '',
          },
          {
            question: 'Could you complete all the tasks?',
            answer: '',
          },
        ],
        evaluationShown: false,
        dayComplete: false,
        completionAudioPlayed: false,
      },
      {
        number: 7,
        label: 'Day 7',
        description: 'Space Walk',
        subtitle:
          'Design your own Spacesuit and Space Helmet in preparation for your first Space Walk.',
        stage: 3,
        extensionComplete: false,
        extensionClicked: false,
        funFactsClicked: false,
        dailyBrief: [
          {
            type: 'profile',
            description: 'Sophie - Science Officer',
            url: '/assets/img/sophie.webp',
          },
          {
            type: 'paragraph',
            text: "Astronauts have special gear called a spacesuit that they use when they must leave the safety of their spacecraft. These spacesuits provide them with air, protect them from the extreme temperatures of space, and protect them from the radiation of the Sun. Sometimes the spacesuits are tethered to the spacecraft, so the astronaut won't float away. Other times the spacesuit ise quipped with small rocket thrusters to allow the astronaut to navigate around the spacecraft.",
          },
          {
            type: 'audio',
            url: './assets/audio/day7-space-day.mp3',
          },
        ],
        extension: [
          {
            type: 'external',
            headline:
              "Don't forget to explore the fun facts and extensions section to learn more about spacesuits",
            url: 'https://easyscienceforkids.com/learn-about-spacesuits-video-for-kids/',
            text: 'Learn about Spacesuits',
          },
          {
            type: 'external',
            headline: '',
            url: 'https://www.nasa.gov/feature/spacewalk-spacesuit-basics',
            text: 'Spacewalk Spacesuit Basics',
          },
          {
            type: 'external',
            headline: '',
            url: 'https://www.nasa.gov/audience/foreducators/spacesuits/home/clickable_suit.html',
            text: 'Clickable Spacesuit - An Interactive Spacesuit Experience | NASA',
          },
          {
            type: 'external',
            headline: '',
            url: 'https://spacekids.co.uk/spacesuits/',
            text: "A children's guide to astronaut spacesuits, ideal research for space projects or homework! (spacekids.co.uk)",
          },
          {
            type: 'external',
            headline: '',
            url: 'http://www.actforlibraries.org/the-exercise-regimen-of-astronauts-in-space/',
            text: 'The Exercise Regimen of Astronauts in Space | Actforlibraries.org',
          },
        ],
        funFacts: [
          {
            type: 'paragraph',
            text: 'Sleep in space has little in common with earthly sleep. In microgravity, astronauts are constantly moving, so to avoid hitting something and getting injured while sleeping, they use sleeping bags that are attached to the wall either vertically (facing the center of the cabin) or horizontally (facing the “ceiling”). The astronaut gets into the bag, fastens the zipper, fixes their head and body with special textile straps, and sleeps. In weightlessness, no one snores, even if they do so very loudly on Earth. So, sleeping on a spaceship may not be very comfortable, but at least you will sleep in silence',
          },
          {
            type: 'paragraph',
            text: 'The spacesuit helmet is completely sealed, so if your face itches, you simply cannot reach it. The only solution is to stick a piece of plaster on the inside of the helmet and rub your face against it.',
          },
          {
            type: 'paragraph',
            text: 'In a spacesuit, you may not only want to scratch something but also sneeze. If you sneeze as you do on Earth, all the glass will be splashed, and the view will be blocked, which may cause the mission to be aborted. To avoid this, the astronauts tilt their heads down and press their chins to their chests. However, sneezes do not happen that often because all astronauts undergo medical examinations before the flight to minimise such risks.',
          },
        ],
        activities: [
          {
            number: 1,
            name: 'Activity 1',
            description: 'Let’s get space walk ready!',
            completed: false,
            content: [
              { type: 'headline', text: 'Let’s get space walk ready!' },
              {
                type: 'question',
                id: "space-walk-question",
                question: 'Why do you think astronauts go on space walks?',
                answerOptions: [
                  { answer: 'To try and catch a star (Astrologer in the making!)', id: '1', correct: false, answerColor: '#767676', answerTextColor: 'white' },
                  { answer: 'To stretch their legs (Sports star in the making!)', id: '2', correct: false, answerColor: '#767676', answerTextColor: 'white' },
                  { answer: 'To get a better view of space (photographer in the making!)', id: '3', correct: false, answerColor: '#767676', answerTextColor: 'white' },
                  { answer: 'To maintain the ISS (Engineer in the making!)', id: '4', correct: true, answerColor: '#767676', answerTextColor: 'white' },
                ]
              },

            ],
          },
          {
            number: 2,
            name: 'Activity 2',
            description: 'Design Your Own Space Suit',
            completed: false,
            content: [
              { type: 'headline', text: 'Design Your Own Space Suit' },
              {
                type: 'image',
                url: './assets/img/illustrations/spacesuit.jpg',
              },
              {
                type: 'paragraph',
                text: "It's time to get creative and design your own spacesuit.",
              },
              {
                type: 'list',
                listHeadline: 'You will need:',
                listItems: [
                  'Spacesuit template from your Mission Box',
                  'Coloured pens / pencils',
                ],
              },
              {
                type: 'orderedList',
                listHeadline: 'Instructions:',
                listItems: [
                  'Customise the spacesuit template from your Mission Box - you can make it as colourful and unique as you like.',
                ],
              },
            ],
          },
          {
            number: 3,
            name: 'Activity 3',
            description: 'Make a Space Helmet',
            completed: false,
            content: [
              { type: 'headline', text: 'Make a Space Helmet' },
              {
                type: 'paragraph',
                text: "Pressure bubble",
              },
              {
                type: 'orderedList',
                listHeadline: 'An astronaut’s helmet not only protects them from flying objects but it also has lots of cool features:',
                listItems: [
                  'Provides oxygen so they can breathe.',
                  'Communication so they can speak to the ISS and the astronaut who is paired up with them for the space walk.',
                  'Gold visor protects from the sun’s rays.',
                  'Protective pressure bubble around their head.',
                  'Foam nose-scratching block in case you get an itchy nose!',
                  'Special shape so you can see where you’re walking better.'
                ],
              },
              {
                type: 'paragraph',
                text: "Remember to add these features in to your helmet!",
              },
              { type: 'image', url: './assets/photos/5E3A6975.webp' },
              {
                type: 'list',
                listHeadline: 'You will need:',
                listItems: [
                  'Cardboard Mission Box',
                  'Stickers',
                  'Coloured pens / paint',
                  'Gold film - cellophane'
                ],
              },
              {
                type: 'orderedList',
                listHeadline: 'Instructions:',
                listItems: [
                  'Fold out your cardboard box',
                  'Cut off the lid of your box.',
                  'Draw a big circle on one side and cut it out so you can see out! Ask a crew member to help you.',
                  'Decorate your helmet as you like - you might want to add stickers as dials.',
                ],
              },
            ],
          },
          {
            number: 4,
            name: 'Activity 4',
            description: 'Virtual Reality Space Walk Experience',
            completed: false,
            pingGroundControl: true,
            content: [
              { type: 'headline', text: 'Virtual Reality Space Walk Experience' },
              {
                type: 'paragraph',
                text: 'Explore the ISS, try a Space Walk!',
              },
              { type: 'image', url: './assets/img/space-walk.jpg' },
              {
                type: 'centerparagraph',
                text: 'Contact Mission Control when you are ready to try a Virtual Reality Space Walk',
              },
            ],
          },
        ],
        evaluationQuestions: [
          {
            question: 'Tell us how your space day went?',
            answer: '',
          },
          {
            question: 'Do you feel more in control?',
            answer: '',
          },
          {
            question: 'Could you complete all the tasks?',
            answer: '',
          },
        ],
        evaluationShown: false,
        dayComplete: false,
        completionAudioPlayed: false,
      },
    ],
  },
  {
    level: 4,
    name: 'Life in space - Star Sailor',
    label: 'Life in space - Star Sailor',
    stageColor: '#8161AC',
    stageTextColor: '#FFFFFF',
    imgUrl: './assets/img/star_sailor.webp',
    description: 'Now it’s time for the next stage of our journey, setting off into space towards Mars!',
    map: {
      size: '13vw',
      maxWidth: '0.5',
      maxHeight: '0.5',
      top: '-5vh',
      left: '42vw',
      connectionX: '0.5',
      connectionY: '0.5',
    },
    completed: false,
    completionAudioPlayed: false,
    badgeShown: false,
    locked: true,
    days: [
      {
        number: 8,
        label: 'Day 8',
        description: 'The Solar System',
        subtitle:
          'The next activities will help you learn the names and order of all the planets in the Solar System.',
        stage: 4,
        extensionComplete: false,
        extensionClicked: false,
        funFactsClicked: false,
        dailyBrief: [
          {
            type: 'profile',
            description: 'Sophie - Science Officer',
            url: '/assets/img/sophie.webp',
          },
          {
            type: 'audio',
            url: './assets/audio/day8-solar-system.mp3',
          },
        ],
        extension: [
          {
            type: 'external',
            headline: 'Explore the solar system in more depth:',
            url: 'https://spaceplace.nasa.gov/menu/solar-system/',
            text: 'Solar System | NASA Space Place - NASA Science for Kids',
          },
          {
            type: 'external',
            headline: '',
            url: 'https://www.silvergames.com/en/t/astronomy',
            text: 'Astronomy Games - Online Educational Space Games (silvergames.com)',
          },
          {
            type: 'external',
            headline: '',
            url: 'https://joshworth.com/dev/pixelspace/pixelspace_solarsystem.html',
            text: 'An accurate scale model of the solar system',
          },
          {
            type: 'external',
            headline: '',
            url: 'https://www.youtube.com/watch?v=8wtWvZczOx4&list=PLbyvawxScNbuVfs56Qm_U0R4Lcy0lMwhs',
            text: 'Paxi on the ISS: A view of Earth',
          },
          {
            type: 'external',
            headline: '',
            url: 'https://www.youtube.com/watch?v=GO5FwsblpT8',
            text: "Carl Sagan's Pale Blue Dot",
          },
        ],
        funFacts: [
          {
            type: 'paragraph',
            text: 'Most of the planets in our solar system rotate in a counter clockwise direction. However, Venus and Uranus actually spin in the opposite direction. One day on Venus lasts 243 Earth days because Venus spins backwards, with its sun rising in the west and setting in the east.',
          },
        ],
        activities: [
          {
            number: 1,
            name: 'Activity 1',
            description: 'Map the Solar System',
            completed: false,
            content: [
              { type: 'headline', text: 'Map the Solar System' },

              { type: 'image', url: './assets/photos/5E3A6985.webp' },
              {
                type: 'paragraph',
                text: 'The Earth is 93 million miles from the sun. Because this distance is so important, scientists have given it a special name: the Astronomical Unit (A.U.). The Earth is 1 A.U. from the sun, whilst Mars is 1.5 A.U. from it. We can make a scale model of the distances between planets, by using CENTIMETRES instead of ASTRONOMICAL UNITS.',
              },
              {
                type: 'paragraph',
                text: 'Mercury - 0.38 A.U. turns into 38cm',
              },
              {
                type: 'paragraph',
                text: 'Venus - 0.72 A.U. turns into 72cm',
              },
              {
                type: 'paragraph',
                text: 'Earth - 1 A.U. turns into 100cm',
              },
              {
                type: 'paragraph',
                text: 'Mars - 1.5 A.U. turns into 150cm',
              },
              {
                type: 'paragraph',
                text: 'Jupiter - 5.2 A.U. turns into 520cm',
              },
              {
                type: 'paragraph',
                text: 'Saturn - 9.5 A.U. turns into 950cm',
              },
              {
                type: 'paragraph',
                text: 'Uranus - 19.2 A.U. turns into 1920cm',
              },
              {
                type: 'paragraph',
                text: 'Neptune - 30.1 A.U. turns into 3010cm',
              },
              {
                type: 'list',
                listHeadline: 'You will need:',
                listItems: [
                  'Planet templates from your Mission Box',
                  'Coloured pens / pencils',
                  'Measuring tape',
                  'Blu Tack',
                  'Scissors - available from your Play Specialist Crew Member',
                ],
              },
              {
                type: 'orderedList',
                listHeadline: 'Instructions:',
                listItems: [
                  'Colour in all the planets and cut them out.',
                  'Blu tack the sun to one corner of your room.',
                  'Use the scale model A.U. to cm to measure and mark out the distances between the planets.',
                  'Use your tape measure to work out where to place the planets around your room.',
                  'Write your own rhyme to help you remember the planets!'
                ],
              },
              {
                type: 'paragraph',
                text: 'My',
              },
              {
                type: 'paragraph',
                text: 'Very',
              },
              {
                type: 'paragraph',
                text: 'Evil',
              },
              {
                type: 'paragraph',
                text: 'Monster',
              },
              {
                type: 'paragraph',
                text: 'Just',
              },
              {
                type: 'paragraph',
                text: 'Sicked/Slurped',
              },
              {
                type: 'paragraph',
                text: 'Up',
              },
              {
                type: 'paragraph',
                text: 'Noodles',
              },

            ],
          },
          {
            number: 2,
            name: 'Activity 2',
            description: 'Solar System Model',
            completed: false,
            content: [
              { type: 'headline', text: 'Solar System Model' },
              { type: 'image', url: './assets/photos/5E3A6979.webp' },
              {
                type: 'list',
                listHeadline: 'You will need:',
                listItems: ['Solar System Model Kit', 'Paints', 'Paintbrushes'],
              },
              {
                type: 'orderedList',
                listHeadline: 'Instructions:',
                listItems: [
                  'Paint each of the planets in your Solar System Model Kit.',
                  'Assemble the planets to create your own model of the Solar System',
                  'Can you remember the order of the planets?',
                ],
              },
            ],
          },
          {
            number: 3,
            name: 'Activity 3',
            description: 'VR Experience of the Solar System',
            completed: false,
            pingGroundControl: true,
            content: [
              {
                type: 'headline',
                text: 'VR Experience of the Solar System',
              },
              {
                type: 'image',
                url: './assets/img/explore-solar-system.jpg',
              },
              {
                type: 'centerparagraph',
                text: 'Contact Mission Control when you are ready to try a VR experience of the Solar System.',
              },
            ],
          },
        ],
        evaluationQuestions: [
          {
            question: 'Tell us how your space day went?',
            answer: '',
          },
          {
            question: 'Do you feel more in control?',
            answer: '',
          },
          {
            question: 'Could you complete all the tasks?',
            answer: '',
          },
        ],
        evaluationShown: false,
        dayComplete: false,
        completionAudioPlayed: false,
      },
      {
        number: 9,
        label: 'Day 9',
        description: 'Stars',
        subtitle:
          'A constellation is a group of visible stars that forms a pattern or outline, typically of an animal, mythological subject, or object. You will find some constellations printed on your Life in Space Mission Box. Bring them to life in the next experiment.',
        stage: 4,
        extensionComplete: false,
        extensionClicked: false,
        funFactsClicked: false,
        dailyBrief: [
          {
            type: 'profile',
            description: 'Daniel - Space Engineer',
            url: '/assets/img/daniel.webp',
          },
          {
            type: 'audio',
            url: './assets/audio/day9-stars.mp3',
          },
        ],
        extension: [
          {
            type: 'external',
            headline:
              'Make a Pinwheel Galaxy using the template in your Mission Box',
            url: 'https://spaceplace.nasa.gov/pinwheel-galaxy/en/',
            text: 'Make a Pinwheel Galaxy | NASA Space Place - NASA Science for Kids',
          },
          {
            type: 'external',
            headline: '',
            url: 'https://spaceplace.nasa.gov/menu/sun/',
            text: 'Sun | NASA Space Place - NASA Science for Kids',
          },
          {
            type: 'external',
            headline:
              "Check out these galleries of images captured by NASA's Hubble Space Telescope and Spitzer Space Telescope:",
            url: 'https://spaceplace.nasa.gov/gallery-space/en/',
            text: 'Gallery of NASA Universe Images | NASA Space Place - NASA Science for Kids',
          },
          {
            type: 'external',
            headline: '',
            url: 'https://www.nasa.gov/content/goddard/what-did-hubble-see-on-your-birthday',
            text: 'What Did Hubble See on Your Birthday?',
          },
          {
            type: 'external',
            headline: '',
            url: 'https://www.astronomyforbeginners.com/astronomy/amazing-astronomy-facts/',
            text: '25 MIND BLOWING ASTRONOMY FACTS',
          },
        ],
        funFacts: [
          {
            type: 'paragraph',
            text: 'The sheer size of space makes it impossible to accurately predict just how many stars we have. Right now, scientists and astronomers use the number of stars only within our galaxy, The Milky Way, to estimate. That number is between 200 - 400 billion stars and there are estimated to be billions of galaxies so the stars in space really are completely uncountable.',
          },
          {
            type: 'paragraph',
            text: 'There are people on Earth who have studied the sky for centuries, they are called astronomers. Often when Astronomers see a cluster of stars that look like something from Earth they will name it so that other people can recognise it too. We call these constellations - one of the most famous constellations we can see from earth is called the big dipper.',
          },
          {
            type: 'external',
            headline: '',
            url: 'https://space-facts.com/the-sun/',
            text: 'Sun Facts - Interesting Facts about the Sun (space-facts.com)',
          },
          {
            type: 'paragraph',
            text: 'One million Earths could fit inside the Sun.',
          },
          {
            type: 'paragraph',
            text: 'The light we see on Earth is a form of energy from the Sun. It travels through space and takes just over eight minutes to reach us. What could you do in eight minutes?',
          },
        ],
        activities: [
          {
            number: 1,
            name: 'Activity 1',
            description: 'Constellation Projector',
            completed: false,
            content: [
              { type: 'headline', text: 'Constellation Projector' },
              {
                type: 'video',
                url: 'https://www.youtube.com/embed/7BQlcXsX5k4',
              },
              {
                type: 'list',
                listHeadline: 'You will need:',
                listItems: [
                  'Your Life in Space Mission Box',
                  'A sharp pencil',
                  'A torch or your phone torch - contact Mission Control if you require a torch',
                ],
              },
              {
                type: 'orderedList',
                listHeadline: 'Instructions:',
                listItems: [
                  'Use your pencil to poke holes through the star images on your box.',
                  'Put a torch into your box, making sure it is facing upwards, and close the lid. The light will shine through these holes.',
                  'You have made a star projector! You can use this to project star constellations onto surfaces in dark rooms.',
                ],
              },
            ],
          },
          {
            number: 2,
            name: 'Activity 2',
            description: 'Constellation Sunprints',
            completed: false,
            content: [
              { type: 'headline', text: 'Constellation Sunprints' },
              { type: 'image', url: './assets/photos/5E3A6974.webp' },
              {
                type: 'paragraph',
                text: "Remember how we said the Sun was the only star in the Solar System? Well, the Sun has incredible powers. Let's try this sunprint activity and report back on just how magical the sun is.",
              },
              {
                type: 'list',
                listHeadline: 'You will need:',
                listItems: [
                  'Cyanotype paper',
                  'Star stickers',
                  'Washi tape',
                  'Tray',
                ],
              },
              {
                type: 'orderedList',
                listHeadline: 'Instructions:',
                listItems: [
                  'Place a sheet of cyanotype paper on your tray.',
                  'Make a constellation shape by sticking stars onto the paper and connecting them using the washi tape. This can be based on a real constellation or you can make up your own.',
                  'Move the tray so that it is somewhere safe that is next to a window. Leave until the paper turns white / pale blue.',
                  'Remove the stars and tape and run the paper under water. You should be left with a constellation print.',
                ],
              },
            ],
          },
          {
            number: 3,
            name: 'Activity 3',
            description: 'Constellation Drawings',
            completed: false,
            content: [
              { type: 'headline', text: 'Constellation Drawings' },
              {
                type: 'image',
                url: './assets/img/illustrations/constellationdrawing.jpg',
              },
              {
                type: 'audio',
                url: './assets/audio/day9-stars2.mp3',
              },
              {
                type: 'paragraph',
                text: 'Perhaps you could have a go at creating your own constellation?',
              },
              {
                type: 'list',
                listHeadline: 'You will need:',
                listItems: ['Paper', 'Pencil', 'Ruler', 'Star stickers'],
              },
              {
                type: 'orderedList',
                listHeadline: 'Instructions:',
                listItems: [
                  'Create an outline of something personal to you - you might want to draw yourself, a favourite animal or object.',
                  'Add star stickers around the outline.',
                  'Connect the stars using your ruler.',
                  'You have created your own constellation. Can you give it a name?',
                ],
              },
            ],
          },
        ],
        evaluationQuestions: [
          {
            question: 'Tell us how your space day went?',
            answer: '',
          },
          {
            question: 'Do you feel more in control?',
            answer: '',
          },
          {
            question: 'Could you complete all the tasks?',
            answer: '',
          },
        ],
        evaluationShown: false,
        dayComplete: false,
        completionAudioPlayed: false,
      },
      {
        number: 10,
        label: 'Day 10',
        description: 'Electromagnetic Spectrum',
        subtitle:
          'The electromagnetic spectrum is the name for the different forms of light that are given off by objects in the Universe. Radio waves, microwaves, infrared radiation, visible light, ultraviolet radiation, X-rays and gamma rays are all types of light, but with different energies! You can explore visible light in the next experiments.',
        stage: 4,
        extensionComplete: false,
        extensionClicked: false,
        funFactsClicked: false,
        dailyBrief: [
          {
            type: 'video',
            url: 'https://www.youtube.com/embed/9-4qpxxF6xw',
          },
        ],
        extension: [
          {
            type: 'external',
            headline: 'Explore more about visible light:',
            url: 'https://science.nasa.gov/ems/09_visiblelight',
            text: 'Visible Light | Science Mission Directorate (nasa.gov)',
          },
          {
            type: 'external',
            headline: 'Mixing light',
            url: 'https://www.sciencebuddies.org/stem-activities/colored-shadows?from=Blog',
            text: 'Coloured Shadows | STEM Activity (sciencebuddies.org)',
          },
          {
            type: 'external',
            headline: '',
            url: 'https://www.childrensmuseum.org/blog/saturday-science-make-a-rainbow',
            text: 'Can you make a rainbow with a mirror?',
          },
          {
            type: 'external',
            headline: 'Make your own colour spinner:',
            url: 'https://littlebinsforlittlehands.com/color-wheel-spinner/',
            text: 'Color Wheel Spinner For Kids STEM - Little Bins for Little Hands',
          },
          {
            type: 'external',
            headline: 'Make Rainbow Paper',
            url: 'https://curiodyssey.org/learn-explore/science-experiments-for-kids/how-to-make-rainbow-paper/',
            text: 'How to make rainbow paper',
          },
        ],
        funFacts: [
          {
            type: 'external',
            headline: '',
            url: 'https://www.natgeokids.com/uk/discover/science/general-science/light-and-lenses-10-facts/',
            text: 'Light and lenses: 10 facts - National Geographic Kids (natgeokids.com)',
          },
          {
            type: 'paragraph',
            text: 'Just like mixing paint to create a new colour, you can mix light and create new colours, too. But while you know that primary colours of paint are red, yellow and blue, did you know that the primary colours of light are red, green and blue?',
          },
          {
            type: 'paragraph',
            text: 'A red ladybird looks red because its body reflects red light while absorbing green and blue light. Special cells in our eyes see this light and tell our brains to understand it as red.',
          },
          {
            type: 'paragraph',
            text: "Not everyone sees colour in the same way, even animals don't always see colours that we do. Some insects like bees can see ultra violet colours which we can't. You may have heard that dogs can only see black and white. It turns out they can see colour, just not as much as humans can.",
          },
        ],
        activities: [
          {
            number: 1,
            name: 'Activity 1',
            description: 'Visible Light: Colour Mixing',
            completed: false,
            content: [
              { type: 'headline', text: 'Visible Light: Colour Mixing' },
              {
                type: 'video',
                url: 'https://www.youtube.com/embed/c4ijwiPRTiU',
              },
              {
                type: 'list',
                listHeadline: 'You will need:',
                listItems: [
                  '3x torches - Contact Mission Control to receive your torches',
                  'Elastic bands',
                  'Coloured cellophane',
                ],
              },
              {
                type: 'orderedList',
                listHeadline: 'Instructions:',
                listItems: [
                  'Contact Mission Control to receive your torches.',
                  'Use the elastic bands to secure 1 coloured sheet to each of the torches. You should have 1 red torch, 1 blue torch and 1 green torch.',
                  'Turn on the torches and see what happens when you mix the colours of light.',
                  'Mix the following combinations of light and colour and record your findings in your Log Book.',
                ],
              },
              {
                type: 'list',
                listHeadline: '',
                listItems: [
                  'Red & Green',
                  'Red & Blue',
                  'Green & Blue',
                  'Red, Green & Blue',
                ],
              },
              {
                type: 'paragraph',
                text: "White light is actually made up of all of these colours! Light can be broken down into primary colours — just like paint. However, the primary colours of light are red, blue and green — not red, blue and yellow as with paint. The secondary colours of light are magenta, cyan and yellow — not purple, green and orange. Sunlight appears yellow because the Earth's atmosphere absorbs more blue light than any other colour. An Edinburgh-born physicist, James Clerk Maxwell, was the first person to realise that colours of light could be mixed to create other colours. There is a statue of him on George Street, holding his colour wheel. James Clerk Maxwell's discovery led to colour photography.",
              },
            ],
          },
          {
            number: 2,
            name: 'Activity 2',
            description: 'Light Refraction',
            completed: false,
            content: [
              { type: 'headline', text: 'Light Refraction' },
              {
                type: 'video',
                url: 'https://www.youtube.com/embed/HTcEeEUNWwQ',
              },
              {
                type: 'paragraph',
                text: 'Light is a form of energy that travels in waves. Light passes easily through air but finds other materials, such as glass, harder to move through. When a beam of light reaches the surface of a new material, it appears to bend. This is known as refraction and is caused by the increased difficulty to travel through different materials. Try it out with this experiment.',
              },
              {
                type: 'list',
                listHeadline: 'You will need:',
                listItems: [
                  'Torch',
                  'Clear plastic cup',
                  'Sheet of white paper',
                  'Water',
                ],
              },
              {
                type: 'orderedList',
                listHeadline: 'Instructions:',
                listItems: [
                  'Fill the plastic cup with water all the way to the top and try not to spill any of the water.',
                  'Shine the torch through the cup. The light should shine through the top of the cup and directly hit the surface of the water.',
                  'The light should shine out of the side of the cup, and a rainbow will be visible. The water causes the light to refract much in the same way that a prism does.',
                  'Place the sheet of white paper under the glass to help see the rainbow.',
                  'Try putting the cup near the edge of the table and creating a rainbow on the floor.',
                ],
              },
            ],
          },
          {
            number: 3,
            name: 'Activity 3',
            description: 'Build a Spectroscope',
            completed: false,
            content: [
              { type: 'headline', text: 'Build a Spectroscope' },
              {
                type: 'video',
                url: 'https://player.vimeo.com/video/1024708230',
              },
              {
                type: 'paragraph',
                text: 'Remember we spoke about astronomers before? Well astronomers use the electromagnetic light spectrum to study stars and planets; they call this Spectroscopy.',
              },
              {
                type: 'paragraph',
                text: 'A spectroscope is a tool which scientists use to help determine what stars are made of. Spectroscopes split up the different colours of light from a star. Each chemical element has its own emission spectrum. The emission spectrum of an element shows which colours of light the element emits when burned. When using a spectroscope to look at stars, astronomers can use the emission spectrum of the star to identify which chemical elements the star is made from. This gives astronomers an indication of how old that star is and therefore how long is has left before it supernovas.',
              },
              {
                type: 'external',
                headline:
                  'Some examples of emission spectra for chemical elements are shown here:',
                url: 'https://www.bbc.co.uk/bitesize/guides/z3pf39q/revision/1',
                text: 'Line emission and absorption spectra - The Universe - WJEC - GCSE Physics (Single Science) Revision - WJEC - BBC Bitesize',
              },
              {
                type: 'list',
                listHeadline: 'You will need:',
                listItems: [
                  'Spectrometer Stencil',
                  '2x sheets black card',
                  '1 small piece of diffraction grating plastic',
                  '2x elastic bands',
                  'Tape',
                  'Scissors',
                ],
              },
              {
                type: 'orderedList',
                listHeadline: 'Instructions:',
                listItems: [
                  'Cut out the Spectrometer Stencils and draw round them onto one sheet of black card.',
                  'Cut out the black stencils, being sure to cut along all the lines of the circles.',
                  'Carefully cut out the small rectangle in the centre of one circle, and the thin slit on the other - the cleaner and thinner the narrow slit the better the resultant spectra will be.',
                  'Carefully tape the small piece of diffraction grating plastic to cover the rectangular hole.',
                  'Roll the second sheet of card lengthwise to make a tube and tape along the edge.',
                  'Use the elastic bands to secure the 2 black circles to the ends of the tube.',
                  'Look through the large window and point the spectroscope toward a light source. The slit at the other end should be vertical. Never look at the sun directly.',
                  'Rotate the ends of the spectroscope until the narrow-slit end is vertical and the light is being refracted on the left and right side of the spectroscope tube.',
                  'The lines you can see are directly related to the gas inside the lights. If you see a red-green-blue continuous spectrum - your lights are probably LEDs!. The light from the sun gives us a continuous spectrum. If you have fluorescent lights the spectroscopes will show you a broken emission line spectrum.',
                ],
              },
            ],
          },
        ],
        evaluationQuestions: [
          {
            question: 'Tell us how your space day went?',
            answer: '',
          },
          {
            question: 'Do you feel more in control?',
            answer: '',
          },
          {
            question: 'Could you complete all the tasks?',
            answer: '',
          },
        ],
        evaluationShown: false,
        dayComplete: false,
        completionAudioPlayed: false,
      },
    ],
  },
  {
    level: 5,
    name: 'Life in space - Mars Landing',
    label: 'Life in space - Mars Landing',
    stageColor: '#F19C79',
    stageTextColor: '#0B023D',
    imgUrl: './assets/img/mars_landing.webp',
    description: 'The fourth stage of the academy.',
    map: {
      size: '13vw',
      maxWidth: '0.5',
      maxHeight: '0.5',
      top: '2vh',
      left: '62vw',
      connectionX: '0.5',
      connectionY: '0.5',
    },
    completed: false,
    completionAudioPlayed: false,
    badgeShown: false,
    locked: true,
    days: [
      {
        number: 11,
        label: 'Day 11',
        description: 'Energy: Food & Power',
        subtitle:
          "Energy comes from different sources in space including the sun, batteries and atoms. Let's explore ways we can create energy.",
        stage: 5,
        extensionComplete: false,
        extensionClicked: false,
        funFactsClicked: false,
        dailyBrief: [
          {
            type: 'profile',
            description: 'Daniel - Space Engineer',
            url: '/assets/img/daniel.webp',
          },
          {
            type: 'audio',
            url: './assets/audio/day11-energy-power-food.mp3',
          },
        ],
        extension: [
          {
            type: 'external',
            headline:
              'Plan a 5-day flight menu and design a food tray that can be used in space.',
            url: 'https://www.nasa.gov/pdf/143163main_Space.Food.and.Nutrition.pdf',
            text: 'Space food and nutrition',
          },
          {
            type: 'external',
            headline: '',
            url: 'https://www.discovermagazine.com/the-sciences/how-do-scientists-build-the-best-diet-for-astronauts',
            text: 'How Do Scientists Build the Best Diet for Astronauts?',
          },
          {
            type: 'external',
            headline: '',
            url: 'https://apollo11space.com/what-do-astronauts-eat/',
            text: 'What Do Astronauts Eat? - Apollo11Space',
          },
          {
            type: 'external',
            headline: 'Learn more about what powers a spacecraft:',
            url: 'https://spaceplace.nasa.gov/what-powers-a-spacecraft/en/',
            text: 'What Powers a Spacecraft?',
          },
          {
            type: 'external',
            headline:
              'These videos show some of the challenges of eating in space:',
            url: 'https://www.youtube.com/watch?v=AGR3FiEkBwA',
            text: 'Eat Like an Astronaut - YouTube',
          },
          {
            type: 'external',
            headline: '',
            url: 'https://www.youtube.com/watch?v=_4aWoZPEd2w&list=PLiuUQ9asub3TmweK0ARjo4jLfvnVA4uP8',
            text: 'Eating on the Space Station - Youtube',
          },
        ],
        funFacts: [
          {
            type: 'paragraph',
            text: "Solar power is energy from the Sun. Spacecraft that orbit Earth, called satellites, are close enough to the Sun that they can often use solar power. These spacecraft have solar panels which convert the Sun's energy into electricity that powers the spacecraft.",
          },
          {
            type: 'paragraph',
            text: 'The electricity from the solar panels charges a battery in the spacecraft. These batteries can power the spacecraft even when it moves out of direct sunlight.',
          },
          {
            type: 'paragraph',
            text: "Solar energy has also been used to power spacecraft on Mars. NASA's Mars Exploration Rovers, Spirit and Opportunity, and Mars Phoenix lander all used power from solar panels and so does the InSight lander.",
          },
        ],
        activities: [
          {
            number: 1,
            name: 'Activity 1',
            description: 'Fruit Battery',
            completed: false,
            content: [
              { type: 'headline', text: 'Fruit Battery' },
              { type: 'image', url: './assets/photos/5E3A7004.webp' },
              {
                type: 'list',
                listHeadline: 'You will need:',
                listItems: [
                  'Lemon Clock Kit',
                  'Lemon or other fruit / vegetable - contact Mission Control to request this',
                ],
              },
              {
                type: 'orderedList',
                listHeadline: 'Instructions:',
                listItems: [
                  'Contact Mission Control to bring you a lemon or other fruit / vegetable. Ask them to cut it in half.',
                  'Connect the red wire on the LCD clock to a copper plate.',
                  'Connect the black wire to a zinc plate.',
                  'Take the other copper plate and zinc plate and connect them to the white wire using tape.',
                  'Insert the plates into the lemon halves to activate the LCD clock. You will see your clock begin to blink.',
                  'You can set the clock by pressing the A button and adjusting the date and time.',
                  'Experiment with other fruits / vegetables and compare results.',
                ],
              },
              {
                type: 'paragraph',
                text: 'The copper plates act like the positive electrodes of a battery. They are plated with a metal which is less reactive than zinc. When the plates are inserted into the lemon, a chemical reaction occurs. Electrons (extremely small particles with negative charge) move from the zinc plates to the copper plates to form a current, thus activating the LCD clock. The lemon juice helps conduct electricity. You can replace the lemon with a potato, grapefruit, use soft drinks and see what effect they have.',
              },
            ],
          },
          {
            number: 2,
            name: 'Activity 2',
            description: 'Solar Energy',
            completed: false,
            content: [
              { type: 'headline', text: 'Solar Energy' },
              {
                type: 'video',
                url: 'https://player.vimeo.com/video/1024708555'
              },
              {
                type: 'paragraph',
                text: 'Create a Solar Fan'
              },
              {
                type: 'paragraph',
                text: 'You will need – Solar Fan kit.'
              },
              {
                type: 'paragraph',
                text: 'Following the instructions in your box, or the instruction video create your own solar fan for your windowsill'
              },
              {
                type: 'paragraph',
                text: "A solar fan operates by converting sunlight into electrical energy through photovoltaic (PV) cells in a solar panel. These PV cells absorb sunlight and generate direct current (DC) electricity, which powers the fan's motor, causing the blades to rotate and circulate air. This process allows the fan to run independently of the electrical grid, making it an energy-efficient and eco-friendly cooling solution."
              }
            ],
          },
        ],
        evaluationQuestions: [
          {
            question: 'Tell us how your space day went?',
            answer: '',
          },
          {
            question: 'Do you feel more in control?',
            answer: '',
          },
          {
            question: 'Could you complete all the tasks?',
            answer: '',
          },
        ],
        evaluationShown: false,
        dayComplete: false,
        completionAudioPlayed: false,
      },
      {
        number: 12,
        label: 'Day 12',
        description: 'Looking Back: Looking Out',
        subtitle:
          'How does Earth look from up there in space? Can you make out any features like a river, island, desert, or city?',
        stage: 5,
        extensionComplete: false,
        extensionClicked: false,
        funFactsClicked: false,
        dailyBrief: [
          {
            type: 'profile',
            description: 'Daniel - Space Engineer',
            url: '/assets/img/daniel.webp',
          },
          {
            type: 'audio',
            url: './assets/audio/day12-looking-back-looking-out2.mp3',
          },
        ],
        extension: [
          {
            type: 'external',
            headline: 'Earth Observation Puzzlers',
            url: 'https://earthobservatory.nasa.gov/blogs/earthmatters/category/eos-satellite-puzzler/',
            text: 'Satellite Puzzler',
          },
          {
            type: 'external',
            headline: 'Images of Earth from Space',
            url: 'https://earthobservatory.nasa.gov/',
            text: 'Earth Observatory',
          },
          {
            type: 'external',
            headline: '',
            url: 'https://www.nasa.gov/image-feature/viewing-earth-from-the-space-station',
            text: 'Viewing Earth from the Space Station | NASA',
          },
          {
            type: 'external',
            headline: 'Explore Earth with Art Quiz',
            url: 'https://www.jpl.nasa.gov/edu/learn/project/explore-earth-and-space-with-art',
            text: 'Explore Earth and Space with Art',
          },
          {
            type: 'external',
            headline: '',
            url: 'https://spaceplace.nasa.gov/topomap-earth/en/',
            text: 'Building a 3D Map of Earth from Space! | NASA Space Place - NASA Science for Kids',
          },
        ],
        funFacts: [
          {
            type: 'paragraph',
            text: "The International Space Station offers ways to observe Earth's ecosystems with both hands-on and automated equipment. Since the station became operational in November 2000, crew members have produced hundreds of thousands of images of the land, oceans, and atmosphere of Earth, and even of the Moon. Astronauts can record phenomena such as storms on Earth in real time, observe and collect images of expected and unexpected natural events such as volcanic eruptions while they unfold, and even provide input to ground personnel for programming automated Earth-sensing systems.",
          },
        ],
        activities: [
          {
            number: 1,
            name: 'Activity 1',
            description: 'Observations of Earth',
            completed: false,
            content: [
              { type: 'headline', text: 'Observations of Earth' },
              {
                type: 'video',
                url: 'https://dlmultimedia.esa.int/download/public/videos/2017/09/030/1709_030_AR_EN.mp4',
              },
              {
                type: 'slideshow',
                slides: [
                  './assets/photos/obs.webp',
                  './assets/photos/obs1.webp',
                  './assets/photos/obs2.webp',
                ],
              },

              {
                type: 'paragraph',
                text: "Earth observations can be used to predict the weather, monitor greenhouse gas emissions, and provide us with a different perspective of the world we live in. However, Earth observations can also give us useful insight into other planets. If we can identify certain domains from above the Earth's atmosphere, then this may give us clues as to what domains lie on other planets. Have a go at spotting these features for yourself.",
              },
              {
                type: 'list',
                listHeadline: 'You will need:',
                listItems: [
                  'Photos of the Earth from different perspectives',
                  'Table from your Log Book',
                ],
              },
              {
                type: 'orderedList',
                listHeadline: 'Instructions:',
                listItems: [
                  'Examine the images. See if you can match the photo taken on Earth to the photo taken by an astronaut on the International Space Station, and a photo taken from a satellite in space.',
                  'Record your answers in the table provided in your Log Book.',
                ],
              },
            ],
          },
          {
            number: 2,
            name: 'Activity 2',
            description: 'Scotland from Above',
            completed: false,
            content: [
              { type: 'headline', text: 'Scotland from Above' },
              { type: 'image', url: './assets/img/scotlandfromabove.webp' },
              {
                type: 'audio',
                url: './assets/audio/day12-looking-back-looking-out.mp3',
              },
              {
                type: 'list',
                listHeadline: 'You will need:',
                listItems: ['Pencils', 'Paper'],
              },
              {
                type: 'orderedList',
                listHeadline: 'Instructions:',
                listItems: [
                  'Imagine how Scotland looks from space. What features do you think would be visible?',
                  'Make a drawing of your view from space.',
                ],
              },
            ],
          },
          {
            number: 3,
            name: 'Activity 3',
            description: 'Postcard to Earth',
            completed: false,
            content: [
              { type: 'headline', text: 'Postcard to Earth' },
              { type: 'image', url: './assets/photos/postcard.png' },
              {
                type: 'list',
                listHeadline: 'You will need:',
                listItems: [
                  'Pen / pencils',
                  'Blank postcards',
                  'Stickers / stamps',
                ],
              },
              {
                type: 'orderedList',
                listHeadline: 'Instructions:',
                listItems: [
                  'Design a postcard to send back home to Earth.',
                  "Write a message on the postcard telling people back on Earth what you've been doing so far. You might want to focus on your favourite and least favourite parts of your journey.",
                ],
              },
            ],
          },
        ],
        evaluationQuestions: [
          {
            question: 'Tell us how your space day went?',
            answer: '',
          },
          {
            question: 'Do you feel more in control?',
            answer: '',
          },
          {
            question: 'Could you complete all the tasks?',
            answer: '',
          },
        ],
        evaluationShown: false,
        dayComplete: false,
        completionAudioPlayed: false,
      },
      {
        number: 13,
        label: 'Day 13',
        description: 'Mars Sighting',
        subtitle:
          "Can you see that red, dusty planet…that's Mars! Let's prepare to land and explore the surface of Mars.",
        stage: 5,
        extensionComplete: false,
        extensionClicked: false,
        funFactsClicked: false,
        dailyBrief: [
          {
            type: 'profile',
            description: 'Daniel - Space Engineer',
            url: '/assets/img/daniel.webp',
          },
          {
            type: 'audio',
            url: './assets/audio/day13-mars-sighting.mp3',
          },
        ],
        extension: [
          {
            type: 'paragraph',
            text: 'There is lots to learn about Mars on the extensions and fun facts page.',
          },
          {
            type: 'external',
            headline: 'Watch a Mars Landing Video:',
            url: 'https://mars.nasa.gov/mars2020/timeline/landing/',
            text: 'https://mars.nasa.gov/mars2020/timeline/landing/',
          },
          {
            type: 'external',
            headline: 'Make a paper helicopter to land on Mars:',
            url: 'https://www.jpl.nasa.gov/edu/learn/project/make-a-paper-mars-helicopter/',
            text: 'Make a Paper Mars Helicopter',
          },
          {
            type: 'external',
            headline: 'Explore Mars in a Minute',
            url: 'https://www.jpl.nasa.gov/edu/teach/activity/mars-in-a-minute/',
            text: 'Mars in a Minute',
          },
          {
            type: 'external',
            headline: 'See Mars in the Night Sky',
            url: 'https://mars.nasa.gov/all-about-mars/night-sky/close-approach/',
            text: 'Mars Close Approach to Earth',
          },
          {
            type: 'external',
            headline: 'Watch this video:',
            url: 'https://www.bing.com/videos/search?q=exo+mars+parachute&docid=608006136411346730&mid=F204A2874799B17512E5F204A2874799B17512E5&view=detail&FORM=VIRE',
            text: 'Watch on ExoMars high-altitude parachute test in slow-mo - Bing video',
          },
          {
            type: 'external',
            headline: 'Comparing Earth and Mars',
            url: 'https://www.palebluemarbles.com/facts-about-mars/',
            text: '10 Facts about Mars (with 6 activities for curious kids) - Pale Blue Marbles',
          },
        ],
        funFacts: [
          {
            type: 'paragraph',
            text: 'Mars, the red planet, was named after this god of war. According to Roman myth, Mars rode on a chariot pulled by two horses named Phobos and Deimos (meaning fear and panic). The two small moons of Mars are named after these two mythical horses.',
          },
          {
            type: 'paragraph',
            text: 'Lower gravity on Mars means you would bounce twice as high than on Earth. A year on Mars is 687 days.',
          },
          {
            type: 'paragraph',
            text: 'Mars is visible from earth with the naked eye.',
          },
          {
            type: 'paragraph',
            text: 'Mars takes almost the same time as Earth to rotate once on its axis, making a day on Mars only 40 minutes longer than a day on Earth.',
          },
          {
            type: 'paragraph',
            text: 'A solar day is the time it takes for the Sun to return to the same position in the sky. This is slightly longer than the sidereal day, which is one rotation (since the planet has also moved relative to the Sun).',
          },
          {
            type: 'paragraph',
            text: 'Mars is about half the size of Earth, by diameter. By volume, you would need 6.6 planets the size of Mars to fill the Earth.',
          },
        ],
        activities: [
          {
            number: 1,
            name: 'Activity 1',
            description: 'Hidden Objects Poster',
            completed: false,
            content: [
              { type: 'headline', text: 'Hidden Objects Poster' },
              {
                type: 'image',
                url: './assets/img/illustrations/hiddenitemsposter.webp',
              },
              { type: 'paragraph', text: "It's time to explore Mars." },
              {
                type: 'list',
                listHeadline: 'You will need:',
                listItems: ['Mars Poster from your Mission Box'],
              },
              {
                type: 'orderedList',
                listHeadline: 'Instructions:',
                listItems: [
                  'Can you find all the hidden objects on the poster?',
                  'What features of Mars do you notice? Write any comments in your Log Book.',
                ],
              },
            ],
          },
          {
            number: 2,
            name: 'Activity 2',
            description: 'Flag Design',
            completed: false,
            content: [
              { type: 'headline', text: 'Flag Design' },
              {
                type: 'image',
                url: './assets/img/illustrations/flagoutline.jpg',
              },
              {
                type: 'paragraph',
                text: "It's traditional to place a flag on newly-explored land. Now that you've arrived on Mars, design the flag that you want to leave.",
              },
              {
                type: 'list',
                listHeadline: 'You will need:',
                listItems: [
                  'Flag template',
                  'Coloured pens / pencils',
                  'Stickers',
                ],
              },
              {
                type: 'orderedList',
                listHeadline: 'Instructions:',
                listItems: [
                  'Make a colourful design of your choice. You might want to draw some of your favourite things or use your favourite colours.',
                  'Cut out your flag and display it in your room for your crew to see.',
                ],
              },
            ],
          },
          {
            number: 3,
            name: 'Activity 3',
            description: 'VR Mars Landing Experience',
            completed: false,
            pingGroundControl: true,
            content: [
              { type: 'headline', text: 'VR Mars Landing Experience' },
              { type: 'image', url: './assets/img/mars-landing.jpg' },
              {
                type: 'centerparagraph',
                text: 'Contact Mission Control when you are ready to land on Mars with the VR.',
              },
            ],
          },
        ],
        evaluationQuestions: [
          {
            question: 'Tell us how your space day went?',
            answer: '',
          },
          {
            question: 'Do you feel more in control?',
            answer: '',
          },
          {
            question: 'Could you complete all the tasks?',
            answer: '',
          },
        ],
        evaluationShown: false,
        dayComplete: false,
        completionAudioPlayed: false,
      },
    ],
  },
  {
    level: 6,
    name: 'Mars Rover',
    label: 'Mars Rover',
    stageColor: '#EDBF00',
    stageTextColor: '#0B023D',
    imgUrl: './assets/img/mars_rover.webp',
    description: 'The fifth stage of the academy.',
    map: {
      size: '12vw',
      maxWidth: '0.5',
      maxHeight: '0.5',
      top: '28vw',
      left: '62vw',
      connectionX: '0.5',
      connectionY: '0.5',
    },
    completed: false,
    completionAudioPlayed: false,
    badgeShown: false,
    locked: true,
    days: [
      {
        number: 14,
        label: 'Day 14',
        description: 'Build a Base',
        subtitle:
          "To survive on Mars, you will need a base to provide protection and shelter from the harsh conditions. Mars is a very cold, bleak, dry and dusty planet with high winds. The air is very thin making it difficult for human's to breath, and high radiation levels mean you will always need protection. Liquid water cannot exist so the only water is in the form of polar ice caps.",
        stage: 6,
        extensionComplete: false,
        extensionClicked: false,
        funFactsClicked: false,
        dailyBrief: [
          {
            type: 'video',
            url: 'https://www.youtube.com/embed/Xpq1fWRAtgA',
          },
        ],
        extension: [
          {
            type: 'external',
            headline:
              'Check out the extensions icon to learn more about surviving and thriving on Mars',
            url: 'https://www.nasa.gov/sites/default/files/atoms/files/surviving-on-mars-education-explorer-activities.pdf',
            text: 'surviving-on-mars-education-explorer-activities.pdf (nasa.gov)',
          },
          {
            type: 'external',
            headline: 'Learn more about the conditions on Mars',
            url: 'https://solarsystem.nasa.gov/news/1679/mars-resources/?page=0&per_page=40&order=created_at+desc&search=&tags=Mars%2CCuriosity+(MSL)%2CMars+2020+Rover%2CDeimos%2CPhobos%2CMars+Moons%2CSpirit%2CMars+Cube+One&category=324',
            text: 'Mars Resources - NASA Solar System Exploration',
          },
          {
            type: 'external',
            headline: 'What do we need to live on Mars?',
            url: 'https://www.bbc.co.uk/bitesize/articles/zrd4cmn',
            text: 'What do we need to live on Mars?',
          },
        ],
        funFacts: [
          {
            type: 'paragraph',
            text: "Dr Aled Roberts from the University of Manchester has been researching materials to use in construction on Mars. He took inspiration from spider silk - it isn't just sticky, it's strong too - gram for gram, it is actually stronger than steel! They compared spider silk to a common protein found in cow blood, which they weren't expecting to be as sticky, but it turned out to be even more so. He then had an idea to try and get the protein from humans. After mixing a protein in human blood with Mars dust, voila! Astro-Crete - a concrete-like substance that can be made on the Red Planet - was born.",
          },
          {
            type: 'external',
            headline: '',
            url: 'https://www.bbc.co.uk/bitesize/articles/zrd4cmn',
            text: 'Life on Mars: What do we need to do it? - BBC Bitesize',
          },
        ],
        activities: [
          {
            number: 1,
            name: 'Activity 1',
            description: 'Design a Base',
            completed: false,
            content: [
              { type: 'headline', text: 'Design a Base' },
              { type: 'image', url: './assets/img/illustrations/dome.webp' },
              {
                type: 'paragraph',
                text: 'Think about everything that is essential for human life on Earth: food, water, oxygen, warmth... If you were building a base on Mars for humans to live on, what would you choose to have in it? A garden? A water source? A toilet? A games room?',
              },
              {
                type: 'paragraph',
                text: 'A Mars habitat for humans will have to be very tough and very sturdy to withstand the cold temperatures and strong dust storms. It will have to be airtight, so the inside can be pumped up with breathable air without exploding or leaking.',
              },
              {
                type: 'paragraph',
                text: 'The materials to build the base should be lightweight, since they will have to be transported into space using rockets. You might like to design your base in pieces that can be easily put together and built on Mars.',
              },
              {
                type: 'paragraph',
                text: 'Researchers are looking into the possibility of using 3D printing technology on Mars. This could make it possible to manufacture building parts directly on Mars - how cool is that?!',
              },
              {
                type: 'list',
                listHeadline: 'You will need:',
                listItems: [
                  'Pages from your sketchbook',
                  'Coloured pens / pencils',
                ],
              },
              {
                type: 'orderedList',
                listHeadline: 'Instructions:',
                listItems: [
                  'Think about the shape and structure of your base. What could withstand the strong winds?',
                  'A dome made of triangles is one possibility. Can you think of other shapes that could fit together easily to make a large structure?',
                  'Make drawings of your designs.',
                  'How many rooms will you include?',
                  'What material will your base be made from?',
                  'How will you build your base on Mars?',
                ],
              },
            ],
          },
          {
            number: 2,
            name: 'Activity 2',
            description: 'Build a Base',
            completed: false,
            content: [
              { type: 'headline', text: 'Build a Base' },
              {
                type: 'image',
                url: './assets/img/illustrations/buildabase.webp',
              },
              {
                type: 'list',
                listHeadline: 'You will need:',
                listItems: [
                  'Paper straws',
                  'Plasticine',
                  'Paper / card',
                  'Scissors',
                ],
              },
              {
                type: 'orderedList',
                listHeadline: 'Instructions:',
                listItems: [
                  'Try turning your design into a 3D model.',
                  'Cut the straws into pieces.',
                  'Try creating shapes with the straws and connecting the pieces of straw with balls of plasticine.',
                  'Can you connect more than one shape together to make a wall?',
                  'Try different shapes. Which is the most effective?',
                  'Would your design stand up to the harsh conditions on Mars?',
                ],
              },
            ],
          },
        ],
        evaluationQuestions: [
          {
            question: 'Tell us how your space day went?',
            answer: '',
          },
          {
            question: 'Do you feel more in control?',
            answer: '',
          },
          {
            question: 'Could you complete all the tasks?',
            answer: '',
          },
        ],
        evaluationShown: false,
        dayComplete: false,
        completionAudioPlayed: false,
      },
      {
        number: 15,
        label: 'Day 15',
        description: 'Mars Rover',
        subtitle:
          'Mars Rovers are special robots sent to Mars to look for signs of life. They have large wheels to move around the rocky surface and features to take photographs of the surface of Mars, or drill into the ground to collect rock samples. What special features will your Mars Rover have?',
        stage: 6,
        extensionComplete: false,
        extensionClicked: false,
        funFactsClicked: false,
        dailyBrief: [
          {
            type: 'profile',
            description: 'Daniel - Space Engineer',
            url: '/assets/img/daniel.webp',
          },
          {
            type: 'audio',
            url: './assets/audio/day15-mars-rover1.mp3',
          },
        ],
        extension: [
          {
            type: 'external',
            headline: 'Play this fun Mars Rover Game',
            url: 'https://spaceplace.nasa.gov/explore-mars/en/',
            text: 'Explore Mars: A Mars Rover Game | NASA Space Place - NASA Science for Kids',
          },
          {
            type: 'external',
            headline: 'About the Mars Rover',
            url: 'https://spaceplace.nasa.gov/mars-rovers/en/',
            text: 'The Mars Rovers | NASA Space Place - NASA Science for Kids',
          },
          {
            type: 'external',
            headline: '',
            url: 'https://www.youtube.com/watch?v=cOkOV1D9ncM',
            text: 'Mars Exploration and Colonization',
          },
          {
            type: 'external',
            headline: '',
            url: 'https://www.funkidslive.com/learn/deep-space-high/destination-mars/designing-mars-rover/',
            text: "Designing a Mars Rover - Fun Kids - the UK's children's radio station (funkidslive.com)",
          },
          {
            type: 'external',
            headline: '',
            url: 'https://www.jpl.nasa.gov/edu/learn/project/make-a-cardboard-rover/',
            text: 'Student Project: Make a Cardboard Rover | NASA / JPL Edu',
          },
          {
            type: 'external',
            headline: '',
            url: 'https://www.planetminecraft.com/project/heavy-mars-rover/',
            text: 'Heavy Mars Rover Minecraft Map (planetminecraft.com)',
          },
        ],
        funFacts: [
          {
            type: 'paragraph',
            text: 'There are currently six rovers on Mars, five operated by the United States and one operated by China. The names of the five rovers of the United States are Sojourner, Spirit and Opportunity, Curiosity, Perseverance. Zhurong, the Chinese rover, landed on 15th May 2021.',
          },
        ],
        activities: [
          {
            number: 1,
            name: 'Activity 1',
            description: 'Design a Mars Rover',
            completed: false,
            content: [
              { type: 'headline', text: 'Design a Mars Rover' },
              {
                type: 'image',
                url: './assets/img/illustrations/marsrover.jpg',
              },
              {
                type: 'audio',
                url: './assets/audio/day15-mars-rover2.mp3',
              },
              {
                type: 'list',
                listHeadline: 'You will need:',
                listItems: [
                  'Mars Template from your Mission Box',
                  'Pens / pencils',
                ],
              },
              {
                type: 'orderedList',
                listHeadline: 'Instructions:',
                listItems: [
                  'Design your own Mars Rover on the surface of Mars.',
                  'What is the purpose of your Rover? Will it collect rocks from the surface or drill into the surface to take deeper samples? Perhaps it will monitor weather conditions, temperatures and atmosphere, take photographs or search for extra-terrestrial life?',
                  'Think about the design features your Rover will need - how will it move, what will power it, how will it communicate.',
                ],
              },
            ],
          },
          {
            number: 2,
            name: 'Activity 2',
            description: 'Build a Mars Rover',
            completed: false,
            pingGroundControl: true,
            content: [
              { type: 'headline', text: 'Build a Mars Rover' },

              {
                type: 'slideshow',
                slides: [
                  './assets/photos/marsroverkit.jpg',
                  './assets/photos/legorover.jpg',
                  './assets/photos/legorover3.jpg',
                  './assets/photos/legorover5.jpg',
                  './assets/img/minecraftrover.webp',
                ],
              },
              {
                type: 'centerparagraph',
                text: 'You can now try building your own Mars Rover. Contact Ground Control to choose a construction kit or Lego bricks to create your Mars Rover.',
              },
              {
                type: 'centerparagraph',
                text: 'You can also choose to explore the Minecraft Mars World on The Nintendo Switch. What features will your world have?',
              },
            ],
          },
        ],
        evaluationQuestions: [
          {
            question: 'Tell us how your space day went?',
            answer: '',
          },
          {
            question: 'Do you feel more in control?',
            answer: '',
          },
          {
            question: 'Could you complete all the tasks?',
            answer: '',
          },
        ],
        evaluationShown: false,
        dayComplete: false,
        completionAudioPlayed: false,
      },
      {
        number: 16,
        label: 'Day 16',
        description: 'Mars Landscape',
        subtitle:
          'Using observation skills, we can study the surface of Mars and compare it with Earth. Mars has many surface features like those on Earth, including volcanoes, stream channels, and impact craters. By comparing features on Mars that are like those on Earth, we can understand more about how the surface of Mars was formed and consider if it is possible to live on Mars in the same way we inhabit Earth.',
        stage: 6,
        extensionComplete: false,
        extensionClicked: false,
        funFactsClicked: false,
        dailyBrief: [
          {
            type: 'profile',
            description: 'Daniel - Space Engineer',
            url: '/assets/img/daniel.webp',
          },
          {
            type: 'audio',
            url: './assets/audio/day16-mars-landscape.mp3',
          },
        ],
        extension: [
          {
            type: 'external',
            headline: 'NASA images',
            url: 'https://mars.nasa.gov/mro/multimedia/images/',
            text: 'Images - NASA Mars',
          },
          {
            type: 'external',
            headline: 'See Mars in 3D',
            url: 'https://mars.nasa.gov/participate/funzone/',
            text: 'Games and Fun!',
          },
          {
            type: 'external',
            headline: '',
            url: 'https://solarsystem.nasa.gov/news/1679/mars-resources/?page=0&per_page=40&order=created_at+desc&search=&tags=Mars%2CCuriosity+(MSL)%2CMars+2020+Rover%2CDeimos%2CPhobos%2CMars+Moons%2CSpirit%2CMars+Cube+One&category=324',
            text: 'Mars Resources - NASA Solar System Exploration',
          },
        ],
        funFacts: [
          {
            type: 'paragraph',
            text: "Mars is also know as the 'Red Planet' because, well, it's red! This signature colour comes from the large amount of chemical called iron oxide (or 'rust' as you might know it) in its rocks and soil. The dust storms on Mars are so large that they are considered to be the biggest in the solar system.",
          },
        ],
        activities: [
          {
            number: 1,
            name: 'Activity 1',
            description: 'Mars in 3D',
            completed: false,
            content: [
              { type: 'headline', text: 'Mars in 3D' },
              { type: 'image', url: './assets/photos/5E3A7008.webp' },
              {
                type: 'list',
                listHeadline: 'You will need:',
                listItems: [
                  'NASA 3D Glasses template from your Mission Box',
                  'Red and cyan cellophane sheets',
                  'Scissors',
                  'Tape',
                ],
              },
              {
                type: 'orderedList',
                listHeadline: 'Instructions:',
                listItems: [
                  'Cut out the glasses template, including the holes for the eyes.',
                  'Cut one cyan-coloured cellophane and one red-coloured cellophane. The two pieces should cover the eyeholes in the glasses.',
                  'Tape the cellophane on the inside of the glasses. Place the red cellophane on the left eye and the cyan cellophane on the right eye (left and right when the glasses are on your face).',
                  'Enjoy the Mars 3D images on the link below with your awesome new glasses!',
                  'Can you identify any of the features on Mars? Look out for rocky surfaces, cliffs, valleys, desert, craters and volcanoes.',
                  'Note the different features in your Log Book.',
                  'How do you think these features were formed?',
                  'Have you seen any of these features on Earth?',
                ],
              },
              {
                type: 'external',
                headline: '',
                url: 'https://mars.nasa.gov/3d/images/?page=0&per_page=25&order=pub_date+desc&search=&category=51%3A190&fancybox=true&url_suffix=',
                text: '3D Images | Mars 3D - NASA Mars Exploration',
              },
            ],
          },
          {
            number: 2,
            name: 'Activity 2',
            description: 'Mars Match',
            completed: false,
            content: [
              { type: 'headline', text: 'Mars Match' },
              {
                type: 'slideshow',
                slides: [
                  './assets/photos/marsmatch1.webp',
                  './assets/photos/marsmatch2.webp',
                  './assets/photos/marsmatch3.webp',
                ],
              },
              {
                type: 'centerparagraph',
                text: 'We can look in more detail at the features of Mars and compare them to similar features on Earth. By matching these features, we can learn clues about how they formed and consider if they could support life.',
              },
              {
                type: 'paragraph',
                text: "Let's explore the differences between Earth and Mars.",
              },
              {
                type: 'list',
                listHeadline: 'You will need:',
                listItems: ['Laminated images from your Mission Box'],
              },
              {
                type: 'orderedList',
                listHeadline: 'Instructions:',
                listItems: [
                  'Find the Mars Cards in your Mission Box.',
                  'The images were taken by looking down on different features from space. Some images show craters and others show volcanoes.',
                  'Try identifying the features and matching them with the same feature found on Earth.',
                  'The sun image in the corner shows the direction of sunlight. Think about where you see shadows to help decide if the photos show craters or volcanoes.',
                ],
              },
              {
                type: 'paragraph',
                text: 'Earth Placemat 1 shows a volcano on Earth taken from the International Space Station. The sunlight is shining from the lower right-hand side. Can you match the correct Mars cards that show a volcano?',
              },
              {
                type: 'paragraph',
                text: 'Earth Placemat 2 shows a view of a river delta on Earth taken from the International Space Station. Can you find the 3 Mars Cards that also show a river delta?',
              },
              {
                type: 'paragraph',
                text: 'Earth Placemat 3 shows an image of Barringer Crater taken from an airplane, with the sunlight shining from the lefthand side. Select the 3 Mars cards that also show a crater on Mars.',
              },
              {
                type: 'paragraph',
                text: 'How do you think these features were created?',
              },
              {
                type: 'paragraph',
                text: 'Do they provide any clues that life could exist on Mars?',
              },
              {
                type: 'paragraph',
                text: 'Write your findings in your Log Book.',
              },
            ],
          },
        ],
        evaluationQuestions: [
          {
            question: 'Tell us how your space day went?',
            answer: '',
          },
          {
            question: 'Do you feel more in control?',
            answer: '',
          },
          {
            question: 'Could you complete all the tasks?',
            answer: '',
          },
        ],
        evaluationShown: false,
        dayComplete: false,
        completionAudioPlayed: false,
      },
    ],
  },
  {
    level: 7,
    name: 'Mars Exploration',
    label: 'Mars Exploration',
    stageColor: '#304069',
    stageTextColor: '#FFFFFF',
    imgUrl: './assets/img/mars_exploration.webp',
    description: 'The sixth stage of the academy.',
    map: {
      size: '14vw',
      maxWidth: '0.5',
      maxHeight: '0.5',
      top: '22vw',
      left: '41vw',
      connectionX: '0.5',
      connectionY: '0.5',
    },
    completed: false,
    completionAudioPlayed: false,
    badgeShown: false,
    locked: true,
    days: [
      {
        number: 17,
        label: 'Day 17',
        description: 'Craters and Volcanos',
        subtitle:
          'Craters are a common feature on the surface of Mars. They are caused by volcanic eruptions, explosions, or impact from rocky objects called asteroids striking the surface of Mars. These asteroids contain frozen water, which could have brought water to Mars millions of years ago. If water is present on Mars, could life thrive here too?',
        stage: 7,
        extensionComplete: false,
        extensionClicked: false,
        funFactsClicked: false,
        dailyBrief: [
          {
            type: 'profile',
            description: 'Sophie - Science Officer',
            url: '/assets/img/sophie.webp',
          },
          {
            type: 'audio',
            url: './assets/audio/day17-craters-volcanos.mp3',
          },
        ],
        extension: [
          {
            type: 'external',
            headline:
              'There are more ways to learn about volcanos on Mars on the extension page.',
            url: 'https://www.lpi.usra.edu/education/explore/mars/inside_mars/volcanism/',
            text: 'Explore - Mars: Inside and Out! - Puzzling Patterns - Where Does Volcanism Occur? ( usra.edu)',
          },
          {
            type: 'external',
            headline: '',
            url: 'https://www.lpi.usra.edu/education/resources/s_system/mappingMars.shtml',
            text: 'Mapping Channels and Craters on Mars (usra.edu)',
          },
          {
            type: 'external',
            headline: 'Olympus Mons',
            url: 'https://www.palebluemarbles.com/facts-about-mars/',
            text: '10 Facts about Mars',
          },
        ],
        funFacts: [
          {
            type: 'paragraph',
            text: "Mars has the biggest volcano in the Solar System: it's called Olympus Mons. Olympus Mons is about as high as three Mount Everests (the highest mountain above sea level on Earth) stacked on top of one another. It covers an area about the size of Arizona, or about 80% of the size of Germany. Olympus Mons has not erupted for several million years but it's unclear if the volcano is extinct or just dormant. ",
          },
        ],
        activities: [
          {
            number: 1,
            name: 'Activity 1',
            description: 'Cast your Crater',
            completed: false,
            content: [
              { type: 'headline', text: 'Cast your Crater' },
              {
                type: 'video',
                url: 'https://www.youtube.com/embed/PNc3qlQsTe4',
              },
              {
                type: 'centerparagraph',
                text: 'In this activity you can learn about the powerful impact of asteroids and create your own Mars craters.',
              },
              {
                type: 'list',
                listHeadline: 'You will need:',
                listItems: [
                  'Scales',
                  'Ruler',
                  'Tape measure',
                  'Assorted balls',
                  'Foam soap',
                  'Tray',
                  'Table in Log Book',
                ],
              },
              {
                type: 'orderedList',
                listHeadline: 'Instructions:',
                listItems: [
                  'Spray the foam soap over the tray and use the ruler to smooth out the surface.',
                  "Drop the various 'asteroids' (assorted small balls) onto the tray. Use the tape measure to measure the height at which each object is dropped from.",
                  'Use the ruler to measure the width of the crater made by the object.',
                  'Use the scales to measure the mass of the object.',
                  'Take note of these results in the table in your Log Book.',
                  'For a fair experiment which can give you useful information, only one thing should be changed during the experiment. For example, if you wanted to investigate how height affects the size of the crater, you would drop the same object from different heights. Another factor you could investigate is mass of the object.',
                ],
              },
            ],
          },
          {
            number: 2,
            name: 'Activity 2',
            description: 'Comparing Volcanos on Mars and Earth',
            completed: false,
            content: [
              {
                type: 'headline',
                text: 'Comparing Volcanos on Mars and Earth',
              },
              {
                type: 'image',
                url: './assets/img/illustrations/volcanos.jpg',
              },
              {
                type: 'paragraph',
                text: "The presence of volcanoes on the surface of a planet is evidence that the interior of that planet was once — or still is — hot enough to produce molten rock — lava! Planets with active volcanoes are said to be geologically active and scientists believe that this is important for a planet's ability to support life. Mars has many volcanoes, including the largest volcano in our solar system, Olympus Mons. Since Mars was geologically active in the past, does it have the right ingredients to support life?",
              },
              {
                type: 'paragraph',
                text: "Did you know that Earth also has many volcanoes, including right here in Edinburgh? Arthur's Seat is a dormant volcano and the rock on which Edinburgh Castle is built is the plug of a volcano, believed to be around 350 million years old. If a city like Edinburgh can form around dormant volcanoes, could the volcanic landscape of Mars become inhabited one day too?",
              },
              {
                type: 'paragraph',
                text: 'One big difference is the size of volcanoes on Mars compared to those on Earth. Use the scale model to compare the difference in size between the largest volcano on Earth, with Olympus Mons on Mars.',
              },
              {
                type: 'list',
                listHeadline: 'You will need:',
                listItems: [
                  'Olympus Mons & Mauna Kea Volcano Templates from your Mission Box',
                  'Scissors',
                  'Tape',
                  'Blue coloured pencil',
                ],
              },
              {
                type: 'orderedList',
                listHeadline: 'Instructions:',
                listItems: [
                  'Take your Olympus Mons & Mauna Kea Volcano Templates and colour the shaded area on the smaller volcano blue.',
                  'Cut out the outline of the first volcano then cut the straight line up the side.',
                  'Very slightly overlap the edges of the cut and tape it, forming a gentle cone.',
                  'Repeat for the second volcano.',
                  'Compare the two volcanos.',
                  'These paper volcanos are scale models of a volcano on Earth and one on Mars. Can you guess which is which?',
                  'Each of the squiggly circles around the volcanos represents one mile in height, or altitude. How many miles are there from the bottom to the top on the tall volcano?',
                  'Which would you rather climb?',
                ],
              },
            ],
          },
          {
            number: 3,
            name: 'Activity 3',
            description: 'Volcano Topography Map',
            completed: false,
            content: [
              { type: 'headline', text: 'Volcano Topography Map' },
              {
                type: 'video',
                url: 'https://www.youtube.com/embed/_KARubo6aeo',
              },
              {
                type: 'paragraph',
                text: 'You have discovered that the volcanoes on Mars are much bigger than those found on Earth. The low gravity on Mars means volcanoes can grow higher. Also, weathering on Mars is very low so the volcanoes stay large as there is less erosion of the surface.',
              },
              {
                type: 'paragraph',
                text: "Scientists observe and measure changes in the shape of a volcano's surface (topography) to help understand the process of how they form, grow and erode.",
              },
              {
                type: 'paragraph',
                text: 'Recreate a volcano from the Martian landscape and make a topographic map to send back to scientists on Earth.',
              },
              {
                type: 'list',
                listHeadline: 'You will need:',
                listItems: ['Plasticine', 'White paper', 'Pencils'],
              },
              {
                type: 'orderedList',
                listHeadline: 'Instructions:',
                listItems: [
                  'Create the first base layer of your volcano from plasticine.',
                  'Draw round the base layer on a piece of paper.',
                  'Create the next layer from plasticine to add to your volcano.',
                  'Draw round this layer on the same piece of paper, placing the second layer inside the first circle.',
                  'Repeat these steps, adding more layers of plasticine to create your volcano, and drawing smaller circles within your drawing.',
                  'When you have drawn round each section you have completed your topography map.',
                ],
              },
            ],
          },
        ],
        evaluationQuestions: [
          {
            question: 'Tell us how your space day went?',
            answer: '',
          },
          {
            question: 'Do you feel more in control?',
            answer: '',
          },
          {
            question: 'Could you complete all the tasks?',
            answer: '',
          },
        ],
        evaluationShown: false,
        dayComplete: false,
        completionAudioPlayed: false,
      },
      {
        number: 18,
        label: 'Day 18',
        description: 'Geology',
        subtitle:
          "Geologists are scientists who study a planet's solid features, like soil, rocks, and minerals. By studying these features, we can learn more about how rocky worlds form and change over time. Mars Rovers use cameras to capture detailed pictures or collect rock samples to send back to Earth.",
        stage: 7,
        extensionComplete: false,
        extensionClicked: false,
        funFactsClicked: false,
        dailyBrief: [
          {
            type: 'profile',
            description: 'Daniel - Space Engineer',
            url: '/assets/img/daniel.webp',
          },
          {
            type: 'audio',
            url: './assets/audio/day18-geology.mp3',
          },
        ],
        extension: [
          {
            type: 'external',
            headline: '',
            url: 'https://solarsystem.nasa.gov/planets/mars/in-depth/',
            text: 'Mars exploration',
          },
          {
            type: 'external',
            headline: '',
            url: 'https://spaceplace.nasa.gov/water-on-mars/en/',
            text: 'Why Do We Care About Water on Mars? | NASA Space Place - NASA Science for Kids',
          },
          {
            type: 'external',
            headline: '',
            url: 'https://www.jpl.nasa.gov/edu/teach/activity/exploring-the-colors-of-mars',
            text: 'Educator Guide: Exploring the Colors of Mars | NASA / JPL Edu',
          },
          {
            type: 'external',
            headline: '',
            url: 'https://www.lpi.usra.edu/education/explore/mars/surface/feature-story/',
            text: 'Mars topographic map Explore - Mars: Inside and Out! - Mars: The Feature Story (usra.edu)',
          },
        ],
        funFacts: [
          {
            type: 'paragraph',
            text: 'Regardless of the nature of the mission, after returning to Earth, all astronauts go through customs. For example, astronauts Neil Armstrong and Buzz Aldrin, returning from the Moon, declared moon rocks, moon dust, and other lunar samples collected from the lunar surface.',
          },
        ],
        activities: [
          {
            number: 1,
            name: 'Activity 1',
            description: 'Describing Rocks',
            completed: false,
            content: [
              { type: 'headline', text: 'Describing Rocks' },
              {
                type: 'image',
                url: './assets/img/illustrations/describingrocks.jpg',
              },
              {
                type: 'paragraph',
                text: 'To classify rocks, scientists make observations that give us clues about how they formed.',
              },
              {
                type: 'list',
                listHeadline: 'Geologists look for these different categories:',
                listItems: [
                  'Luster describes the sheen, glossiness, or reflectiveness of a surface.',
                  'Cleavage refers to how the rock breaks in different directions.',
                  'Vesicles and voids are found when pockets of gas expanded within the rock during formation.',
                  'Inclusions are impurities found within the structure of a rock from when the rock was first formed.',
                  'Blebs are minerals growing within minerals.',
                  'Homogeneous refers to the level of evenness throughout the structure, while a structure that is not very even would be called heterogeneous.',
                  'When a sample is very easily crushed, it is referred to as friable.',
                ],
              },
              {
                type: 'paragraph',
                text: 'Can you apply these descriptions to the different sweet treat images in your Log Book.',
              },
              {
                type: 'external',
                headline:
                  'Learn to describe rocks like a NASA scientist through this online quiz.',
                url: 'https://www.jpl.nasa.gov/edu/learn/project/describe-rocks-like-a-nasa-scientist/',
                text: 'Student Project: Describe Rocks Like a NASA Scientist | NASA / JPL Edu',
              },
              {
                type: 'paragraph',
                text: 'We can use different types of cakes and sweet treats to practise identifying sediments on Mars.',
              },
              {
                type: 'list',
                listHeadline: 'You will need:',
                listItems: ['Table of sweet treats in your Log Book', 'Pencil'],
              },
              {
                type: 'orderedList',
                listHeadline: 'Instructions:',
                listItems: [
                  'Look at each image in the table and describe what the cakes look like, taking care to see if you can spot anything that sets them apart from others. This is exactly what Earth and Planetary Scientists do when looking at features on Earth and beyond!',
                ],
              },
            ],
          },
          {
            number: 2,
            name: 'Activity 2',
            description: 'Rock Sample',
            completed: false,
            content: [
              { type: 'headline', text: 'Rock Sample' },
              {
                type: 'video',
                url: 'https://www.youtube.com/embed/I3-DSZ0OyW0',
              },
              {
                type: 'paragraph',
                text: 'Scientists are especially interested in a type of sedimentary rock called a conglomerate. These rocks are made up of small pebbles of other rock types cemented together. Conglomerates are interesting to scientists because the rounding of the pebbles indicates that water once eroded, or ate away at, these rocks. And where there was once water, there might have once been life.',
              },
              {
                type: 'paragraph',
                text: 'The Martian atmosphere has weathered the exterior of rocks on Mars for billions of years, erasing much of the information we might gather from simply looking at a picture of a rock. To gather more information from a rock sample, Mars Rovers can help to drill into the rocks and extract a small section called a core sample. Can you create your own core sample and learn how to analyse it.',
              },
              {
                type: 'list',
                listHeadline: 'You will need:',
                listItems: [
                  '4 colours of clay / plasticine',
                  'Sprinkles / beads',
                  'Plastic straws',
                  'Wooden skewer',
                  'Clay tools',
                  'Tray',
                  'Scissors',
                ],
              },
              {
                type: 'orderedList',
                listHeadline: 'Instructions:',
                listItems: [
                  'Choose one colour of clay that will make up most of your rock and cement your other rock pieces together.',
                  'Using the remaining colours, form pea-size and marble-size balls to represent inclusions, or material trapped inside a rock during its formation.',
                  'Mix some sprinkles into one or more colours then form it into a cube - this will represent harder rocks.',
                  'Grab a chunk of your main colour of clay that will become the bottom of your rock. Flatten it onto the tray. Add the balls and cubes of other colours and chunks of the main colour and press them all together to form a rock.',
                  'Cover your rock with the remainder of the main colour so it looks like the rock is one colour. This represents the weathering that can oxidize, or change the surface by chemically reacting with oxygen, rock surfaces on Mars.',
                  "Now let's take a core sample.",
                  'Cut the plastic straws into three pieces.',
                  'Push an open end of the straw straight down into the rock, all the way to the tray. Twist the straw and lift out a sample.',
                  'This is a core sample of your rock. Use a skewer to push your core sample out of the straw for inspection.',
                  'Examine your core sample. Can you see inclusions or layers underneath the surface of the rock?',
                  'Use your core sample to investigate how the rock formed over time and how many different materials it is made of.',
                  'Compare the size and roundness of any inclusions in your core sample. The larger an inclusion, the closer to its source it is. Smaller inclusions will have travelled farther, perhaps in moving water or in a landslide or other geologic event. Rounder inclusions indicate a more active body of water that smoothed them during tumbling.',
                  'Record your findings in your Log Book. Think about how many different types of rock inclusions are in the conglomerate? Which colour of rock makes up most of the rock? Which colour makes up the least? Do any inclusions indicate they formed in the presence of water?',
                ],
              },
            ],
          },
        ],
        evaluationQuestions: [
          {
            question: 'Tell us how your space day went?',
            answer: '',
          },
          {
            question: 'Do you feel more in control?',
            answer: '',
          },
          {
            question: 'Could you complete all the tasks?',
            answer: '',
          },
        ],
        evaluationShown: false,
        dayComplete: false,
        completionAudioPlayed: false,
      },
      {
        number: 19,
        label: 'Day 19',
        description: 'Life on Mars',
        subtitle:
          "A planet's atmosphere determines much of what happens on its surface. Earth's atmosphere protects us from much of the sun's harmful radiation, provides us with oxygen to breathe and burns up any meteorites before they hit Earth. Without our precious atmosphere, life on Earth wouldn't be possible. Mars's atmosphere is about 100 times thinner than Earth's, which explains why it looks so different and why life on Mars is so difficult.",
        stage: 7,
        extensionComplete: false,
        extensionClicked: false,
        funFactsClicked: false,
        dailyBrief: [
          {
            type: 'profile',
            description: 'Daniel - Space Engineer',
            url: '/assets/img/daniel.webp',
          },
          {
            type: 'audio',
            url: './assets/audio/day19-life-on-mars.mp3',
          },
        ],
        extension: [
          {
            type: 'external',
            headline:
              'You can recap on everything you have discovered about Mars through the extensions page.',
            url: 'https://solarsystem.nasa.gov/planets/mars/overview/',
            text: 'Overview | Mars - NASA Solar System Exploration',
          },
          {
            type: 'external',
            headline: '',
            url: 'https://spaceplace.nasa.gov/all-about-mars/en/',
            text: 'All About Mars | NASA Space Place - NASA Science for Kids',
          },
          {
            type: 'external',
            headline: '',
            url: 'https://www.natgeokids.com/uk/discover/science/space/facts-about-mars/',
            text: 'Facts About Mars |Facts for Kids - National Geographic Kids (natgeokids.com)',
          },
          {
            type: 'external',
            headline: '',
            url: 'https://www.ducksters.com/science/mars.php',
            text: 'Astronomy for Kids: The Planet Mars (ducksters.com)',
          },
        ],
        funFacts: [
          {
            type: 'paragraph',
            text: 'In 1996, a group of scientists told the world that they had found evidence of bacteria inside a meteorite that had come from Mars. However, this idea is not very popular with most other scientists. If life is found on Mars in the future, it would be the first proof that we are not alone in the Universe.',
          },
        ],
        activities: [
          {
            number: 1,
            name: 'Activity 1',
            description: 'Protecting Life',
            completed: false,
            content: [
              { type: 'headline', text: 'Protecting Life' },
              {
                type: 'video',
                url: 'https://www.youtube.com/embed/6lOSW8A20nk',
              },
              {
                type: 'paragraph',
                text: 'Ultra Violet (UV) radiation from the Sun affects living things. Humans need UV radiation because our skin uses it to make vitamin D, which is vital to maintaining healthy bones. About 10 minutes of Sun each day allows our skin to make the recommended amount of vitamin D. However, too much exposure to UV causes the skin to burn and leads to wrinkled and patchy skin, skin cancer, and cataracts.',
              },
              {
                type: 'paragraph',
                text: 'On Earth, we are protected by our atmosphere from most UV radiation coming from the Sun. The ozone layer absorbs much of the UV portion and we can protect ourselves completely by covering ourselves with clothing and using sun cream.',
              },
              {
                type: 'paragraph',
                text: 'On Mars there is very little atmosphere to protect living things from UV radiation — or from X-rays and gamma-rays or even more dangerous cosmic rays. Organisms would have to provide their own protection by adapting, or in sheltered environments such as living underground.',
              },
              {
                type: 'paragraph',
                text: 'The next experiment explores how UV affects living things. Can you create enough protection to preserve life on Mars from harmful radiation? Could you survive on Mars?',
              },
              {
                type: 'list',
                listHeadline: 'You will need:',
                listItems: [
                  'UV Beads (white)',
                  'Non-UV Beads',
                  'Pipe cleaners',
                  'UV Torch',
                  'Non-UV Torch',
                  'Paper / card',
                ],
              },
              {
                type: 'orderedList',
                listHeadline: 'Instructions:',
                listItems: [
                  'Design and create a life form using the pipe cleaners and beads.',
                  'The UV (white) beads will act as radiation detectors.',
                  'Use your UV torch to act as radiation on Mars. Shine it directly onto your life form. ',
                  'What do you notice about the UV radiation detectors (white beads)? Have they changed colour?',
                  'Can you use the paper/card to create a protective shield from the radiation?',
                  'What happens to the UV beads now, do they still change colour?',
                  'Use the normal torch to act as radiation on Earth. Do you notice any changes when you shine the normal torch onto your life form?',
                  'Record your observations in your Log Book.',
                  'Do you think life could survive the strong radiation on Mars?',
                ],
              },
            ],
          },
          {
            number: 2,
            name: 'Activity 2',
            description: 'Design a Martian',
            completed: false,
            content: [
              { type: 'headline', text: 'Design a Martian' },
              { type: 'image', url: './assets/img/illustrations/alien.webp' },
              {
                type: 'paragraph',
                text: 'Thinking about what you have discovered about the extreme conditions on Mars, can you design a life form that could exist on Mars?',
              },
              {
                type: 'list',
                listHeadline: 'You will need:',
                listItems: ['Paper', 'Pens / pencils'],
              },
              {
                type: 'orderedList',
                listHeadline: 'Instructions:',
                listItems: [
                  'Draw your new life form - is it an animal / plant / microscopic life form such as bacteria?',
                  'Your drawing should show what size, colour and shape it is. Will it be camouflaged against the Martian surface?',
                  'Create a name for your life form. Will it be based on the name of a living thing on Earth? (e.g. Mars Fungus, Olympus Mons-monkey) or will it be a whole new life form altogether? Perhaps it might be based on a creature from a space-based tv show or film?',
                  'Can you make a description or labelled drawing of the habitat where your life form exists? Does it live on an ice field at the Mars South Pole? Is it in an extinct Martian volcano? Does it live in a tunnel underground?',
                ],
              },
            ],
          },
        ],
        evaluationQuestions: [
          {
            question: 'Tell us how your space day went?',
            answer: '',
          },
          {
            question: 'Do you feel more in control?',
            answer: '',
          },
          {
            question: 'Could you complete all the tasks?',
            answer: '',
          },
        ],
        evaluationShown: false,
        dayComplete: false,
        completionAudioPlayed: false,
      },
      {
        number: 20,
        label: 'Day 20',
        description: 'Final Findings',
        subtitle:
          'Captain, you have reached the end of your Mission to Mars! It is time to look at all the evidence you have gathered and report back on your findings. Could conditions on Mars support life to exist? Have you found any evidence of life in your research?',
        stage: 7,
        extensionComplete: false,
        extensionClicked: false,
        funFactsClicked: false,
        dailyBrief: [],
        extension: [
          {
            type: 'external',
            headline:
              'Play this game to find out how much you know about Mars:',
            url: 'https://www.lpi.usra.edu/education/explore/mars/over_mars/gameboard.pdf',
            text: 'mindovermars.ai (usra.edu)',
          },
          {
            type: 'external',
            headline: '',
            url: 'https://www.lpi.usra.edu/education/explore/mars/over_mars/',
            text: 'Explore - Mars: Inside and Out! - Mind Over Mars (usra.edu)',
          },
        ],
        funFacts: [
          {
            type: 'paragraph',
            text: 'A year on Mars lasts 687 days. This is how long it takes Mars to complete one orbit around the Sun.',
          },
          {
            type: 'paragraph',
            text: "The tilt of Mars on its axis (25°) is similar to Earth's (23.5°). This gives it winter, summer, autumn and spring seasons as it orbits the Sun.",
          },
          {
            type: 'paragraph',
            text: "Earth's orbit is nearly circular but Mars's is more elliptical. This makes the seasons different lengths, since Mars travels more slowly when it is further from the Sun.",
          },
        ],
        activities: [
          {
            number: 1,
            name: 'Activity 1',
            description: 'What do we know?',
            completed: false,
            content: [
              { type: 'headline', text: 'What do we know?' },
              {
                type: 'image',
                url: './assets/img/whatdoweknow.webp',
              },
              {
                type: 'paragraph',
                text: "Let's re-cap on everything you have learned on your journey by looking at these facts about Mars.",
              },
              {
                type: 'external',
                headline: '',
                url: 'https://mars.nasa.gov/all-about-mars/facts/',
                text: 'Mars Facts | All About Mars - NASA Mars Exploration',
              },
              {
                type: 'paragraph',
                text: "Try answering the short 'What Do We Know' quiz in your Log Book to refresh your memory.",
              },
            ],
          },
          {
            number: 2,
            name: 'Activity 2',
            description: 'Final Findings Survey',
            completed: false,
            content: [
              { type: 'headline', text: 'Final Findings Survey' },
              {
                type: 'survey',
                listHeadline: '',
                content: [
                  {
                    type: 'headline',
                    text: "Your mission was to discover if there was life on Mars. Let's reconsider the conditions needed for life such as atmosphere, how we breath, energy, food, water and protection, and look at the evidence you have gathered.",
                  },
                  {
                    type: 'smileys',
                    id: 1,
                    question:
                      'Could you build a structure to withstand the harsh conditions of Mars and provide enough protection to sustain life?',
                    answer: '',
                  },
                  {
                    type: 'smileys',
                    id: 2,
                    question:
                      'Did you find evidence that liquid water once flowed on Mars, making it possible that life could have thrived there too?',
                    answer: '',
                  },
                  {
                    type: 'smileys',
                    id: 3,
                    question:
                      'Could life exist in the thin atmosphere and high radiation levels present on Mars?',
                    answer: '',
                  },
                  {
                    type: 'smileys',
                    id: 4,
                    question:
                      'Did you find any evidence of changes to the rocks, soil or landscape that indicate life might be present?',
                    answer: '',
                  },
                  {
                    type: 'smileys',
                    id: 5,
                    question: 'Do you think life exists on Mars?',
                    answer: '',
                  },
                ],
              },
            ],
          },
          {
            number: 3,
            name: 'Activity 3',
            description: 'Present Your Findings',
            completed: false,
            content: [
              { type: 'headline', text: 'Present Your Findings' },
              {
                type: 'paragraph',
                text: 'Look at all the evidence you have gathered on your Mission. Can you present your findings to bring back to scientists on Earth.',
              },
              {
                type: 'image',
                url: './assets/img/findings.webp',
              },
              {
                type: 'list',
                listHeadline: 'You will need:',
                listItems: [
                  'Log Book',
                  'Materials left over from your Mission Boxes',
                  'Sketchbook',
                  'Pens / pencils',
                ],
              },
              {
                type: 'orderedList',
                listHeadline: 'Instructions:',
                listItems: [
                  'Gather all your evidence and choose a way to present your findings. You might like to make a collage or a poster, draw a picture, write a story about what you have learned, or use one of your Mission Boxes and some of the materials you have left over to create a model. Be as creative as you like.',
                  'Think about what you have learned about the conditions on Mars - temperature, atmosphere, air quality, weather.',
                  'Consider the features of the landscape and how they have formed.',
                  'Reflect on the geology of the rocks and what you have discovered.',
                  'Tell us your conclusions, does life exist on Mars?',
                ],
              },
            ],
          },
        ],
        evaluationQuestions: [
          {
            question: 'Tell us how your space day went?',
            answer: '',
          },
          {
            question: 'Do you feel more in control?',
            answer: '',
          },
          {
            question: 'Could you complete all the tasks?',
            answer: '',
          },
        ],
        evaluationShown: false,
        dayComplete: false,
        completionAudioPlayed: false,
      },
    ],
  },
  {
    level: 8,
    name: 'Return to Earth',
    label: 'Return to Earth',
    stageColor: '#F1DBB9',
    stageTextColor: '#0B023D',
    imgUrl: './assets/img/return_to_earth.webp',
    description: 'The sixth stage of the academy.',
    map: {
      size: '9vw',
      maxWidth: '0.5',
      maxHeight: '0.5',
      top: '27vw',
      left: '19vw',
      connectionX: '0.5',
      connectionY: '0.5',
    },
    completed: false,
    completionAudioPlayed: false,
    badgeShown: false,
    locked: true,
    days: [
      {
        number: 21,
        label: 'Day 21',
        description: 'Return to Earth',
        subtitle:
          'You have completed your Mission to Mars and are now ready to return to Earth! Can you tell Ground Control all about your journey.',
        stage: 8,
        extensionComplete: false,
        extensionClicked: false,
        funFactsClicked: false,
        dailyBrief: [
          {
            type: 'profile',
            description: 'Sophie - Science Officer',
            url: '/assets/img/sophie.webp',
          },
          {
            type: 'audio',
            url: './assets/audio/day20-is-there-life-on-mars.mp3',
          },
        ],
        extension: [
          {
            type: 'paragraph',
            text: 'Challenges associated with long-duration spaceflight do not end with landing. Astronauts often suffer from balance problems that lead to dizziness and difficulty standing, walking and turning corners when they return to normal gravity. Researchers are developing techniques, using a treadmill and simulated balance disturbances, to help astronauts adapt to a new gravity environment.',
          },
          {
            type: 'external',
            headline: '',
            url: 'https://www.nasa.gov/feature/7-ways-astronaut-scott-kelly-will-need-to-readjust-to-earth-after-340-days-in-space',
            text: '7 Ways Astronaut Scott Kelly Will Need to Readjust to Earth | NASA',
          },
          {
            type: 'external',
            headline: '',
            url: 'https://scienceblog.com/28212/astronaut-balancing-act-training-to-help-explorers-adapt-to-a-return-to-gravity/',
            text: 'Astronaut Balancing Act: Training to Help Explorers Adapt to a Return to Gravity | ScienceBlog.com',
          },
          {
            type: 'external',
            headline: '',
            url: 'https://airandspace.si.edu/stories/editorial/how-astronauts-return-earth',
            text: 'How Astronauts Return to Earth | National Air and Space Museum (si.edu)',
          },
        ],
        funFacts: [
          {
            type: 'paragraph',
            text: "When astronauts return to earth, they experience a number of changes. First, they must readjust to the earth's gravity. This can cause them to feel dizzy and disoriented. They may also experience changes in their vision. Second, they must readjust to the Earth's atmosphere. This can cause them to feel short of breath and have difficulty sleeping. Finally, they must readjust to the Earth's climate. This can cause them to feel cold and sweaty.",
          },
          {
            type: 'paragraph',
            text: "In space, Earth's gravity does not affect us, so our spine straightens, and we become 3-5 cm taller. After returning to Earth, growth is restored after a few months. Want to grow up in a few hours? Go to space.",
          },
          {
            type: 'paragraph',
            text: "After a long stay in weightlessness, astronauts are getting used to the fact that things on Earth do not fly around them in free flight but fall under the force of the Earth's gravity. So, when they return home, they often drop pens, cups, keys, and other objects until they get used to gravity again.",
          },
          {
            type: 'external',
            headline: '',
            url: 'https://orbitaltoday.com/2022/07/15/25-amazing-facts-about-astronauts-everything-you-wanted-to-know-about-space-travel/',
            text: "25 Interesting Facts About Astronauts You Didn't Know - Orbital Today",
          },
        ],
        activities: [
          {
            number: 1,
            name: 'Activity 1',
            description: 'Comic Strip Journey',
            completed: false,
            content: [
              { type: 'headline', text: 'Comic Strip Journey' },
              {
                type: 'image',
                url: './assets/img/comic.webp',
              },
              {
                type: 'list',
                listHeadline: 'You will need:',
                listItems: ['Sketchbook', 'Pens / pencils'],
              },
              {
                type: 'orderedList',
                listHeadline: 'Instructions:',
                listItems: [
                  'Create a comic strip all about the different stages of your journey to Mars.',
                  'Divide the page into sections - this could be 3 / 4 / 6',
                  'Create a scene in each of the sections about your journey.',
                  'What was the highlight of your Mission?',
                ],
              },
            ],
          },
          {
            number: 2,
            name: 'Activity 2',
            description: 'Journey Map Jigsaw',
            completed: false,
            content: [
              { type: 'headline', text: 'Journey Map Jigsaw' },
              {
                type: 'image',
                url: './assets/img/illustrations/journeyjigsaw.webp',
              },
              {
                type: 'paragraph',
                text: 'Contact Mission Control to borrow a jigsaw map charting your journey to Mars and back. Can you piece it together?',
              },
            ],
          },
          {
            number: 3,
            name: 'Activity 3',
            description: 'Return to Earth video',
            completed: false,
            content: [
              {
                type: 'video',
                url: 'https://www.youtube.com/embed/OW9JTJPX8MI',
                text: 'Return to Earth video',
              },
            ],
          },
          {
            number: 4,
            name: 'Activity 4',
            description: 'Space Academy Survey',
            completed: false,
            content: [
              { type: 'headline', text: 'Space Academy Survey' },
              {
                type: 'finalSurvey',
                listHeadline: '',
                content: [
                  {
                    type: 'headline',
                    text: 'Congratulations in taking part in Space Academy! Can you tell us what you thought about your Mission to Mars and how to make it better?',
                  },
                  {
                    type: 'smileys',
                    id: 1,
                    question:
                      'How do you feel after taking part in Space Academy?',
                    answer: '',
                  },
                  {
                    type: 'freeText',
                    question:
                      'Can you tell us what you enjoyed most / least about your Mission to Mars?',
                    answer: '',
                  },
                  {
                    type: 'freeText',
                    question:
                      "One of the toughest parts of an astronaut's mission is being in isolation. What is the hardest part of being in hospital for you?",
                    answer: '',
                  },
                  {
                    type: 'number',
                    question:
                      'How did taking part in Space Academy change your stay in hospital?',
                    answer: '',
                  },
                  {
                    type: 'wordBank',
                    question:
                      'Choose 3 words from the word bank to describe your experience:',
                    options: [
                      'fun',
                      'fact-filled',
                      'provided distraction',
                      'positive',
                      'entertaining',
                      'okay',
                      'exciting',
                      'playful',
                      'challenging',
                      'reduced isolation',
                      'informative',
                      'enjoyable',
                      'good connection',
                      'uninteresting',
                      'creative',
                      'fascinating',
                      'inspiring',
                      'original',
                      'complicated',
                      'in control',
                      'too much',
                      'amazing',
                    ],
                    answer: [],
                  },
                ],
              },
            ],
          },
        ],
        evaluationQuestions: [
          {
            question: 'Tell us how your space day went?',
            answer: '',
          },
          {
            question: 'Do you feel more in control?',
            answer: '',
          },
          {
            question: 'Could you complete all the tasks?',
            answer: '',
          },
        ],
        evaluationShown: false,
        dayComplete: false,
        completionAudioPlayed: false,
      },
    ],
  },
] as AcademyStage[];
