<div
  class="flex gap-20 items-center justify-center relative cursor-pointer"
  routerLink="{{ number }}"
  [style.color]="stage.stageTextColor"
>
  <div
    class="w-[20vw] max-w-[450px] rounded-br-[35px] rounded-[30px] h-[35vh] min-h-[200px] max-h-[600px] flex flex-col justify-between items-center z-0 border-t-4 border-l-4 border-white"
    [style.background]="stage.stageColor"
  >
    <div>
      <img
        src="/assets/img/day_card_shadow.svg"
        class="absolute bottom-0 right-0 w-[19vw] max-w-[200px] z-[-10]"
      />
    </div>

    <div class="px-6 py-4 flex flex-col items-center h-[25vh] justify-between">
      <div
        class="font-bold text-[1.2vw] tablet:text-[2vw] laptop:text-[1.8vw] desktop:text-[1.2vw] text-center mb-5 mt-5"
      >
        <h2>{{ name }}</h2>
      </div>
      <div class="flex h-[5vh] mb-10">
        <p
          class="text-white-700 text-center text-[1.2vw] tablet:text-[1.6vw] laptop:text-[1.2vw] desktop:text-[1vw]"
        >
          {{ description }}
        </p>
      </div>
    </div>
    <div class="px-6 pb-5">
      <button
        routerLink="{{ number }}"
        class="bg-[#48a5aa] rounded-full text-[1.2vw] tablet:text-[1 vw] laptop:text-[1.5vw] px-8 py-2 z-[20] cursor-pointer hover:shadow-2xl transition-shadow"
      >
        {{ completed ? "View" : "Begin" }}
      </button>
    </div>
    <img
      *ngIf="completed"
      src="/assets/img/experiment_complete_icon.svg"
      class="absolute top-[-20px] right-[-20px] w-[60px] xl-desktop:w-[3vw]"
    />
  </div>
</div>
