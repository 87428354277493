import { Component, OnInit } from '@angular/core';
import { Academy } from '@models/academy';
import { AcademyService } from '@services/academy.service';
import { MailingService } from '@services/mailing.service';

@Component({
  selector: 'setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.scss'],
  standalone: false
})
export class Setup implements OnInit {
  academy: Academy;
  administratorPin: string = '000000';
  administratorPinError: boolean = false;
  astronautPin: string = '0000';
  astronautPinError: boolean = false;
  pinErrorMessage: string = '';
  headingText: string = 'Enter PIN';
  loggedIn: boolean = false;
  error: boolean = false;
  errorMessage: string = '';
  teamMemberNames: string[] = [];
  evaluations: any[] = [];
  evaluationsShowing: boolean = false;
  emailEvaluationButtonText: string = 'Email Evaluations';
  constructor(
    private academyService: AcademyService,
    private mailingService: MailingService
  ) {
    this.academy = academyService.getAcademy();
    this.evaluations = this.academyService
      .getEvaluations()
      .filter((evaluation) => {
        return (
          evaluation.evaluationQuestions.filter((question: any) => {
            return question.answer !== '';
          }).length > 0
        );
      });
  }

  ngOnInit(): void { }

  resetAcademy() {
    this.academyService.unlockProgress(1);
  }
  changeAdministratorPin(input: HTMLInputElement) {
    this.administratorPin = input.value;
    this.academy.administrator.pin = input.value;
    this.academyService.storeAcademy();
    input.value = '';
  }
  changeAstronautPin(input: HTMLInputElement) {
    this.astronautPin = input.value;
    this.academy.astronaut.id = input.value;
    this.academyService.storeAcademy();
    input.value = '';
  }

  resetPin() {
    let newPin = Math.floor(1000 + Math.random() * 9000);
    this.academy.astronaut.id = newPin.toString();
    this.academyService.storeAcademy();
  }
  checkInput(event: HTMLInputElement) {
    if (event.value.length > 1) {
      event.value = '';
    }
  }

  verifyUser(
    input1: HTMLInputElement,
    input2: HTMLInputElement,
    input3: HTMLInputElement,
    input4: HTMLInputElement,
    input5: HTMLInputElement,
    input6: HTMLInputElement
  ) {
    let pin = Number(
      input1.value +
      input2.value +
      input3.value +
      input4.value +
      input5.value +
      input6.value
    );

    if (Number(this.academy.administrator.pin) === pin) {
      this.loggedIn = true;
    } else {
      this.error = true;
      this.headingText = 'Incorrect PIN, try again';
      input1.value = '';
      input2.value = '';
      input3.value = '';
      input4.value = '';
      input5.value = '';
      input6.value = '';
      setTimeout(() => {
        this.error = false;
        input1.focus();
        this.headingText = 'Enter your PIN';
      }, 2000);
    }
  }
  unlockProgress(stage: HTMLSelectElement) {
    this.academyService.unlockProgress(Number(stage.value));
  }
  emailEvaluations() {
    this.emailEvaluationButtonText = 'Sending...';
    setTimeout(() => {
      this.emailEvaluationButtonText = 'Email Evaluations';
    }, 3000);
    this.mailingService.sendEvaluations(
      this.academy.astronaut.id,
      this.evaluations
    );
  }
}
