import { Component, Input, OnInit } from '@angular/core';
import { AcademyDay } from '../academy-day/academy-day.component';
import { AcademyService } from '@services/academy.service';
import { Academy } from '@models/academy';
import { AcademyStage } from '@models/academystage';
import { ActivatedRoute, RouterLink } from '@angular/router';

@Component({
  selector: 'academy-activity-item',
  templateUrl: './academy-activity-item.component.html',
  styleUrls: ['./academy-activity-item.component.scss'],
  standalone: false
})
export class AcademyActivityItem implements OnInit {
  academy: Academy;
  content!: [];
  activityBrief!: [];
  userText: string = '';
  stage: AcademyStage;
  @Input() completed: boolean = false;
  @Input() number: Number = 0;
  @Input() day!: AcademyDay;
  @Input() name: string = "Captain's Checklist";
  @Input() description: string = 'Are you familiar with your cockpit?';

  constructor(
    private academyService: AcademyService,
    private route: ActivatedRoute
  ) {
    this.academy = academyService.getAcademy();
    this.stage = this.academyService.getStage(
      Number(this.route.snapshot.paramMap.get('stage'))
    )!;
  }

  ngOnInit(): void { }
}
