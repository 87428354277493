<div
  *ngIf="stage"
  class="flex gap-20 items-start justify-center relative"
  [ngClass]="locked ? 'cursor-not-allowed' : 'cursor-pointer'"
  [routerLink]="locked ? null : day.number"
>
  <div
    class="w-[20vw] max-w-[450px] rounded-br-[35px] rounded-[30px] h-[35vh] min-h-[200px] max-h-[600px] flex flex-col justify-between items-center z-0 border-t-4 border-l-4 border-white"
    [style.background]="determineDayCardBackground()"
    [style.background-size]="'cover'"
  >
    <img
      src="/assets/img/day_card_shadow.svg"
      class="absolute bottom-0 right-0 w-[19vw] max-w-[200px] z-[10]"
    />
    <div
      class="bg-[#3D4871] rounded-b-[30px] w-[20vw] max-w-[450px] h-[120px] px-5 absolute left-0 bottom-0 z-10 bg-cover z-[0] flex justify-center items-center"
      [style.background]="stage.stageColor"
      [style.color]="stage.stageTextColor"
    >
      <p>{{ day.description }}</p>
    </div>
    <img
      [ngClass]="!stage.completed ? 'grayscale' : ''"
      *ngIf="day.number === stage.days[stage.days.length - 1].number && !locked"
      src="/assets/img/badge_icon.svg"
      class="absolute top-[-40px] right-[-40px] w-[80px] z-10"
    />
    <img
      *ngIf="locked"
      src="/assets/img/padlock_icon.svg"
      class="absolute top-[-40px] right-[-40px] w-[80px] z-10"
    />
  </div>
  <div class="flex flex-col absolute mt-5">
    <h2
      id="dayCardText"
      class="text-white text-[4vw] tablet:text-[2vw] xl-desktop:text-[1.5vw]"
    >
      {{ day.label }}
    </h2>
  </div>
  <div class="absolute flex bottom-[-15px] gap-1">
    <div *ngFor="let activity of day.activities" class="z-20">
      <img
        *ngIf="!locked"
        src="/assets/img/star.svg"
        alt="star"
        [ngClass]="!activity.completed ? 'grayscale' : ''"
        class="w-12 h-12"
      />
    </div>
  </div>
</div>
